import React from "react";
import { userDevice } from "helpers/tracking";

const Training = (props) => {
  const {
    title,
    public_learner,
    due_status,
    due_on,
    status,
    link_path,
    external,
    external_content_url,
    latest_completed_at_formatted,
  } = props.training;

  const alertIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 9.75V13.5"
        stroke="#CCA252"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.7063 3.74999L2.45634 18C2.32491 18.2276 2.2556 18.4858 2.25537 18.7487C2.25514 19.0115 2.32398 19.2698 2.45501 19.4977C2.58604 19.7256 2.77465 19.915 3.00194 20.0471C3.22923 20.1791 3.48723 20.2491 3.75009 20.25H20.2501C20.5129 20.2491 20.7709 20.1791 20.9982 20.0471C21.2255 19.915 21.4141 19.7256 21.5452 19.4977C21.6762 19.2698 21.745 19.0115 21.7448 18.7487C21.7446 18.4858 21.6753 18.2276 21.5438 18L13.2938 3.74999C13.1634 3.52206 12.975 3.33263 12.7479 3.20088C12.5207 3.06914 12.2627 2.99976 12.0001 2.99976C11.7375 2.99976 11.4795 3.06914 11.2523 3.20088C11.0251 3.33263 10.8368 3.52206 10.7063 3.74999V3.74999Z"
        stroke="#CCA252"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z"
        fill="#CCA252"
      />
    </svg>
  );

  const redirectToCourse = () => {
    if (external && !!external_content_url) {
      window.open(external_content_url, "_blank");
      return;
    }

    if (external) {
      return;
    }

    window.location.href = link_path;
  };

  const trackEvent = () => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      "academy_my_learning_training_clicked",
      {
        training: title,
        status: status,
        is_public_lerner: public_learner,
        user_device: userDevice(),
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  const StatusChip = ({ status, latest_completed_at_formatted }) => {
    return (
      <>
        {status === "completed" && (
          <span className="w-full rounded-none bg-green-50 px-5 py-1 text-center text-sm font-semibold text-gray-70 md:w-1/2">
            {i18n.t("shared.my_learning.table.row.statuses.completed")}{" "}
            {latest_completed_at_formatted}
          </span>
        )}
        {status === "failed" && (
          <span className="w-full rounded-none bg-red-20 px-5 py-1 text-center text-sm font-semibold text-red-100 md:w-1/2">
            {i18n.t("shared.my_learning.table.row.statuses.failed")}
          </span>
        )}
        {(status === "not_started" || status === "in_progress") && (
          <span className="w-full rounded-none bg-gray-25 px-5 py-1 text-center text-sm font-semibold text-gray-70 md:w-1/2">
            {i18n.t(`shared.my_learning.table.row.statuses.${status}`)}
          </span>
        )}
      </>
    );
  };

  return (
    <div
      onClick={() => {
        trackEvent();
        redirectToCourse();
      }}
      className={`my-2 shadow-md ${
        !!external_content_url || (!external && "cursor-pointer")
      }`}
    >
      <div
        className={`items-center justify-around rounded px-2 py-2 md:flex md:py-4 ${
          due_status === "Overdue"
            ? "bg-red-20"
            : due_status === "Due soon"
            ? "bg-yellow-25"
            : "bg-white"
        }`}
      >
        <div className="flex-wrap md:flex md:w-3/4 ">
          <div className="mb-2 md:mb-0">
            <span className="text-lg font-light">{title}</span>
            {external && (
              <span className="ml-3 bg-blue-25 p-1 text-sm font-light">
                External
              </span>
            )}
          </div>

          {!public_learner && (
            <div className="mx-0 flex items-center text-center md:mx-2">
              {due_status === "Overdue" && (
                <>
                  <span className="mr-2 h-5">{alertIcon}</span>
                  <span className="text-xs font-semibold text-red-150">
                    {i18n.t("shared.my_learning.table.row.overdue")} {due_on}
                  </span>
                </>
              )}
              {due_status === "Due soon" && (
                <>
                  <span className="mr-2 h-5">{alertIcon}</span>
                  <span className="text-xs font-semibold text-yellow-150">
                    {due_on}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
        <div className="flex justify-end md:w-1/4 md:justify-center">
          <StatusChip
            status={status}
            latest_completed_at_formatted={latest_completed_at_formatted}
          />
        </div>
      </div>
    </div>
  );
};

export default Training;
