import React from "react";
import LearnerSkillPill from "./LearnerSkillPill";

interface LearnerSkill {
  id: number;
  title: string;
}

interface LearnerSkillsProps {
  skillsList: LearnerSkill[];
  userId: number;
}

const LearnerSkillsList: React.FC<LearnerSkillsProps> = ({
  skillsList = [],
  userId,
}) => {
  return (
    <>
      <p className="text-base">{i18n.t("shared.portfolio.click_on_a_skill")}</p>
      <div className="mb-8 mt-4 flex space-x-10">
        <div className="flex space-x-2">
          <div className="h-5 w-5 rounded-full bg-teal-150"></div>
          <p className="font-light">{i18n.t("shared.portfolio.selected")}</p>
        </div>
        <div className="flex space-x-2">
          <div className="h-5 w-5 rounded-full bg-teal-50"></div>
          <p className="font-light">
            {i18n.t("shared.portfolio.not_selected")}
          </p>
        </div>
      </div>
      <div>
        {skillsList.map((skill, index) => (
          <LearnerSkillPill
            key={index}
            userId={userId}
            skillId={skill.id}
            skillTitle={skill.title}
            pointerEvents={"pointer-events-auto"}
            cursorStyle={"cursor-pointer"}
          />
        ))}
      </div>
    </>
  );
};

export default LearnerSkillsList;
