import turbolinks from "turbolinks";
turbolinks.start();

import "../src/application.scss";
import "controllers";
import "./courseNav";
import "./courses";
import "./accessibility";
import "./translations";
import "./timeZones";
import "./stripe";
import "./reactRails";

var timeZone = jstz.determine().name();
document.cookie = `time_zone=${timeZone}`;

if (document.getElementById("menu-open")) {
  document.addEventListener("turbolinks:load", function () {
    document.getElementById("menu-open").addEventListener("click", function () {
      var menu = document.getElementById("mobile-menu-overlay");
      menu.classList.add("fixed");
      menu.classList.remove("hidden");
    });

    document
      .getElementById("menu-close")
      .addEventListener("click", function () {
        var menu = document.getElementById("mobile-menu-overlay");
        menu.classList.add("hidden");
        menu.classList.remove("fixed");
      });
  });
}
