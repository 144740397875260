import React from "react";
import classNames from "classnames";
import _ from "lodash";
import PropTypes from "prop-types";

const MatrixStatusButton = ({
  label,
  statuses,
  filteredStatus,
  setFilteredStatus,
}) => {
  const determineIfFilterButtonIsActive = (buttonFilter) => {
    filteredStatus.map((status) => status);
    return _.isEqual(buttonFilter, filteredStatus);
  };

  const handleApplyFilter = (statuses) => {
    setFilteredStatus(statuses);
  };

  return (
    <button
      className={classNames(
        "mr-2 box-border flex content-center items-center rounded-lg border p-2 font-medium focus:outline-none",
        {
          "border-gray-30 bg-white": determineIfFilterButtonIsActive(statuses),
          "btn-disabled border-gray-20":
            !determineIfFilterButtonIsActive(statuses),
        }
      )}
      onClick={() => handleApplyFilter(statuses)}
    >
      {label}
    </button>
  );
};

MatrixStatusButton.propTypes = {
  label: PropTypes.string.isRequired,
  statuses: PropTypes.array.isRequired,
  filteredStatus: PropTypes.array.isRequired,
};

export default MatrixStatusButton;
