import React from "react";
import Training from "components/MyLearning/Training";

const TrainingTable = (training) => {
  const trainings = training.training;

  return (
    <div className="container mx-auto my-2">
      <div className="hidden flex-wrap items-center justify-evenly rounded  bg-gray-40 p-2 text-white md:flex">
        <div className="flex w-3/4 justify-center">
          <span className="text-center font-semibold">
            {i18n.t("shared.my_learning.table.columns.course")}
          </span>
        </div>
        <div className="flex w-1/4 justify-center">
          <span className="font-semibold">
            {i18n.t("shared.my_learning.table.columns.status")}
          </span>
        </div>
      </div>

      {trainings.map((training) => (
        <Training key={training.slug} training={training} />
      ))}
    </div>
  );
};

export default TrainingTable;
