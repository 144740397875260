document.addEventListener("turbolinks:load", function () {
  // Text to Speech
  speech = window.speechSynthesis;
  isVoiceOverOn = document.getElementById("speak-play");
  if (speech && isVoiceOverOn) {
    speech.cancel();
    var startButton = document.getElementById("speak-play");
    if (!startButton) return;
    var pauseButton = document.getElementById("speak-pause");
    var resetButton = document.getElementById("speak-reset");
    var text = document.getElementById("text-to-speech").dataset.copy;

    document
      .getElementById("text-to-speech-controls")
      .classList.remove("hidden");

    startButton.addEventListener("click", function () {
      if (speech.paused) {
        speech.resume();
      } else {
        speech.cancel();
        speech.resume();
        var utterance = new SpeechSynthesisUtterance(text);
        utterance.rate = 0.9;
        speech.speak(utterance);
      }
      pauseButton.classList.remove("hidden");
      startButton.classList.add("hidden");
      resetButton.classList.remove("hidden");
    });

    pauseButton.addEventListener("click", function () {
      speech.pause();
      pauseButton.classList.add("hidden");
      startButton.classList.remove("hidden");
    });

    resetButton.addEventListener("click", function () {
      speech.resume();
      speech.cancel();
      pauseButton.classList.add("hidden");
      startButton.classList.remove("hidden");
      resetButton.classList.add("hidden");
    });
  }

  // font size
  var zoomInButton = document.getElementById("zoom-in");
  var zoomOutButton = document.getElementById("zoom-out");

  if (zoomInButton && zoomOutButton) {
    zoomInButton.addEventListener("click", function () {
      var courseContent = document.querySelector(".course-content");
      var zoomLevel = parseFloat(courseContent.style.zoom || "1");
      if (zoomLevel >= 2) return;
      zoomLevel += 0.1;
      courseContent.style.zoom = zoomLevel;
    });

    zoomOutButton.addEventListener("click", function () {
      var courseContent = document.querySelector(".course-content");
      var zoomLevel = parseFloat(courseContent.style.zoom || "1");
      if (zoomLevel <= 1) return;
      zoomLevel -= 0.1;
      courseContent.style.zoom = zoomLevel;
    });
  }
});
