import { isMobileOnly, isTablet } from "react-device-detect";

export const userDevice = () => {
  if (isMobileOnly) {
    return "smartphone";
  } else if (isTablet) {
    return "tablet";
  }

  return "desktop";
};
