import React, { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import FormModal from "../../Shared/FormModal";
import WorkHistoryCreateForm from "../WorkHistoryCreateForm";

interface NewButtonProps {
  userId: number;
  userName: string;
  userEmail: string;
  countryList: string[];
}

const NewButton: React.FC<NewButtonProps> = ({
  userId,
  userName,
  userEmail,
  countryList,
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);

  const renderModal = () => {
    return (
      <FormModal
        isVisible={isModalVisible}
        title={i18n.t("shared.portfolio.work_experience")}
        onClose={() => setModalVisibility(false)}
      >
        <WorkHistoryCreateForm userId={userId} countryList={countryList} />
      </FormModal>
    );
  };

  const trackClick = () => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      "academy_portfolio_add_work_history_button_clicked",
      {
        name: userName,
        email: userEmail,
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  return (
    <>
      <div
        onClick={() => {
          setModalVisibility(true), trackClick();
        }}
        className="cursor-pointer py-6 md:mb-4 md:py-2"
      >
        <div className="flex h-fit flex-wrap items-center justify-center rounded-lg rounded-b-lg border border-gray-40 p-4 hover:shadow-lg">
          <p className="py-1 text-lg font-medium leading-tight">
            {i18n.t("shared.portfolio.add_work_experience")}
          </p>
        </div>
      </div>
      {renderModal()}
    </>
  );
};

export default NewButton;
