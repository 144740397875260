import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ExportToCsv } from "export-to-csv";
import CheckBox from "../Checkbox";
import { userDevice } from "helpers/tracking";
import { ChevronUpDownIcon } from "components/Shared/Icons";

const ComplianceTable = ({ columns, data }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [allData, setAllData] = useState(data);
  const [sortAscending, setSortAscending] = useState(false);

  const toggleSortOrder = (attribute, type) => {
    setSortAscending(!sortAscending);
    if (type === "percentage") {
      let sortedData = [...allData].sort((a, b) =>
        sortAscending
          ? a[attribute] - b[attribute]
          : b[attribute] - a[attribute]
      );
      setAllData(sortedData);
    } else {
      let sortedData = [...allData].sort((a, b) =>
        sortAscending
          ? a[attribute].localeCompare(b[attribute])
          : b[attribute].localeCompare(a[attribute])
      );
      setAllData(sortedData);
    }
  };

  const isSummaryByTrainingTable = () => {
    return columns.length === 3;
  };

  const handleCheckboxChange = (value) => {
    if (selectedRows.includes(value)) {
      setSelectedRows(selectedRows.filter((id) => id !== value));
    } else {
      setSelectedRows([...selectedRows, value]);
    }
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows);
  };

  const handleExportData = () => {
    csvExporter.generateCsv(data);
  };

  const handleSelectAllRows = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((row) => row.name));
    }
  };

  const csvOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const getSelectedRows = () => {
    return data.filter((row) => selectedRows.includes(row.name));
  };

  const getIsOneRowSelected = () => {
    return selectedRows.length === 1;
  };

  const getAreSomeRowsSelected = () => {
    return selectedRows.length > 0;
  };

  const getAreAllRowsSelected = () => {
    return selectedRows.length === data.length;
  };

  const getSelectedRowsIds = () => {
    return data
      .filter((row) => selectedRows.includes(row.name))
      .map((row) => {
        return row.id;
      });
  };

  const firstColumnWidth = () => {
    switch (columns.length) {
      case 3:
        return "w-5/12";
      case 4:
        return "w-2/12";
    }
  };

  const complianceCellClass = (value) => {
    if (value === 0) {
      return "bg-gray-40";
    } else if (value < 60) {
      return "bg-red-50";
    } else if (value < 80) {
      return "bg-yellow-50";
    } else {
      return "bg-green-50";
    }
  };

  const dataClass = (index, value) => {
    const baseClasses = `font-light flex h-8 bg-clip-content overflow-hidden items-center ${
      typeof value == "number"
        ? `${complianceCellClass(value)} px-20 text-lg`
        : ""
    }`;

    switch (index) {
      case 1:
        return [baseClasses, firstColumnWidth(), "ml-3 leading-tight"].join(
          " "
        );
      case 2:
        return [baseClasses, "w-3/12 justify-center content-center px-20"].join(
          " "
        );
      default:
        return [baseClasses, "w-3/12 justify-center"].join(" ");
    }
  };

  const trackEvent = (event) => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      event,
      {
        user_device: userDevice(),
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  const pageHead = () => {
    return (
      <div className="flex">
        <button
          className="btn btn-primary text-md mr-2 w-40"
          onClick={() => {
            handleExportData(),
              trackEvent("academy_b2b_export_all_rows_clicked");
          }}
        >
          {i18n.t("client.training.export_all")}
        </button>
        <button
          className={`btn ${
            getAreSomeRowsSelected() ? "btn-fourth" : "btn-disabled"
          } mr-2 w-56`}
          onClick={() => {
            handleExportRows(getSelectedRows()),
              trackEvent("academy_b2b_export_selected_rows_clicked");
          }}
        >
          {i18n.t("client.training.export_selected")}
        </button>
        {isSummaryByTrainingTable() && (
          <a
            className={`btn track-click ${
              getIsOneRowSelected() ? "btn-fourth" : "btn-disabled"
            } w-62`}
            href={`/c/home/training/bulk_mark_training_as_complete?course_id=${encodeURIComponent(
              getSelectedRowsIds()
            )}`}
            onClick={(e) => {
              !getIsOneRowSelected() && e.preventDefault(),
                trackEvent(
                  "academy_b2b_bulk_mark_training_as_complete_clicked"
                );
            }}
          >
            {i18n.t("client.training.mark_as_complete")}
          </a>
        )}
      </div>
    );
  };

  const table = () => {
    return (
      <table className="w-full">
        {tableHead()} {tableBody()}
      </table>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr className="align-center flex w-full items-center p-3">
          <th
            className="flex h-8 w-10 cursor-pointer items-center justify-center"
            onClick={() => {
              handleSelectAllRows();
            }}
          >
            <CheckBox
              onChange={() => handleSelectAllRows()}
              checked={getAreAllRowsSelected()}
              label={null}
            />
          </th>
          {columns.map((column, index) => (
            <th
              className={`my-4 flex font-normal ${
                index == 0
                  ? `${firstColumnWidth()} ml-3`
                  : "w-3/12 justify-center"
              }`}
              key={column.id}
            >
              <h2>{column.header} </h2>
              {column.header !== "Compliant staff" && (
                <button
                  className="ml-2"
                  onClick={() =>
                    toggleSortOrder(column.accessorKey, column.type)
                  }
                >
                  <ChevronUpDownIcon />
                </button>
              )}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const tableBody = () => {
    return (
      <tbody className="flex flex-col items-center rounded">
        {allData.map((row) => (
          <tr
            className="align-center mb-2 flex w-full cursor-pointer items-center rounded bg-white p-3 shadow-sm"
            key={row.id}
            onClick={() => {
              handleCheckboxChange(row.name);
            }}
          >
            <td className="flex h-8 w-10 items-center justify-center">
              <CheckBox
                onChange={() => handleCheckboxChange(row.name)}
                checked={selectedRows.includes(row.name)}
                label={null}
              />
            </td>
            {Object.values(row).map(
              (cell, index) =>
                index > 0 && ( // don't render id in table
                  <td className={`${dataClass(index, cell)}`}>{cell}</td>
                )
            )}
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <>
      {pageHead()}
      {table()}
    </>
  );
};

ComplianceTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default ComplianceTable;
