import React, { useState } from "react";
import axios from "axios";
import { PersonalCertificate } from "./index";

interface PersonalCertificateEditFormProps {
  personalCertificate: PersonalCertificate;
}

const PersonalCertificateEditForm: React.FC<
  PersonalCertificateEditFormProps
> = ({ personalCertificate }) => {
  const [responseMessage, setResponseMessage] = useState(null);
  const [courseTitleValue, setCourseTitleValue] = useState(
    personalCertificate.course_title
  );
  const [completionDateValue, setCompletionDateValue] = useState(
    personalCertificate.completion_date
  );
  const [fileUploadValue, setFileUploadValue] = useState(null);
  const [submittingForm, setSubmittingForm] = useState(false);

  const reloadPage = () => {
    window.location.reload();
  };

  const handleFormSubmission = (event) => {
    event.preventDefault();
    setSubmittingForm(true);

    const token = document.querySelector(
      "[name=csrf-token]"
    ) as HTMLMetaElement;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

    const requestType = () => {
      if (event.type === "submit") {
        return "patch";
      } else {
        return "delete";
      }
    };

    const formData = new FormData();
    formData.append("personal_certificate[course_title]", courseTitleValue);
    formData.append("personal_certificate[completed_at]", completionDateValue);
    formData.append("personal_certificate[document]", fileUploadValue);

    axios[requestType()](
      `/api/v0/personal_certificates/${personalCertificate.id}`,
      fileUploadValue === null
        ? {
            personal_certificate: {
              course_title: courseTitleValue,
              completed_at: completionDateValue,
              document: fileUploadValue,
            },
          }
        : formData
    )
      .then((response) => {
        if (response.status === 200) {
          setResponseMessage(response.data.message);
          setTimeout(reloadPage, 600);
        }
      })
      .catch((error) => {
        setResponseMessage(error.response.data.message);
      });
  };

  const displayResponseMessage = () => {
    if (!!responseMessage) {
      return <div className="text-sm text-gray-70">{responseMessage}</div>;
    }
  };

  const displayUploadedFile = () => {
    if (personalCertificate.document_present) {
      return (
        <>
          <label
            className="text-base font-light text-gray-100"
            htmlFor="uploaded-file"
          >
            {i18n.t("shared.portfolio.uploaded_file")}
          </label>
          <input
            id="uploaded-file"
            className="focus:outline-none mt-1.5 mb-8 w-full rounded-lg border-2 border-gray-30 p-3 focus:ring-0"
            value={personalCertificate.document_filename}
            disabled
          ></input>
        </>
      );
    }
  };

  return (
    <form
      onSubmit={(e) => {
        handleFormSubmission(e);
      }}
    >
      <label
        className="text-base font-light text-gray-100"
        htmlFor="course-title"
      >
        {i18n.t("shared.portfolio.course_title")}
      </label>
      <input
        id="course-title"
        className="focus:outline-none mt-1.5 mb-8 w-full rounded-lg border-2 border-gray-30 p-3 focus:ring-0"
        onChange={(event) => setCourseTitleValue(event.target.value)}
        value={courseTitleValue}
      ></input>

      <label
        className="text-base font-light text-gray-100"
        htmlFor="completion-date"
      >
        {i18n.t("shared.portfolio.certificate_completion_date")}
      </label>
      <input
        id="completion-date"
        type="date"
        className="focus:outline-none mt-1.5 mb-8 w-full rounded-lg border-2 border-gray-30 p-3 focus:ring-0"
        onChange={(event) => setCompletionDateValue(event.target.value)}
        value={completionDateValue}
      ></input>
      {displayUploadedFile()}
      <label
        className="text-base font-light text-gray-100"
        htmlFor="file-upload"
      >
        {i18n.t("shared.portfolio.upload_certificate")}
        {personalCertificate.document_present && (
          <span className="text-xs font-light text-gray-100">
            {" "}
            {i18n.t("shared.portfolio.will_replace_file")}
          </span>
        )}
      </label>
      <input
        className="focus:outline-none mt-1.5 w-full rounded-lg border-2 border-gray-30 p-3 focus:ring-0"
        type="file"
        accept="image/png,image/jpg,image/jpeg,application/pdf"
        id="file-upload"
        onChange={(event) => setFileUploadValue(event.target.files[0])}
      ></input>

      <p className="mt-1.5 mb-8 text-xs font-light text-gray-100">
        {i18n.t("shared.portfolio.public_certificate_alert")}
      </p>

      <button
        type="submit"
        className={`${
          submittingForm ? "btn-disabled" : "btn-primary "
        } btn w-full`}
        disabled={submittingForm}
      >
        {submittingForm ? i18n.t("shared.please_wait") : i18n.t("shared.save")}
      </button>

      <button
        type="button"
        className={`${
          submittingForm ? "btn-disabled" : "btn-fourth "
        } btn mt-2.5 mb-4 w-full`}
        onClick={(event) => handleFormSubmission(event)}
        disabled={submittingForm}
      >
        {submittingForm
          ? i18n.t("shared.please_wait")
          : i18n.t("shared.delete")}
      </button>
      <div className="h-4">{displayResponseMessage()}</div>
    </form>
  );
};

export default PersonalCertificateEditForm;
