import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Checkbox = ({
  onChange,
  checked,
  label,
  value,
  noWrap,
  labelCursorPointer,
}) => {
  return (
    <>
      <input
        type="checkbox"
        name={value}
        value={value}
        checked={checked}
        onChange={onChange}
        className="cursor-pointer text-teal-100 focus:ring-0"
      />
      {label && (
        <label
          className={classNames("pl-2", {
            "whitespace-nowrap": noWrap,
            "cursor-pointer": labelCursorPointer,
          })}
          htmlFor={value}
        >
          {label}
        </label>
      )}
    </>
  );
};

Checkbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Checkbox;
