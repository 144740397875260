import React, { useState } from "react";
import { InfoIcon } from "components/Shared/Icons";
import LearnerSkillPill from "./LearnerSkillPill";
import FormModal from "../Shared/FormModal";
import LearnerSkillsList from "./LearnerSkillsList";

interface LearnerSkill {
  id: number;
  title: string;
}

export interface SkillsProps {
  skillsList?: LearnerSkill[];
  learnerSkills: LearnerSkill[];
  isCurrentUser: boolean;
  userId: number;
  userName?: string;
  userEmail?: string;
}

const LearnerSkills: React.FC<SkillsProps> = ({
  skillsList = [],
  learnerSkills = [],
  isCurrentUser,
  userId,
  userName,
  userEmail,
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);

  const handleHeading = () => {
    if (isCurrentUser) {
      return (
        <div className="flex items-start justify-between">
          <span className="mb-6 mr-3 text-lg font-medium">
            {i18n.t("shared.portfolio.my_skills")}
          </span>
          <a
            className="cursor-pointer text-sm font-light text-blue-100 hover:underline"
            onClick={() => {
              setModalVisibility(true), trackClick();
            }}
          >
            {i18n.t("shared.edit")}
          </a>
        </div>
      );
    } else {
      return (
        <div className="mb-6 mr-3 text-lg font-medium">
          {i18n.t("shared.portfolio.skills")}
        </div>
      );
    }
  };

  const handleSkills = () => {
    if (learnerSkills.length > 0) {
      return learnerSkills.map((skill) => (
        <LearnerSkillPill
          key={skill.id}
          userId={userId}
          skillId={skill.id}
          skillTitle={skill.title}
          pointerEvents={"pointer-events-none"}
          cursorStyle={"cursor-default"}
        />
      ));
    } else if (isCurrentUser) {
      return (
        <div className="mb-3 text-base font-light">
          {i18n.t("shared.portfolio.empty_state")}
        </div>
      );
    }
  };

  const renderModal = () => {
    return (
      <FormModal
        isVisible={isModalVisible}
        title={i18n.t("shared.portfolio.skills")}
        onClose={() => {
          setModalVisibility(false), window.location.reload();
        }}
      >
        <LearnerSkillsList skillsList={skillsList} userId={userId} />
      </FormModal>
    );
  };

  const trackClick = () => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      "academy_portfolio_add_learner_skill_button_clicked",
      {
        name: userName,
        email: userEmail,
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  return (
    <>
      <div className="my-2 w-full rounded-lg border-2 border-gray-20 bg-white p-4 shadow-md mdmax:w-80">
        {handleHeading()}
        {handleSkills()}
        {!isCurrentUser && (
          <div className="mt-6 flex items-center">
            <span className="mr-2">
              <InfoIcon />
            </span>
            <span className="text-xs text-gray-100">
              {i18n.t("shared.portfolio.learner_generated_skills")}
            </span>
          </div>
        )}
      </div>
      {renderModal()}
    </>
  );
};

export default LearnerSkills;
