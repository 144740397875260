import React, { useState } from "react";
import axios from "axios";

const LearnerSkillPill = ({
  userId,
  skillId,
  skillTitle,
  pointerEvents,
  cursorStyle,
}) => {
  const [learnerSkillId, setLearnerSkillId] = useState(skillId);

  const token = document.querySelector("[name=csrf-token]") as HTMLMetaElement;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

  const textColor = () => {
    return !!learnerSkillId ? "text-white" : "text-teal-150";
  };

  const bgColor = () => {
    return !!learnerSkillId ? "bg-teal-150" : "bg-teal-50";
  };

  const handleOnClick = () => {
    if (!!learnerSkillId) {
      destroyLearnerSkill(learnerSkillId);
    } else {
      createLearnerSkill(skillTitle);
    }
  };

  const createLearnerSkill = (title) => {
    axios
      .post("/api/v0/learner_skills", {
        learner_skill: {
          user_id: userId,
          title: title,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setLearnerSkillId(response.data.learner_skill_id);
        }
      })
      .catch(() => {});
  };

  const destroyLearnerSkill = (id) => {
    axios
      .delete(`/api/v0/learner_skills/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setLearnerSkillId(null);
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <div
        className={`m-1.5 inline-block ${pointerEvents} ${cursorStyle} rounded-full px-3 py-2 text-center text-xs ${textColor()} ${bgColor()}`}
        onClick={() => handleOnClick()}
      >
        {i18n.t(`shared.portfolio.skills_list.${skillTitle}`)}
      </div>
    </>
  );
};

export default LearnerSkillPill;
