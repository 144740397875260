import React, { useState } from "react";
import classNames from "classnames";
import axios from "axios";

import OutsideClickHandler from "react-outside-click-handler";

import PropTypes from "prop-types";
import MatrixStatusButton from "./MatrixStatusButton";
import MatrixDropdown from "./MatrixDropdown";

const MatrixFilters = ({
  courses,
  users,
  filteredCourses,
  setFilteredCourses,
  locations,
  filteredLocations,
  setFilteredLocations,
  roles,
  filteredRoles,
  setFilteredRoles,
  statuses,
  filteredStatus,
  setFilteredStatus,
  careCertificates,
  filteredCareCertificate,
  setFilteredCareCertificate,
  applyAllFilters,
  showCareCertificate,
}) => {
  const [selectedLocations, setSelectedLocations] = useState(filteredLocations);
  const [selectedCourses, setSelectedCourses] = useState(filteredCourses);
  const [selectedRoles, setSelectedRoles] = useState(filteredRoles);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [downloadIsProcessing, setDownloadIsProcessing] = useState(false);

  // EMAIL FUNCTIONS

  const handleDownloadMatrix = async () => {
    setDownloadIsProcessing(true);

    const matrixParams = {
      course_ids: filteredCourses.length
        ? filteredCourses.map((course) => course.id)
        : courses.map((course) => course.id),
      location_ids: filteredLocations.length
        ? filteredLocations.map((location) => location.id)
        : locations.map((location) => location.id),
      learner_ids: users.map((user) => user.id),
      statuses: filteredStatus.map((status) => status),
    };

    axios.post("/api/v0/c/matrix/download", matrixParams).then((response) => {
      const apiResponse = response.data;
      const downloadStatus = apiResponse.download_response;
      handleDownloadNotification(downloadStatus);

      setDownloadIsProcessing(false);
    });
  };

  const downLoadMatrixButtonText = () => {
    if (downloadIsProcessing) {
      return i18n.t("client.matrix.download.please_wait");
    } else {
      return i18n.t("client.matrix.download.request");
    }
  };

  // Display a notification with the download status
  const handleDownloadNotification = (downloadSuccess) => {
    if (downloadSuccess) {
      alert(i18n.t("client.matrix.download_success.message"));
    } else {
      alert(i18n.t("client.matrix.download_failed.message"));
    }
  };

  const handleResetClick = () => {
    setFilteredCourses([]);
    setFilteredLocations([]);
    setFilteredRoles([]);
    setFilteredStatus(statuses.map((status) => status.id));
    setFilteredCareCertificate([]);

    setSelectedCourses([]);
    setSelectedLocations([]);
    setSelectedRoles([]);

    resetAllCheckboxes();
  };

  const resetAllCheckboxes = () => {
    dropdowns.current.forEach((dropdown) => {
      dropdown.resetSelectedData();
    });
  };

  const filterDropdownData = [
    { key: "Locations", values: locations },
    { key: "Courses", values: courses },
    { key: "Roles", values: roles },
  ];

  const handleOnChange = (selectedState, setSelectedState, item) => {
    if (selectedState.some((stateItem) => stateItem.id === item.id)) {
      setSelectedState(
        selectedState.filter((stateItem) => stateItem.id !== item.id)
      );
    } else {
      setSelectedState([...selectedState, item]);
    }
  };

  const applyStateFilter = (dataKey, item, selectedItems) => {
    switch (dataKey) {
      case "Locations":
        return handleOnChange(selectedItems, setSelectedLocations, item);
      case "Courses":
        return handleOnChange(selectedItems, setSelectedCourses, item);
      case "Roles":
        return handleOnChange(selectedItems, setSelectedRoles, item);
      default:
        return null;
    }
  };

  const handleDropdownClick = (dataKey) => {
    if (activeDropdown === dataKey) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dataKey);
    }
  };

  const renderStatusButtons = () => (
    <div className="flex items-center justify-between">
      <p className="mr-2">Courses:</p>
      <MatrixStatusButton
        label="All"
        statuses={statuses.map((status) => status.id)}
        filteredStatus={filteredStatus}
        setFilteredStatus={setFilteredStatus}
      />
      {statuses.map((status) => (
        <MatrixStatusButton
          key={status.id}
          label={status.id}
          statuses={[status.id]}
          filteredStatus={filteredStatus}
          setFilteredStatus={setFilteredStatus}
        />
      ))}
    </div>
  );

  const renderCareCertificateFilterButton = () => (
    <div className="flex items-center justify-between">
      <p className="mr-2">Care Certificates:</p>
      <MatrixStatusButton
        label="All"
        statuses={careCertificates.map((ccstatus) => ccstatus.id)}
        filteredStatus={filteredCareCertificate}
        setFilteredStatus={setFilteredCareCertificate}
      />
      {careCertificates.map((ccstatus) => (
        <MatrixStatusButton
          key={ccstatus.id}
          label={ccstatus.id}
          statuses={[ccstatus.id]}
          filteredStatus={filteredCareCertificate}
          setFilteredStatus={setFilteredCareCertificate}
        />
      ))}
    </div>
  );

  const renderEmailButton = () => {
    return (
      <button
        className="btn btn-fourth"
        onClick={handleDownloadMatrix}
        disabled={downloadIsProcessing}
      >
        {downLoadMatrixButtonText()}
      </button>
    );
  };

  const renderFilterPill = (key, item) => {
    {
      return (
        <span
          className={classNames(
            "mb-2 mr-2 inline-block rounded-full border border-gray-30 px-3 py-1",
            {
              "bg-teal-25": key === "locations",
              "bg-green-25": key === "courses",
              "bg-purple-25": key === "roles",
            }
          )}
          key={item.id}
        >
          {item.name}
        </span>
      );
    }
  };

  return (
    <div className="mb-4 flex flex-col ">
      <div className="flex justify-between">
        {renderStatusButtons()}
        {renderEmailButton()}
      </div>
      <div className="flex justify-between">
        {showCareCertificate && renderCareCertificateFilterButton()}
      </div>
      <div className="mt-4 flex">
        <OutsideClickHandler
          onOutsideClick={() => {
            setActiveDropdown(null);
          }}
          display="flex"
        >
          {filterDropdownData.map((data) => {
            return (
              <span
                className={classNames("mr-3", {
                  hidden: data.values.length < 2,
                })}
                key={data.key}
              >
                <MatrixDropdown
                  data={data}
                  filteredData={
                    data.key === "Locations"
                      ? filteredLocations
                      : data.key === "Courses"
                      ? filteredCourses
                      : filteredRoles
                  }
                  applyStateFilter={applyStateFilter}
                  isActive={activeDropdown === data.key}
                  handleDropdownClick={handleDropdownClick}
                />
              </span>
            );
          })}
        </OutsideClickHandler>
        <div className="flex">
          <button
            className="btn btn-primary mr-3 min-w-[80px]"
            onClick={() => {
              applyAllFilters(
                selectedLocations,
                selectedCourses,
                selectedRoles
              );
            }}
          >
            Apply
          </button>
          <button className="btn btn-fourth" onClick={() => handleResetClick()}>
            Reset
          </button>
        </div>
      </div>
      <div className="mt-4 text-sm">
        {filteredLocations.map((item) => {
          return renderFilterPill("locations", item);
        })}
        {filteredCourses.map((item) => {
          return renderFilterPill("courses", item);
        })}
        {filteredRoles.map((item) => {
          return renderFilterPill("roles", item);
        })}
      </div>
    </div>
  );
};

MatrixFilters.propTypes = {
  courses: PropTypes.array.isRequired,
  filteredCourses: PropTypes.array.isRequired,
  setFilteredCourses: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  filteredLocations: PropTypes.array.isRequired,
  setFilteredLocations: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  filteredRoles: PropTypes.array.isRequired,
  setFilteredRoles: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  filteredStatus: PropTypes.array.isRequired,
  setFilteredStatus: PropTypes.func.isRequired,
  careCertificates: PropTypes.array.isRequired,
  filteredCareCertificate: PropTypes.array.isRequired,
  setFilteredCareCertificate: PropTypes.func.isRequired,
  applyAllFilters: PropTypes.func.isRequired,
  showCareCertificate: PropTypes.bool.isRequired,
};

export default MatrixFilters;
