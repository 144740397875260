import React, { useState } from "react";
import classNames from "classnames";
import Checkbox from "../../Shared/Checkbox";
import { ChevronDown } from "components/Shared/Icons";
import PropTypes from "prop-types";

const MatrixDropdown = ({
  data,
  filteredData,
  applyStateFilter,
  isActive,
  handleDropdownClick,
}) => {
  const [selectedData, setSelectedData] = useState(filteredData);

  const handleOnChange = (item) => {
    if (selectedData.some((selectedItem) => selectedItem.id === item.id)) {
      setSelectedData(
        selectedData.filter((selectedItem) => selectedItem.id !== item.id)
      );
    } else {
      setSelectedData([...selectedData, item]);
    }
    applyStateFilter(data.key, item, selectedData);
  };

  const handleIsOptionChecked = (item) => {
    return selectedData.some((selectedItem) => selectedItem.id === item.id);
  };

  return (
    <div className="relative" onClick={(event) => event.stopPropagation()}>
      <button
        className="rounded-lg border border-gray-30 bg-white pb-2 pl-3 pr-10 pt-2"
        onClick={() => handleDropdownClick(data.key)}
      >
        <span className="absolute right-2" style={{ top: "12px" }}>
          {<ChevronDown />}
        </span>
        {data.key}
      </button>
      <div
        className={classNames(
          "absolute z-50 flex max-h-[50vh] flex-col overflow-scroll rounded-lg bg-white p-3 shadow-lg transition-opacity duration-200",
          {
            "opacity-100": isActive,
            "pointer-events-none opacity-0": !isActive,
          }
        )}
        style={{ top: "3rem" }}
      >
        {data.values.map((item) => (
          <div
            className="flex cursor-pointer items-center"
            key={item.id}
            onClick={() => handleOnChange(item)}
          >
            <Checkbox
              onChange={() => handleOnChange(item)}
              checked={handleIsOptionChecked(item)}
              value={item.id || ""}
              label={item.name}
              noWrap={true}
              labelCursorPointer={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

MatrixDropdown.propTypes = {
  data: PropTypes.object.isRequired,
  applyStateFilter: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleDropdownClick: PropTypes.func.isRequired,
  filteredData: PropTypes.array.isRequired,
};

export default MatrixDropdown;
