import React, { useRef } from "react";
import Proptypes from "prop-types";

import Slider from "react-slick";
import "./styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { isMobileOnly, isTablet } from "react-device-detect";
import { userDevice } from "helpers/tracking";

const AdvertCarousel = ({ adverts }) => {
  const sliderRef = useRef(null);

  const trackEvent = (advert) => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      advert.tracking_event_name,
      {
        advertised_item: advert.advertised_item,
        advert_type: advert.type,
        user_device: userDevice(),
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  const settings = {
    autoplay: true,
    autoplaySpeed: 7000,
    dots: true,
    arrows: false,
  };

  return (
    <Slider {...settings} ref={sliderRef}>
      {adverts.map((advert, index) => {
        return (
          <div key={index}>
            <div className="flex min-h-fit bg-gray-20 px-4 text-gray-100 md:h-72 md:px-0">
              <div className="flex w-full flex-col items-center justify-center rounded-l-lg rounded-r-lg bg-white px-4 py-4 text-center shadow-lg md:h-72 md:px-10 md:py-6 lg:w-1/2 lg:items-start lg:rounded-r-none lg:text-left">
                <span className="mb-2 text-2xl font-semibold tracking-wide">
                  {advert.title}
                </span>
                {!!advert.sub_title && (
                  <span className="my-2 text-xl tracking-wide">
                    {advert.sub_title}
                  </span>
                )}
                <span className="font-light">{advert.body}</span>
                {!!advert.price && (
                  <span className="mt-2 font-light">
                    <span className="tracking-wide">
                      <strong>{advert.price}</strong>
                    </span>{" "}
                    {!!advert.price_before_saving && (
                      <s>{advert.price_before_saving}</s>
                    )}
                  </span>
                )}
                {!!advert.cta_copy && (
                  <a href={advert.cta_url}>
                    <button
                      className="btn btn-primary mt-4 w-max"
                      onClick={() => trackEvent(advert)}
                    >
                      {advert.cta_copy}
                    </button>
                  </a>
                )}
              </div>
              <img
                src={advert.image_url}
                className="min-h-72 hidden h-72 w-1/2 rounded-r-lg object-cover object-center md:block"
                style={isMobileOnly || isTablet ? { display: "none" } : {}}
              />
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

AdvertCarousel.propTypes = {
  adverts: Proptypes.array.isRequired,
};

export default AdvertCarousel;
