import React, { useState } from "react";
import axios from "axios";

interface PersonalCertificateCreateFormProps {
  userId: number;
}

const PersonalCertificateCreateForm: React.FC<
  PersonalCertificateCreateFormProps
> = ({ userId }) => {
  const [responseMessage, setResponseMessage] = useState(null);
  const [courseTitleValue, setCourseTitleValue] = useState(null);
  const [completedAtValue, setCompletedAtValue] = useState(null);
  const [fileUploadValue, setFileUploadValue] = useState(null);
  const [submittingForm, setSubmittingForm] = useState(false);

  const reloadPage = () => {
    window.location.reload();
  };

  const handleFormSubmission = (event) => {
    event.preventDefault();
    setSubmittingForm(true);

    const token = document.querySelector(
      "[name=csrf-token]"
    ) as HTMLMetaElement;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

    const formData = new FormData();
    formData.append("personal_certificate[user_id]", `${userId}`);
    formData.append("personal_certificate[course_title]", courseTitleValue);
    formData.append("personal_certificate[completed_at]", completedAtValue);
    formData.append("personal_certificate[document]", fileUploadValue);

    axios
      .post("/api/v0/personal_certificates", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setResponseMessage(response.data.message);
          setTimeout(reloadPage, 600);
        }
      })
      .catch((error) => {
        setResponseMessage(error.response.data.message);
        setTimeout(() => setSubmittingForm(false), 600);
      });
  };

  const displayResponseMessage = () => {
    if (!!responseMessage) {
      return <div className="text-sm text-gray-70">{responseMessage}</div>;
    }
  };

  return (
    <form
      onSubmit={(e) => {
        handleFormSubmission(e);
      }}
    >
      <label
        className="text-base font-light text-gray-100"
        htmlFor="course-title"
      >
        {i18n.t("shared.portfolio.course_title")}
      </label>
      <input
        id="course-title"
        className="mb-8 mt-1.5 w-full rounded-lg border-2 border-gray-30 p-3 focus:outline-none focus:ring-0"
        onChange={(event) => setCourseTitleValue(event.target.value)}
      ></input>

      <label
        className="text-base font-light text-gray-100"
        htmlFor="completion-date"
      >
        {i18n.t("shared.portfolio.certificate_completion_date")}
      </label>
      <input
        id="completion-date"
        type="date"
        className="mb-8 mt-1.5 w-full rounded-lg border-2 border-gray-30 p-3 focus:outline-none focus:ring-0"
        onChange={(event) => setCompletedAtValue(event.target.value)}
      ></input>

      <label
        className="text-base font-light text-gray-100"
        htmlFor="file-upload"
      >
        {i18n.t("shared.portfolio.upload_certificate")}
      </label>
      <input
        className="mt-1.5 w-full rounded-lg border-2 border-gray-30 p-3 focus:outline-none focus:ring-0"
        type="file"
        accept="image/png,image/jpg,image/jpeg,application/pdf"
        id="file-upload"
        onChange={(event) => setFileUploadValue(event.target.files[0])}
      ></input>

      <p className="mb-8 mt-1.5 text-xs font-light text-gray-100">
        {i18n.t("shared.portfolio.public_certificate_alert")}
      </p>

      <button
        type="submit"
        className={`${
          submittingForm ? "btn-disabled" : "btn-primary "
        } btn mb-4 w-full`}
        disabled={submittingForm}
      >
        {submittingForm ? i18n.t("shared.please_wait") : i18n.t("shared.save")}
      </button>
      <div className="h-4">{displayResponseMessage()}</div>
    </form>
  );
};

export default PersonalCertificateCreateForm;
