import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { isMobileOnly, isTablet } from "react-device-detect";
import { Close } from "components/Shared/Icons";

const FormModal = ({
  title,
  children,
  isVisible = false,
  onClose,
  customTopMargin,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isVisible);
  }, [isVisible]);

  const customStyles = {
    content: {
      height: "fit-content",
      maxHeight: "90vh",
      width: isMobileOnly || isTablet ? "90%" : "480px",
      top: isMobileOnly || isTablet ? "53%" : "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginTop: !!customTopMargin ? customTopMargin : "0px",
      transform: "translate(-50%, -50%)",
      padding: "40px",
      borderRadius: "8px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
  };

  function closeModal() {
    setIsOpen(false);
    onClose();
  }

  const modal = () => {
    return (
      <>
        <span
          onClick={() => closeModal()}
          className={`absolute ${
            isMobileOnly ? "right-0 top-0 mt-2 h-8 w-8" : "right-4 top-4"
          } cursor-pointer`}
        >
          <Close />
        </span>
        <div className="mb-8 text-3xl">{title}</div>
        {children}
      </>
    );
  };

  return (
    <div className="removed-vertical-padding container">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        closeTimeoutMS={50}
        ariaHideApp={false}
      >
        <div>{modal()}</div>
      </Modal>
    </div>
  );
};

FormModal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customTopMargin: PropTypes.string,
};

export default FormModal;
