import React, { FormEvent, ReactElement, useState } from "react";
import axios from "axios";
import { Job } from "../AcceptJob";

interface AcceptJobFormProps {
  userId: number;
  job: Job;
}

const AcceptJobForm: React.FC<AcceptJobFormProps> = ({ userId, job }) => {
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [bodyValue, setBodyValue] = useState<string | null>(null);
  const [submittingForm, setSubmittingForm] = useState<boolean>(false);

  const { id: jobId, title, contact } = job;

  const reloadPage = () => {
    window.location.reload();
  };

  const handleFormSubmission = async (
    e: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    setSubmittingForm(true);

    const token = document.querySelector(
      "[name=csrf-token]"
    ) as HTMLMetaElement;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

    axios
      .post(
        "/api/v0/learners/jobs/accept",
        {
          job_id: jobId,
          user_id: userId,
          body: bodyValue,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setTimeout(reloadPage, 600);
        }
      })
      .catch((error) => {
        setResponseMessage(error.response.data.message);
      });
  };

  return (
    <>
      <form
        className="m-0"
        onSubmit={async (e) => {
          await handleFormSubmission(e);
        }}
      >
        <label className="text-base font-light text-gray-100" htmlFor="body">
          {i18n.t("learner.jobs.show.body", { contact })}
        </label>
        <textarea
          id="body"
          rows={5}
          className="mb-8 mt-1.5 w-full rounded-lg border-2 border-gray-30 p-3 focus:outline-none focus:ring-0"
          onChange={(event) => setBodyValue(event.target.value)}
        ></textarea>

        <button
          type="submit"
          className={`${
            submittingForm ? "btn-disabled" : "btn-primary btn-flex"
          } btn mb-4 w-full`}
          disabled={submittingForm}
        >
          {submittingForm
            ? i18n.t("shared.please_wait")
            : i18n.t("learner.jobs.show.send_email")}
        </button>
        {responseMessage && (
          <div className="mt-4 text-base text-red-150">{responseMessage}</div>
        )}
      </form>
    </>
  );
};

export default AcceptJobForm;
