import React from "react";

export interface EventCertificate {
  id: number;
  event_title: string;
  event_certificate_created_at: string;
  event_host: string;
  url: string;
  image_url: string;
}

interface EventCertificatesProps {
  eventCertificates: EventCertificate[];
}

const EventCertificates: React.FC<EventCertificatesProps> = ({
  eventCertificates = [],
}) => {
  const renderCertificateCardImage = (eventCertificate: EventCertificate) => {
    return (
      <img
        src={eventCertificate.image_url}
        alt="illustrative image related to course title and content"
        className="h-32 w-full rounded-lg rounded-b-none object-cover md:h-full md:rounded-b-lg"
      />
    );
  };

  return (
    <>
      {eventCertificates.map((eventCertificate: EventCertificate, index) => {
        return (
          <div key={index} className="py-6 md:py-2">
            <div className="flex h-fit flex-wrap items-center rounded-lg rounded-b-lg border-2 border-gray-20 bg-white p-0 shadow-lg md:p-4">
              <div className="relative flex w-full flex-wrap items-center md:w-8/12 md:flex-nowrap">
                <div className="relative h-full w-full md:mr-6 md:h-14 md:w-14 md:min-w-14">
                  {renderCertificateCardImage(eventCertificate)}
                </div>
                <div className="mx-auto mb-0 justify-center pt-9 text-center md:mx-0 md:pt-0 md:text-left">
                  <p className="text-lg font-medium leading-tight">
                    {eventCertificate.event_title}
                  </p>
                  <p className="mt-8 text-xs font-light tracking-widest md:mt-1">
                    {i18n.t("learner.portfolio.certificates.certified_on")}
                    {" " + eventCertificate.event_certificate_created_at}
                  </p>
                  {!!eventCertificate.event_host && (
                    <p className="mt-8 text-xs font-light tracking-widest md:mt-1">
                      {i18n.t("learner.events.show.provided_by")}
                      {" " + eventCertificate.event_host}
                    </p>
                  )}
                </div>
              </div>
              <div className="relative flex w-full justify-end px-4 pb-5 pt-9 md:w-4/12 md:px-6 md:pb-0 md:pt-0">
                <a
                  className="w-full"
                  href={eventCertificate.url}
                  target="_blank"
                >
                  <button className="w-full rounded rounded-md border border-teal-100 px-3 py-2 text-sm font-medium text-gray-100 hover:bg-teal-25">
                    {i18n.t("shared.portfolio.details")}
                  </button>
                </a>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default EventCertificates;
