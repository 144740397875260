import React, { useState } from "react";
import classNames from "classnames";
import axios from "axios";

import ToggleSwitch from "../Shared/ToggleSwitch";

const PublicProfileToggle = ({
  publicProfileUrl,
  currentUserId,
  currentUserName,
  currentUserEmail,
  isPublicProfile,
}) => {
  const [publicProfileStatus, setPublicProfileStatus] =
    useState(isPublicProfile);
  const [errorMessage, setErrorMessage] = useState(null);

  const updateUserPublicProfileAttribute = (publicStatus) => {
    const token = document.querySelector(
      "[name=csrf-token]"
    ) as HTMLMetaElement;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

    axios
      .patch(`/api/v0/users/${currentUserId}`, {
        user: { public_profile: publicStatus },
      })
      .then((response) => {
        if (response.status === 200) {
          setPublicProfileStatus(publicStatus);
        } else {
          setErrorMessage("Oops, something went wrong. Please try again.");
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const displayCopyLink = () => {
    if (publicProfileStatus === true) {
      return (
        <>
          <div className="mt-3.5 flex items-center justify-between space-x-2 rounded border border-teal-25 p-3">
            <input
              className="w-70% truncate rounded p-1.5 font-light focus:outline-none md:w-80% lg:w-60%"
              value={publicProfileUrl}
              readOnly
            />
            <button
              className="btn btn-primary min-w-fit text-xs"
              onClick={(event) => {
                copyToClipboard(event), trackClick();
              }}
            >
              {i18n.t("shared.certificates.index.copy_link")}
            </button>
          </div>
        </>
      );
    }
  };

  const copyToClipboard = (event) => {
    navigator.clipboard.writeText(
      `${publicProfileUrl}&utm_source=academy&utm_campaign=public_profile&utm_medium=website`
    );
    event.target.innerText = `${i18n.t("shared.certificates.index.copied")}`;
  };

  const trackClick = () => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      "academy_public_profile_copy_link_clicked",
      {
        name: currentUserName,
        email: currentUserEmail,
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  const displayErrorMessage = () => {
    if (!!errorMessage) {
      return (
        <div className="text-sm text-gray-70 text-red-150">{errorMessage}</div>
      );
    }
  };

  return (
    <>
      <div
        className={classNames("rounded border p-3", {
          ["border-teal-25"]: publicProfileStatus === true,
          ["border-yellow-25"]: publicProfileStatus === false,
        })}
      >
        <ToggleSwitch
          isToggleChecked={publicProfileStatus}
          updateToggleState={(checked) =>
            updateUserPublicProfileAttribute(checked)
          }
          toggleLabel={i18n.t("shared.certificates.index.make_profile_public")}
        />
      </div>
      {displayCopyLink()}
      {displayErrorMessage()}
    </>
  );
};

export default PublicProfileToggle;
