import React from "react";
import { isMobileOnly, isTablet, isDesktop } from "react-device-detect";

const CollectionCard = ({ bundle }) => {
  const handleCourseTitle = () => {
    if (isMobileOnly && bundle.title.length > 49) {
      return bundle.title.substring(0, 50) + "...";
    } else if (isTablet && bundle.title.length > 54) {
      return bundle.title.substring(0, 55) + "...";
    } else if (isDesktop && bundle.title.length > 59) {
      return bundle.title.substring(0, 60) + "...";
    } else {
      return bundle.title;
    }
  };

  const handleCardImage = () => {
    return (
      <div className="bg-teal-10 relative h-32 rounded-t-lg">
        <img
          src={bundle.image_url}
          alt="illustrative image related to course title and content"
          style={{
            height: "128px",
            width: "100%",
            objectFit: "cover",
            borderRadius: "8px 8px 0px 0px",
          }}
        />
      </div>
    );
  };

  const handleCardUpperSection = () => {
    return (
      <a href={`/public/collections/${bundle.slug}`}>{handleCardImage()}</a>
    );
  };

  const handleCardMiddleSection = () => {
    return (
      <div className="h-16">
        <div className="mb-5 flex justify-between text-base font-light text-gray-100">
          <span>
            {bundle.number_of_courses} {i18n.t("shared.nav.courses")}
          </span>
          {bundle.duration && <span>{bundle.duration}</span>}
        </div>
      </div>
    );
  };

  const handleCtaSection = () => {
    return (
      <div className="mt-2 flex justify-between">
        <a href={handleCtaButtonLink()} className="w-full">
          <button
            className="w-full rounded-md border border-teal-100 px-3 py-1 text-base font-medium text-gray-100 hover:bg-teal-25"
            onClick={trackEvent}
          >
            {handleCtaButtonText()}
          </button>
        </a>
      </div>
    );
  };

  const handleCtaButtonLink = () => {
    if (bundle.user_has_assigned_bundle) {
      return `/my_learning`;
    } else {
      return `/public/collections/${bundle.slug}`;
    }
  };

  const handleCtaButtonText = () => {
    if (bundle.user_has_assigned_bundle) {
      return i18n.t("shared.public.bundles.card.start_learning");
    } else {
      return i18n.t("shared.public.bundles.card.get_started");
    }
  };

  const trackEvent = (event) => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      "academy_bundle_index_bundle_clicked",
      {
        bundle: bundle.title,
        bundle_id: bundle.id,
        user_device: userDevice(),
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  return (
    <div className="rounded rounded-lg bg-white">
      {handleCardUpperSection()}
      <div className="h-52 rounded-b-lg border-2 border-gray-20 px-4 pb-4 pt-3 shadow-md">
        <div className="mb-1 h-16 text-lg font-medium leading-7 text-gray-100">
          {
            <a href={`/public/collections/${bundle.slug}`}>
              {handleCourseTitle()}
            </a>
          }
        </div>
        {handleCardMiddleSection()}
        {handleCtaSection()}
      </div>
    </div>
  );
};

export default CollectionCard;
