import PropTypes from "prop-types";
import React from "react";
import { slide as Menu } from "react-burger-menu";
import { userDevice } from "helpers/tracking";
import {
  AcademyLogo,
  AdminIcon,
  CareCertificateIcon,
  DashboardIcon,
  FaqIcon,
  LoginIcon,
  LogoutIcon,
  MyLearningIcon,
  ProfileIcon,
  RegisterIcon,
  SettingsIcon,
  StaffIcon,
  CoursesIcon,
  EditorIcon,
  CollectionsIcon,
  RolesIcon,
  PurchasesIcon,
  HomeIcon,
  FileIcon,
} from "../Icons";

const MobileNav = ({ navItems }) => {
  const styles = {
    bmBurgerButton: {
      position: "fixed",
      width: "28px",
      height: "24px",
      right: "24px",
      top: "18px",
    },
    bmBurgerBars: {
      background: "#263342",
      height: "4px",
      opacity: "1",
    },
    bmBurgerBarsHover: {
      background: "#263342",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
      width: "100%",
    },
    bmMenu: {
      background: "white",
      fontSize: "24px",
      overflow: "hidden",
      windth: "100%",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#263342",
      display: "flex",
      overflow: "hidden",
      padding: "40px 32px",
      width: "100%",
    },
    bmItem: {
      padding: "8px 0",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
      overflow: "hidden",
    },
  };

  const renderIcon = (icon) => {
    const componentMapping = {
      admin: AdminIcon,
      care_certificate: CareCertificateIcon,
      dashboard: DashboardIcon,
      faqs: FaqIcon,
      login: LoginIcon,
      logout: LogoutIcon,
      my_learning: MyLearningIcon,
      profile: ProfileIcon,
      register: RegisterIcon,
      settings: SettingsIcon,
      staff: StaffIcon,
      courses: CoursesIcon,
      editor: EditorIcon,
      collections: CollectionsIcon,
      roles: RolesIcon,
      purchases: PurchasesIcon,
      referrals: StaffIcon,
      home: HomeIcon,
      documents: FileIcon,
    };

    const Icon = componentMapping[icon];

    return <Icon />;
  };

  const trackEvent = (navItem) => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      "academy_nav_clicked",
      {
        nav_item: navItem,
        user_device: userDevice(),
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  return (
    <>
      <div className="ml-2 p-4">
        <a href="/">
          <AcademyLogo height="24px" width="154px" />
        </a>
      </div>
      <h5 className="fixed right-16 top-4 pt-px text-base text-gray-80">
        Menu
      </h5>
      <Menu itemListClassName={"flex-col my-auto"} styles={styles}>
        {navItems.map((item) => (
          <a
            onClick={() => trackEvent(item.text)}
            key={item.id}
            id={item.id}
            className={`menu-item ${
              item.id === "logout" ? "mb-16 mt-auto text-indian-100" : ""
            }`}
            href={item.href}
          >
            <span className="flex items-center">
              {renderIcon(item.id)}
              {item.text}
            </span>
          </a>
        ))}
      </Menu>
    </>
  );
};

MobileNav.propTypes = {
  navItems: PropTypes.array.isRequired,
};

export default MobileNav;
