import React, { ReactElement, useState } from "react";
import FormModal from "../../Portfolio/Shared/FormModal";
import AcceptJobForm from "./AcceptJobForm";

export interface Job {
  id: number;
  title: string;
  contact: string;
}

interface AcceptJobProps {
  userId: number;
  job: Job;
}

const AcceptJob: React.FC<AcceptJobProps> = ({ userId, job }) => {
  const [isModalVisible, setModalVisibility] = useState<boolean>(false);

  const renderModal = (): ReactElement | null => {
    return (
      <FormModal
        isVisible={isModalVisible}
        title={i18n.t("learner.jobs.show.say_youre_interested")}
        onClose={() => setModalVisibility(false)}
      >
        <AcceptJobForm userId={userId} job={job} />
      </FormModal>
    );
  };

  return (
    <>
      <button
        className={"btn-flex btn w-full"}
        onClick={() => {
          setModalVisibility(true);
        }}
      >
        {i18n.t("learner.jobs.show.say_youre_interested_button")}
      </button>
      {renderModal()}
    </>
  );
};

export default AcceptJob;
