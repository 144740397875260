import React, { useState } from "react";
import axios from "axios";
import FormModal from "../../Portfolio/Shared/FormModal";

interface ConfirmHireModalProps {
  jobMatcherId: number;
  jobTitle: string;
  userName: string;
  activeJob: boolean;
}

const ConfirmHireModal: React.FC<ConfirmHireModalProps> = ({
  jobMatcherId,
  jobTitle,
  userName,
  activeJob,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);

  const reloadPage = () => {
    window.location.reload();
  };

  const openContactModal = () => {
    setIsModalVisible(true);
  };

  const confirmHire = (event) => {
    event.preventDefault();

    const token = document.querySelector(
      "[name=csrf-token]"
    ) as HTMLMetaElement;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

    const formData = new FormData();
    formData.append("job_matcher[status]", "candidate_hired");

    axios
      .put(`/api/v0/c/job_matchers/${jobMatcherId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setIsModalVisible(false);
          reloadPage();
        }
      })
      .catch((error) => {
        setResponseMessage(error.response.data.message);
      });
  };

  const renderModal = () => {
    return (
      <FormModal
        isVisible={isModalVisible}
        title="Mark job listing as hired?"
        onClose={() => setIsModalVisible(false)}
      >
        <div className="mb-6 text-base font-light">
          Looks like you’ve hired{" "}
          <span className="font-medium">{userName}</span> for the{" "}
          <span className="font-medium">{jobTitle}</span> role. If you confirm,
          we will mark this job listing as completed and withdraw it from public
          view.
        </div>
        <div className="md:flex md:justify-between">
          <button
            className="btn btn-flex-empty mb-3 w-full md:mb-0 md:mr-3"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            Cancel
          </button>
          <button onClick={confirmHire} className="btn btn-flex w-full md:ml-3">
            Yes, mark as hired
          </button>
        </div>
        {responseMessage && (
          <div className="mt-4 text-base text-red-150">{responseMessage}</div>
        )}
      </FormModal>
    );
  };

  return (
    <>
      <button
        disabled={!activeJob}
        onClick={openContactModal}
        className={`${
          activeJob ? "btn-flex-secondary" : "btn-disabled opacity-50"
        } btn mt-2 w-full lg:mt-0`}
      >
        Hired
      </button>
      {renderModal()}
    </>
  );
};

export default ConfirmHireModal;
