import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "switch", "hiddenCheckbox"];
  static classes = [
    "buttonEnabled",
    "buttonNotEnabled",
    "switchEnabled",
    "switchNotEnabled"
  ];

  connect() {
    this._toggle();
  }

  changed(event) {
    event.preventDefault();

    this.hiddenCheckboxTarget.checked = !this.hiddenCheckboxTarget.checked;

    this._toggle();
  }

  _toggle() {
    if (this.hiddenCheckboxTarget.checked == true) {
      this.buttonTarget.classList.add(this.buttonEnabledClass);
      this.switchTarget.classList.add(this.switchEnabledClass);
      this.buttonTarget.classList.remove(this.buttonNotEnabledClass);
      this.switchTarget.classList.remove(this.switchNotEnabledClass);
    } else {
      this.buttonTarget.classList.remove(this.buttonEnabledClass);
      this.switchTarget.classList.remove(this.switchEnabledClass);
      this.buttonTarget.classList.add(this.buttonNotEnabledClass);
      this.switchTarget.classList.add(this.switchNotEnabledClass);
    }
  }
}
