import PropTypes from "prop-types";
import React from "react";

const UserForm = ({
  checkboxSelected,
  handleFormSubmission,
  trackUserInput,
  isCarerSelected,
  isNurseSelected,
  isLoading,
}) => {
  return (
    <form
      onSubmit={handleFormSubmission}
      className="flex w-full flex-col items-center"
    >
      <div>
        <p className="pb-6 text-center">
          {i18n.t("shared.modals.flex_banner.inner_modal.subheader")}
        </p>
      </div>
      <div>
        <input
          type="text"
          name="name"
          className="form-control text-gray-700 mb-4 w-60 rounded-lg border-gray-30 text-sm focus:border-teal-100 focus:ring-teal-100"
          onChange={trackUserInput}
          placeholder={i18n.t(
            "shared.modals.flex_banner.inner_modal.postcode_placeholder"
          )}
        />
      </div>
      <div className="mb-12 flex w-80% flex-row flex-wrap justify-center">
        <div className="radio px-3">
          <label>
            <input
              name="workerTypeCheckbox"
              type="radio"
              value={i18n.t(
                "shared.modals.flex_banner.inner_modal.carer_db_value"
              )}
              checked={isCarerSelected}
              className="ml-2 mr-2 h-4 w-4 rounded border-gray-30 text-teal-100 focus:ring-teal-100"
              onChange={checkboxSelected}
            />
            {i18n.t("shared.modals.flex_banner.inner_modal.carer_label")}
          </label>
        </div>
        <div className="radio px-3">
          <label>
            <input
              name="workerTypeCheckbox"
              type="radio"
              value={i18n.t(
                "shared.modals.flex_banner.inner_modal.nurse_db_value"
              )}
              checked={isNurseSelected}
              className="ml-2 mr-2 h-4 w-4 rounded border-gray-30 text-teal-100 focus:ring-teal-100"
              onChange={checkboxSelected}
            />
            {i18n.t("shared.modals.flex_banner.inner_modal.nurse_label")}
          </label>
        </div>
      </div>
      <button type="submit" className="btn btn-primary w-60">
        {isLoading
          ? i18n.t("shared.please_wait")
          : i18n.t("shared.modals.flex_banner.inner_modal.cta_active")}
      </button>
    </form>
  );
};

UserForm.propTypes = {
  checkboxSelected: PropTypes.func.isRequired,
  handleFormSubmission: PropTypes.func.isRequired,
  trackUserInput: PropTypes.func.isRequired,
  isCarerSelected: PropTypes.bool.isRequired,
  isNurseSelected: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default UserForm;
