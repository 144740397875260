function enableSubmitAnswersButton() {
  var submitAnswers = document.querySelector(
    ".final-assessment .actions .submit-answers"
  );
  var assessments = document.querySelectorAll(".assessment");

  for (var i = 0; i < assessments.length; i++) {
    if (!assessments[i].querySelector(".focused")) {
      submitAnswers.classList.add("btn-disabled");
      submitAnswers.setAttribute("disabled", "disabled");
      return false;
    }
  }
  submitAnswers.classList.remove("btn-disabled");
  submitAnswers.removeAttribute("disabled");
}

document.addEventListener("turbolinks:load", function () {
  var quiz = document.querySelector(".quiz");
  var assessment = document.querySelector(".assessment");

  var markAnswerStatus = function (answer, correct) {
    if (correct) {
      document.querySelector(".next-chapter").classList.remove("hidden");
      document.querySelector(".next-chapter-mobile").classList.remove("hidden");
      document.querySelector(".disabled-next-chapter").classList.add("hidden");
      quiz.querySelector(".try-again").classList.add("hidden");
      quiz.querySelector(".pass-feedback").classList.remove("hidden");
      quiz.classList.add("pass");
      quiz.classList.remove("fail");
    } else {
      document.querySelector(".next-chapter").classList.add("hidden");
      document.querySelector(".next-chapter-mobile").classList.add("hidden");
      document
        .querySelector(".disabled-next-chapter")
        .classList.remove("hidden");
      quiz.querySelector(".try-again").classList.remove("hidden");
      quiz.querySelector(".pass-feedback").classList.add("hidden");
      quiz.classList.add("fail");
      quiz.classList.remove("pass");
    }
  };

  var markAnswerFocused = function (answer) {
    var answers = quiz.querySelectorAll(".answer");
    quiz.querySelector(".try-again").classList.add("hidden");
    quiz.querySelector(".pass-feedback").classList.add("hidden");

    for (var i = 0; i < answers.length; i++) {
      if (answers[i] === answer) {
        answers[i].classList.add("selected");
      } else {
        answers[i].classList.remove("selected");
      }
    }
  };

  if (quiz) {
    var answers = quiz.querySelectorAll(".answer");
    var checkAnswerButton = document.querySelector("#check-answer");
    var selectedAnswer = null;

    for (var i = 0; i < answers.length; i++) {
      answers[i].addEventListener("change", function () {
        markAnswerFocused(this);
        return (selectedAnswer = this);
      });
    }

    checkAnswerButton.addEventListener("click", function () {
      markAnswerStatus(
        selectedAnswer,
        selectedAnswer.classList.contains("correct")
      );
    });
  }

  // final assessment
  var answers = document.querySelectorAll(".assessment .answer");

  for (var i = 0; i < answers.length; i++) {
    answers[i].addEventListener(
      "change",
      function () {
        var answer = this;
        var assessment = this.parentNode;
        var sectionAnswers = assessment.querySelectorAll(".answer");

        var multiple_answers = answer.getAttribute("data-multi-answer");

        var jsonAnswers;

        var answersStore = document.querySelector(".answers-store");
        var jsonAnswers = {};

        if (answersStore.value.length) {
          jsonAnswers = JSON.parse(answersStore.value);
        }

        if (multiple_answers === "true") {
          if (
            jsonAnswers[answer.getAttribute("data-module")] &&
            jsonAnswers[answer.getAttribute("data-module")].includes(
              answer.getAttribute("data-answer")
            )
          ) {
            var index = jsonAnswers[answer.getAttribute("data-module")].indexOf(
              answer.getAttribute("data-answer")
            );
            jsonAnswers[answer.getAttribute("data-module")].splice(index, 1);
          } else if (jsonAnswers[answer.getAttribute("data-module")]) {
            jsonAnswers[answer.getAttribute("data-module")].push(
              answer.getAttribute("data-answer")
            );
          } else {
            jsonAnswers[answer.getAttribute("data-module")] = [
              answer.getAttribute("data-answer"),
            ];
          }
        } else {
          jsonAnswers[answer.getAttribute("data-module")] = [
            answer.getAttribute("data-answer"),
          ];
        }

        answersStore.value = JSON.stringify(jsonAnswers);

        if (multiple_answers === "true") {
          for (var x = 0; x < sectionAnswers.length; x++) {
            if (sectionAnswers[x] === answer) {
              if (sectionAnswers[x].classList.contains("focused")) {
                sectionAnswers[x].classList.remove("focused");
              } else {
                sectionAnswers[x].classList.add("focused");
              }
            }
          }
        } else {
          for (var x = 0; x < sectionAnswers.length; x++) {
            if (sectionAnswers[x] === answer) {
              sectionAnswers[x].classList.add("focused");
            } else {
              sectionAnswers[x].classList.remove("focused");
            }
          }
        }

        enableSubmitAnswersButton();
      },
      false
    );
  }
});
