import { Controller } from "stimulus";

export default class extends Controller {
  static classes = ["checked"];
  static targets = ["input"];

  all(event) {
    this.inputTargets.forEach((target) => {
      target.checked = event.target.checked;
      if (target.checked) {
        target.parentElement.classList.add(this.checkedClass);
      } else {
        target.parentElement.classList.remove(this.checkedClass);
      }
    });
  }

  all_but_disabled(event) {
    this.inputTargets.forEach((target) => {
      target.checked = event.target.checked && !target.disabled;
      if (target.checked) {
        target.parentElement.classList.add(this.checkedClass);
      } else {
        target.parentElement.classList.remove(this.checkedClass);
      }
    });
  }

  toggle(event) {
    event.target.parentElement.classList.toggle(this.checkedClass);
  }
}
