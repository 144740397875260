import React, { useState } from "react";
import axios from "axios";

interface WorkHistory {
  id: number;
  role_title: string;
  employer_name: string;
  care_setting: string;
  start_date: string;
  end_date: string;
  country: string;
  parsed_start_date: string;
  parsed_end_date: string;
  current_position: boolean;
}

interface WorkHistoryEditFormProps {
  workHistory: WorkHistory;
  countryList: string[];
}

const WorkHistoryEditForm: React.FC<WorkHistoryEditFormProps> = ({
  workHistory,
  countryList,
}) => {
  const [responseMessage, setResponseMessage] = useState(null);
  const [roleTitleValue, setRoleTitleValue] = useState(workHistory.role_title);
  const [employerNameValue, setEmployerNameValue] = useState(
    workHistory.employer_name
  );
  const [careSettingValue, setCareSettingValue] = useState(
    workHistory.care_setting
  );
  const [startDateValue, setStartDateValue] = useState(workHistory.start_date);
  const [endDateValue, setEndDateValue] = useState(workHistory.end_date);
  const [countryValue, setCountryValue] = useState(workHistory.country);
  const [currentPositionValue, setCurrentPositionValue] = useState(
    workHistory.current_position
  );
  const [submittingForm, setSubmittingForm] = useState(false);

  const reloadPage = () => {
    window.location.reload();
  };

  const handleFormSubmission = (event) => {
    event.preventDefault();
    setSubmittingForm(true);

    const token = document.querySelector(
      "[name=csrf-token]"
    ) as HTMLMetaElement;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

    const requestType = () => {
      if (event.type === "submit") {
        return "patch";
      } else {
        return "delete";
      }
    };

    axios[requestType()](`/api/v0/work_histories/${workHistory.id}`, {
      work_history: {
        role_title: roleTitleValue,
        employer_name: employerNameValue,
        care_setting: careSettingValue,
        start_date: startDateValue,
        end_date: endDateValue,
        country: countryValue,
        current_position: currentPositionValue,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setResponseMessage(response.data.message);
          setTimeout(reloadPage, 600);
        }
      })
      .catch((error) => {
        setResponseMessage(error.response.data.message);
      });
  };

  const displayResponseMessage = () => {
    if (!!responseMessage) {
      return <div className="text-sm text-gray-70">{responseMessage}</div>;
    }
  };

  return (
    <form
      className="m-0"
      onSubmit={(e) => {
        handleFormSubmission(e);
      }}
    >
      <label className="text-base font-light text-gray-100" htmlFor="roleTitle">
        {i18n.t("shared.portfolio.role_title")}
      </label>
      <input
        id="roleTitle"
        className="focus:outline-none mt-1.5 mb-8 w-full rounded-lg border-2 border-gray-30 p-3 focus:ring-0"
        onChange={(event) => setRoleTitleValue(event.target.value)}
        value={roleTitleValue}
      ></input>

      <label
        className="text-base font-light text-gray-100"
        htmlFor="employerName"
      >
        {i18n.t("shared.portfolio.employer_name")}
      </label>
      <input
        id="employerName"
        className="focus:outline-none mt-1.5 mb-8 w-full rounded-lg border-2 border-gray-30 p-3 focus:ring-0"
        onChange={(event) => setEmployerNameValue(event.target.value)}
        value={employerNameValue}
      ></input>

      <label
        className="text-base font-light text-gray-100"
        htmlFor="careSetting"
      >
        {i18n.t("shared.portfolio.care_setting")}
      </label>
      <input
        id="careSetting"
        className="focus:outline-none mt-1.5 w-full rounded-lg border-2 border-gray-30 p-3 focus:ring-0"
        onChange={(event) => setCareSettingValue(event.target.value)}
        value={careSettingValue}
      ></input>
      <p className="mt-1 ml-1 mb-8 text-xs font-light italic text-gray-70">
        {i18n.t("shared.portfolio.care_setting_example")}
      </p>

      <label className="text-base font-light text-gray-100" htmlFor="country">
        {i18n.t("shared.portfolio.country")}
      </label>
      <select
        id="country"
        className="focus:outline-none mt-1.5 mb-8 w-full rounded-lg border-2 border-gray-30 p-3 pr-10 focus:ring-0"
        onChange={(event) => setCountryValue(event.target.value)}
        value={countryValue}
      >
        {countryList.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </select>

      <label className="text-base font-light text-gray-100" htmlFor="startDate">
        {i18n.t("shared.portfolio.start_date")}
      </label>
      <input
        id="startDate"
        type="date"
        className="focus:outline-none mt-1.5 mb-8 w-full rounded-lg border-2 border-gray-30 p-3 focus:ring-0"
        onChange={(event) => setStartDateValue(event.target.value)}
        value={startDateValue}
      ></input>

      <label
        className={`text-base font-light ${
          currentPositionValue ? "text-gray-40" : "text-gray-100"
        }`}
        htmlFor="endDate"
      >
        {i18n.t("shared.portfolio.end_date")}
      </label>
      <input
        id="endDate"
        type="date"
        className={`focus:outline-none mt-1.5 mb-8 w-full rounded-lg border-2 ${
          currentPositionValue
            ? "border-gray-20 text-gray-30"
            : "border-gray-30"
        } p-3 focus:ring-0`}
        onChange={(event) => setEndDateValue(event.target.value)}
        disabled={currentPositionValue}
        value={endDateValue}
      ></input>

      <div className="flex">
        <input
          id="currentPosition"
          type="checkbox"
          className="focus:outline-none outline-none mt-1.5 mb-8 block h-8 w-8 rounded-lg border-2 border-gray-30 p-3 text-teal-100 ring-0 focus:ring-0"
          onChange={() => setCurrentPositionValue(!currentPositionValue)}
          checked={currentPositionValue}
        ></input>
        <label
          className="mt-2 ml-3 text-base font-light text-gray-100"
          htmlFor="currentPosition"
        >
          {i18n.t("shared.portfolio.current_position")}
        </label>
      </div>

      <button
        type="submit"
        className={`${
          submittingForm ? "btn-disabled" : "btn-primary "
        } btn w-full`}
        disabled={submittingForm}
      >
        {submittingForm ? i18n.t("shared.please_wait") : i18n.t("shared.save")}
      </button>

      <button
        type="button"
        className={`${
          submittingForm ? "btn-disabled" : "btn-fourth "
        } btn mt-2.5 mb-4 w-full`}
        onClick={(event) => handleFormSubmission(event)}
        disabled={submittingForm}
      >
        {submittingForm
          ? i18n.t("shared.please_wait")
          : i18n.t("shared.delete")}
      </button>
      <div className="h-4">{displayResponseMessage()}</div>
    </form>
  );
};

export default WorkHistoryEditForm;
