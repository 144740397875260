import React, { useState } from "react";
import FormModal from "../Shared/FormModal";
import PersonalCertificateEditForm from "./PersonalCertificateEditForm";

export interface PersonalCertificate {
  id: number;
  completion_date: string;
  parsed_completion_date: string;
  course_title: string;
  document_filename: string;
  document_present: boolean;
  image_url: string;
}

interface PersonalCertificatesProps {
  personalCertificates: PersonalCertificate[];
  isCurrentUser: boolean;
}

const PersonalCertificates: React.FC<PersonalCertificatesProps> = ({
  personalCertificates = [],
  isCurrentUser,
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedPersonalCertificate, setSelectedPersonalCertificate] =
    useState(null);

  const renderModal = (personalCertificate: PersonalCertificate) => {
    return (
      <FormModal
        isVisible={isModalVisible}
        title={i18n.t("shared.portfolio.certificate")}
        onClose={() => setModalVisibility(false)}
      >
        <PersonalCertificateEditForm
          personalCertificate={personalCertificate}
        />
      </FormModal>
    );
  };

  const handleCta = (personalCertificate: PersonalCertificate) => {
    if (!personalCertificate.document_present) {
      return null;
    } else {
      return (
        <div className="relative flex w-full justify-end px-4 pb-5 pt-9 md:w-4/12 md:px-6 md:pb-0 md:pt-0">
          <a
            className="w-full"
            href={`/public/external_certificates/${personalCertificate.id}`}
            target="_blank"
          >
            <button
              className="w-full rounded rounded-md border border-teal-100 px-3 py-2 text-sm font-medium text-gray-100 hover:bg-teal-25"
              // onClick={trackEvent}
            >
              {i18n.t("shared.portfolio.details")}
            </button>
          </a>
        </div>
      );
    }
  };

  const renderEditButton = (personalCertificate: PersonalCertificate) => {
    return (
      <a
        className="cursor-pointer text-xs font-light tracking-wider text-gray-80 underline md:tracking-wide"
        onClick={() => {
          setSelectedPersonalCertificate(personalCertificate);
          setModalVisibility(true);
        }}
      >
        {i18n.t("shared.edit")}
      </a>
    );
  };

  const renderCertificateCardImage = (
    personalCertificate: PersonalCertificate
  ) => {
    return (
      <img
        src={personalCertificate.image_url}
        alt="illustrative image related to course title and content"
        className="h-32 w-full rounded-lg rounded-b-none object-cover md:h-full md:rounded-b-lg"
      />
    );
  };

  return (
    <>
      {personalCertificates.map(
        (personalCertificate: PersonalCertificate, index) => {
          return (
            <div key={index} className="py-6 md:py-2">
              <div className="flex h-fit flex-wrap items-center rounded-lg rounded-b-lg border-2 border-gray-20 bg-white p-0 shadow-lg md:p-4">
                <div className="relative flex w-full flex-wrap items-center md:w-8/12 md:flex-nowrap">
                  <div className="relative h-full w-full md:mr-6 md:h-14 md:w-14 md:min-w-14">
                    {renderCertificateCardImage(personalCertificate)}
                  </div>
                  <div className="mx-auto mb-0 justify-center pt-9 text-center md:mx-0 md:pt-0 md:text-left">
                    <p className="text-lg font-medium leading-tight">
                      {personalCertificate.course_title}
                    </p>
                    <p className="mt-8 text-xs font-light tracking-widest md:mt-1">
                      {i18n.t("shared.portfolio.externally_certified_on")}
                      {" " + personalCertificate.parsed_completion_date}
                    </p>
                    {isCurrentUser && (
                      <p>{renderEditButton(personalCertificate)}</p>
                    )}
                  </div>
                </div>
                {handleCta(personalCertificate)}
              </div>
            </div>
          );
        }
      )}
      {renderModal(selectedPersonalCertificate)}
    </>
  );
};

export default PersonalCertificates;
