import React, { useState } from "react";
import FormModal from "../Shared/FormModal";
import emailLogo from "../../../../assets/images/icons/email-white.png";
import axios from "axios";
import emailSentLogo from "../../../../assets/images/icons/email_sent.png";

interface ContactCandidateProps {
  userName: string;
  userId: number;
  jobId: number;
  isLearnerContactable: boolean;
}

const ContactCandidate: React.FC<ContactCandidateProps> = ({
  userName,
  userId,
  jobId,
  isLearnerContactable,
}) => {
  const baseUrl = window.location.origin;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [contactable, setContactable] = useState(isLearnerContactable);

  const redirectToJobPage = () => {
    window.location.href = `${baseUrl}/c/jobs/${jobId}`;
  };

  const openContactModal = () => {
    setIsModalVisible(true);
  };

  const sendInvitation = (event) => {
    event.preventDefault();

    const token = document.querySelector(
      "[name=csrf-token]"
    ) as HTMLMetaElement;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

    const params = { user_id: userId, job_id: jobId };

    axios
      .post("/api/v0/c/candidates/send_invitation", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setIsModalVisible(false);
          setContactable(false);
          setTimeout(redirectToJobPage, 1200);
        }
      })
      .catch((error) => {
        setResponseMessage(error.response.data.message);
      });
  };

  const renderModal = () => {
    return (
      <FormModal
        isVisible={isModalVisible}
        title={`Contact ${userName}?`}
        onClose={() => {
          setIsModalVisible(false);
          setResponseMessage(null);
        }}
      >
        <div className="tex-base mb-6 font-light">
          <span className="font-medium">
            Please confirm that you would like to reach out to this candidate.
          </span>{" "}
          We will then send an automated email to {userName} containing details
          of this job listing and allow to approve or decline.
        </div>
        <div className="md:flex md:justify-between">
          <button
            className="btn btn-flex-empty mb-3 w-full md:mb-0 md:mr-3"
            onClick={() => {
              setIsModalVisible(false);
              setResponseMessage(null);
            }}
          >
            Cancel
          </button>
          <button
            onClick={sendInvitation}
            className="btn btn-flex w-full md:ml-3"
          >
            <div className="flex items-center justify-center">
              <img className="w-6 pr-2" src={emailLogo}></img>
              Yes, send email
            </div>
          </button>
        </div>
        {responseMessage && (
          <div className="mt-4 text-base text-red-150">{responseMessage}</div>
        )}
      </FormModal>
    );
  };

  return (
    <>
      <button
        disabled={!contactable}
        onClick={openContactModal}
        className={`${
          contactable ? "btn-flex" : "btn-disabled"
        } btn flex w-full items-center justify-center text-sm`}
      >
        {contactable ? (
          <>
            <img className="w-8 pr-2" src={emailLogo} alt="Contact"></img>
            Contact
          </>
        ) : (
          <>
            <img
              className="w-8 pr-2"
              src={emailSentLogo}
              alt="Email sent"
            ></img>
            Email sent
          </>
        )}
      </button>
      {renderModal()}
    </>
  );
};

export default ContactCandidate;
