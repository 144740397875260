import React, { FormEvent, ReactElement, useState } from "react";
import axios from "axios";

interface DeclineJobProps {
  userId: number;
  jobId: number;
}

const DeclineJob: React.FC<DeclineJobProps> = ({ userId, jobId }) => {
  const [submittingForm, setSubmittingForm] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);

  const reloadPage = () => {
    window.location.reload();
  };

  const handleOnClick = async (
    e: FormEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();
    setSubmittingForm(true);

    const token = document.querySelector(
      "[name=csrf-token]"
    ) as HTMLMetaElement;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

    axios
      .post(
        "/api/v0/learners/jobs/decline",
        {
          job_id: jobId,
          user_id: userId,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setTimeout(reloadPage, 600);
        }
      })
      .catch((error) => {
        setResponseMessage(error.response.data.message);
      });
  };

  const displayButtonText = () => {
    if (submittingForm) {
      return i18n.t("shared.please_wait");
    }
    return i18n.t("learner.jobs.show.decline_job_button");
  };

  return (
    <>
      <button
        type="submit"
        className={`${
          submittingForm ? "btn-disabled" : "btn-flex-secondary"
        } btn w-full md:mb-0 md:mr-3`}
        disabled={submittingForm}
        onClick={async (e) => {
          await handleOnClick(e);
        }}
      >
        {displayButtonText()}
      </button>
      {responseMessage && (
        <div className="mt-4 text-base text-red-150">{responseMessage}</div>
      )}
    </>
  );
};

export default DeclineJob;
