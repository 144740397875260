import { Controller } from "stimulus";
import Inputmask from "inputmask";

export default class extends Controller {
  static targets = ["dateInput"];

  connect() {
    this._addMasks();
  }

  _addMasks() {
    this.dateInputTargets.forEach(element =>
      Inputmask({ alias: "datetime", inputFormat: "dd/mm/yyyy" }).mask(element)
    );
  }
}
