import React, { useState } from "react";
import { userDevice } from "helpers/tracking";
import "../Modals/styles.scss";

const FlexOpportunityAdvert = ({ currentUser, signupUrl }) => {
  const trackFlexSignUpClick = () => {
    window.analytics.track(
      "academy_flex_signup_link_clicked",
      {
        email: currentUser.email,
        user_device: userDevice(),
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  const button = (buttonText: string, url: string, clickAction: () => void): JSX.Element => {
    return (
      <a
        className="btn mt-4 w-full rounded bg-white text-base font-medium text-gray-100 hover:bg-teal-25 md:mt-0 md:w-auto"
        href={url}
        target="_blank"
        onClick={clickAction}
      >
        {buttonText}
      </a>
    );
  };

  return (
    <>
      <div className="flex w-full flex-col justify-around rounded bg-teal-100 px-5 py-8 text-center md:flex-row md:text-left">
        <div className="self-center text-lg text-white">
          {i18n.t("shared.modals.flex_banner.headline")}
        </div>
        {currentUser.country_of_residence === "en-ca"
          ? button(
              i18n.t("shared.modals.flex_banner.inner_modal.signup_now"),
              signupUrl,
              trackFlexSignUpClick
            )
          : button(
              i18n.t("shared.modals.flex_banner.headline_cta"),
              "https://www.florence.co.uk/looking-for-work/jobs/",
              null
            )}
      </div>
    </>
  );
};

export default FlexOpportunityAdvert;
