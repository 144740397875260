import React, { useState } from "react";
import axios from "axios";
import { JobPreference } from "./index";

import ToggleSwitch from "../Shared/ToggleSwitch";

interface JobPreferenceFormProps {
  jobPreference: JobPreference;
  userId: number;
  dailyScheduleOptions: string[];
  weeklyScheduleOptions: string[];
  roleTypeOptions: string[];
  hasAdequatePortfolioContent: boolean;
}

const JobPreferenceForm: React.FC<JobPreferenceFormProps> = ({
  jobPreference,
  userId,
  dailyScheduleOptions,
  weeklyScheduleOptions,
  roleTypeOptions,
  hasAdequatePortfolioContent,
}) => {
  const [responseMessage, setResponseMessage] = useState(null);
  const [submittingForm, setSubmittingForm] = useState(false);

  const [accommodationRequiredValue, setAccommodationRequiredValue] = useState(
    jobPreference?.accommodation_required || false
  );
  const [dailyShiftScheduleValue, setDailyShiftScheduleValue] = useState(
    jobPreference?.daily_shift_schedule || dailyScheduleOptions[0][1]
  );
  const [dbsUpToDateValue, setDbsUpToDateValue] = useState(
    jobPreference?.dbs_up_to_date || false
  );
  const [fullTimeValue, setFullTimeValue] = useState(
    jobPreference?.full_time || true
  );
  const [postCodeValue, setPostCodeValue] = useState(jobPreference?.post_code);
  const [roleTypeValue, setRoleTypeValue] = useState(
    jobPreference?.role_type || roleTypeOptions[0][1]
  );
  const [sponsorshipRequiredValue, setSponsorshipRequiredValue] = useState(
    jobPreference?.sponsorship_required || false
  );
  const [ukResidentValue, setUkResidentValue] = useState(
    jobPreference?.uk_resident || false
  );
  const [vaccinationsUpToDateValue, setVaccinationsUpToDateValue] = useState(
    jobPreference?.vaccinations_up_to_date || false
  );
  const [validUkDrivingLicenseValue, setValidUkDrivingLicenseValue] = useState(
    jobPreference?.valid_uk_driving_license || false
  );
  const [weeklyShiftScheduleValue, setWeeklyShiftScheduleValue] = useState(
    jobPreference?.weekly_shift_schedule || weeklyScheduleOptions[0][1]
  );
  const [lookingInTheUkValue, setLookingInTheUkValue] = useState(
    !!jobPreference || false
  );

  const reloadPage = () => {
    window.location.reload();
  };

  const handleFormSubmission = (event) => {
    event.preventDefault();
    setResponseMessage(null);
    setSubmittingForm(true);

    const token = document.querySelector(
      "[name=csrf-token]"
    ) as HTMLMetaElement;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

    const requestType = () => {
      return !!jobPreference ? "patch" : "post";
    };

    const url = () => {
      return !!jobPreference
        ? `/api/v0/job_preferences/${jobPreference?.id}`
        : "/api/v0/job_preferences";
    };

    axios[requestType()](url(), {
      job_preference: {
        user_id: userId,
        accommodation_required: accommodationRequiredValue,
        daily_shift_schedule: dailyShiftScheduleValue,
        dbs_up_to_date: dbsUpToDateValue,
        full_time: fullTimeValue,
        post_code: postCodeValue,
        role_type: roleTypeValue,
        sponsorship_required: sponsorshipRequiredValue,
        uk_resident: ukResidentValue,
        vaccinations_up_to_date: vaccinationsUpToDateValue,
        valid_uk_driving_license: validUkDrivingLicenseValue,
        weekly_shift_schedule: weeklyShiftScheduleValue,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(reloadPage, 600);
        }
      })
      .catch((error) => {
        setResponseMessage(error.response.data.message);
        setSubmittingForm(false);
      });
  };

  const displayResponseMessage = () => {
    if (!!responseMessage) {
      return <div className="text-sm text-red-150">{responseMessage}</div>;
    }
  };

  const renderLookingInTheUkCheckbox = () => {
    return (
      <span className="mb-6 flex flex-wrap items-center justify-between">
        <label
          className="mr-4 text-base font-light text-gray-100"
          htmlFor="lookingInTheUk"
        >
          {i18n.t("shared.portfolio.looking_in_uk_label")}
        </label>
        <input
          id="lookingInTheUk"
          type="checkbox"
          className="block h-6 w-6 cursor-pointer rounded-lg border-2 border-gray-30 p-3 text-teal-100 outline-none ring-0 focus:outline-none focus:ring-0"
          onChange={() => setLookingInTheUkValue(!lookingInTheUkValue)}
          checked={lookingInTheUkValue}
        ></input>
      </span>
    );
  };

  const renderForm = () => {
    return (
      <form
        className="m-0"
        onSubmit={(e) => {
          handleFormSubmission(e);
        }}
      >
        <span className="mb-6 flex flex-wrap items-center">
          <span
            className={`mr-3 text-base font-light ${
              !fullTimeValue ? "text-gray-100" : "text-gray-40"
            }`}
          >
            {i18n.t("shared.portfolio.part_time_toggle_label")}
          </span>
          <ToggleSwitch
            isToggleChecked={fullTimeValue}
            updateToggleState={(checked) => setFullTimeValue(checked)}
          />
          <span
            className={`ml-3 mr-3 text-base font-light ${
              fullTimeValue ? "text-gray-100" : "text-gray-40"
            }`}
          >
            {i18n.t("shared.portfolio.full_time_toggle_label")}
          </span>
        </span>

        <span className="mb-6 flex flex-wrap items-center justify-between">
          <label
            className="mr-4 text-base font-light text-gray-100"
            htmlFor="ukResident"
          >
            {i18n.t("shared.portfolio.uk_resident_label")}
          </label>
          <input
            id="ukResident"
            type="checkbox"
            className="block h-6 w-6 cursor-pointer rounded-lg border-2 border-gray-30 p-3 text-teal-100 outline-none ring-0 focus:outline-none focus:ring-0"
            onChange={() => setUkResidentValue(!ukResidentValue)}
            checked={ukResidentValue}
          ></input>
        </span>

        <span className="mb-6 flex flex-wrap items-center justify-between">
          <label
            className="text-base font-light text-gray-100"
            htmlFor="sponsorshipRequired"
          >
            {i18n.t("shared.portfolio.sponsorship_required_label")}
          </label>
          <input
            id="sponsorshipRequired"
            type="checkbox"
            className="block h-6 w-6 cursor-pointer rounded-lg border-2 border-gray-30 p-3 text-teal-100 focus:border-teal-100 focus:ring-0"
            onChange={() =>
              setSponsorshipRequiredValue(!sponsorshipRequiredValue)
            }
            checked={sponsorshipRequiredValue}
          ></input>
        </span>

        <label
          className={`text-base font-light ${
            ukResidentValue ? "text-gray-100" : "text-gray-40"
          }`}
          htmlFor="postCode"
        >
          {i18n.t("shared.portfolio.post_code_label")}
        </label>
        <p
          className={`mt-1 text-xs font-light italic ${
            ukResidentValue ? "text-gray-70" : "text-gray-40"
          }`}
        >
          {i18n.t("shared.portfolio.post_code_helper")}
        </p>
        <input
          id="postCode"
          className={`mb-6 mt-1.5 w-full rounded-lg border-2 px-3 py-1.5 focus:border-teal-100 focus:ring-0 ${
            ukResidentValue ? "border-gray-30" : "border-gray-20 text-gray-30"
          }`}
          onChange={(event) => setPostCodeValue(event.target.value)}
          disabled={!ukResidentValue}
          value={postCodeValue}
        ></input>

        <label
          className="text-base font-light text-gray-100"
          htmlFor="dailyShiftSchedule"
        >
          {i18n.t("shared.portfolio.daily_schedule_label")}
        </label>
        <select
          id="dailyShiftSchedule"
          className="mb-6 mt-1.5 w-full rounded-lg border-2 border-gray-30 px-3 py-1.5 focus:border-teal-100 focus:ring-0"
          onChange={(event) => setDailyShiftScheduleValue(event.target.value)}
          value={dailyShiftScheduleValue}
        >
          {dailyScheduleOptions.map((option, index) => (
            <option key={index} value={option[1]}>
              {option[0]}
            </option>
          ))}
        </select>

        <label
          className="text-base font-light text-gray-100"
          htmlFor="weeklyShiftSchedule"
        >
          {i18n.t("shared.portfolio.weekly_schedule_label")}
        </label>
        <select
          id="weeklyShiftSchedule"
          className="mb-6 mt-1.5 w-full rounded-lg border-2 border-gray-30 px-3 py-1.5 focus:border-teal-100 focus:ring-0"
          onChange={(event) => setWeeklyShiftScheduleValue(event.target.value)}
          value={weeklyShiftScheduleValue}
        >
          {weeklyScheduleOptions.map((option, index) => (
            <option key={index} value={option[1]}>
              {option[0]}
            </option>
          ))}
        </select>

        <label
          className="text-base font-light text-gray-100"
          htmlFor="roleType"
        >
          {i18n.t("shared.portfolio.role_type_label")}
        </label>
        <select
          id="roleType"
          className="mb-8 mt-1.5 w-full rounded-lg border-2 border-gray-30 px-3 py-1.5 focus:border-teal-100 focus:ring-0"
          onChange={(event) => setRoleTypeValue(event.target.value)}
          value={roleTypeValue}
        >
          {roleTypeOptions.map((option, index) => (
            <option key={index} value={option[1]}>
              {option[0]}
            </option>
          ))}
        </select>

        <span className="mb-6 flex flex-wrap items-center justify-between">
          <label
            className="mr-4 text-base font-light text-gray-100"
            htmlFor="dbsUpToDate"
          >
            {i18n.t("shared.portfolio.dbs_up_to_date_label")}
          </label>
          <input
            id="dbsUpToDate"
            type="checkbox"
            className="block h-6 w-6 cursor-pointer rounded-lg border-2 border-gray-30 p-3 text-teal-100 outline-none ring-0 focus:outline-none focus:ring-0"
            onChange={() => setDbsUpToDateValue(!dbsUpToDateValue)}
            checked={dbsUpToDateValue}
          ></input>
        </span>

        <span className="mb-6 flex flex-wrap items-center justify-between">
          <label
            className="mr-4 text-base font-light text-gray-100"
            htmlFor="vaccinationsUpToDate"
          >
            {i18n.t("shared.portfolio.vaccinations_up_to_date_label")}
          </label>
          <input
            id="vaccinationsUpToDate"
            type="checkbox"
            className="block h-6 w-6 cursor-pointer rounded-lg border-2 border-gray-30 p-3 text-teal-100 outline-none ring-0 focus:outline-none focus:ring-0"
            onChange={() =>
              setVaccinationsUpToDateValue(!vaccinationsUpToDateValue)
            }
            checked={vaccinationsUpToDateValue}
          ></input>
        </span>

        <span className="mb-6 flex flex-wrap items-center justify-between">
          <label
            className="mr-4 text-base font-light text-gray-100"
            htmlFor="validUkDrivingLicense"
          >
            {i18n.t("shared.portfolio.valid_uk_driving_license_label")}
          </label>
          <input
            id="validUkDrivingLicense"
            type="checkbox"
            className="block h-6 w-6 cursor-pointer rounded-lg border-2 border-gray-30 p-3 text-teal-100 outline-none ring-0 focus:outline-none focus:ring-0"
            onChange={() =>
              setValidUkDrivingLicenseValue(!validUkDrivingLicenseValue)
            }
            checked={validUkDrivingLicenseValue}
          ></input>
        </span>

        <span className="mb-6 flex flex-wrap items-center justify-between">
          <label
            className="mr-4 text-base font-light text-gray-100"
            htmlFor="accommodationRequired"
          >
            {i18n.t("shared.portfolio.accommodation_required_label")}
          </label>
          <input
            id="accommodationRequired"
            type="checkbox"
            className="block h-6 w-6 cursor-pointer rounded-lg border-2 border-gray-30 p-3 text-teal-100 outline-none ring-0 focus:outline-none focus:ring-0"
            onChange={() =>
              setAccommodationRequiredValue(!accommodationRequiredValue)
            }
            checked={accommodationRequiredValue}
          ></input>
        </span>

        <button
          type="submit"
          className={`${
            submittingForm ? "btn-disabled" : "btn-primary "
          } btn w-full`}
          disabled={submittingForm}
        >
          {submittingForm
            ? i18n.t("shared.please_wait")
            : i18n.t("shared.save")}
        </button>
        <div className="h-4 pt-2">{displayResponseMessage()}</div>
      </form>
    );
  };

  const renderNotLookingInTheUkError = () => {
    return (
      <div className="text-sm text-red-150">
        {i18n.t("shared.portfolio.looking_in_the_uk_error")}
      </div>
    );
  };

  return hasAdequatePortfolioContent ? (
    <>
      {renderLookingInTheUkCheckbox()}
      {lookingInTheUkValue ? renderForm() : renderNotLookingInTheUkError()}
    </>
  ) : (
    <div className=" text-gray-100">
      {i18n.t("shared.portfolio.inadequate_content_copy")}
    </div>
  );
};

export default JobPreferenceForm;
