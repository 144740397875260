import React, { useState } from "react";
import { isMobileOnly } from "react-device-detect";
import FormModal from "../Shared/FormModal";
import WorkHistoryEditForm from "./WorkHistoryEditForm";

interface WorkHistory {
  id: number;
  role_title: string;
  employer_name: string;
  care_setting: string;
  start_date: string;
  end_date: string;
  country: string;
  parsed_start_date: string;
  parsed_end_date: string;
  current_position: boolean;
}

interface WorkHistoriesProps {
  workHistories: WorkHistory[];
  isCurrentUser: boolean;
  countryList: string[];
}

const WorkHistories: React.FC<WorkHistoriesProps> = ({
  workHistories = [],
  isCurrentUser,
  countryList,
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedWorkHistory, setSelectedWorkHistory] = useState(null);

  const renderDates = (workHistory: WorkHistory) => {
    const secondaryText = workHistory.current_position
      ? i18n.t("shared.portfolio.current")
      : workHistory.parsed_end_date;

    return `${workHistory.parsed_start_date} - ${secondaryText}`;
  };

  const renderModal = (workHistory: WorkHistory) => {
    return (
      <FormModal
        isVisible={isModalVisible}
        title={i18n.t("shared.portfolio.work_experience")}
        onClose={() => setModalVisibility(false)}
      >
        <WorkHistoryEditForm
          workHistory={workHistory}
          countryList={countryList}
        />
      </FormModal>
    );
  };

  const renderEditButton = (workHistory: WorkHistory) => {
    if (!isCurrentUser) return null;

    return (
      <a
        className="cursor-pointer text-xs font-medium tracking-wider text-gray-80 underline md:tracking-wide"
        onClick={() => {
          setSelectedWorkHistory(workHistory);
          setModalVisibility(true);
        }}
      >
        {i18n.t("shared.edit")}
      </a>
    );
  };

  return (
    <>
      <p className="mb-2 ml-1 mt-6 text-xl font-medium leading-tight lg:mt-4">
        {i18n.t("shared.portfolio.work_history")}
      </p>
      {workHistories.map((workHistory: WorkHistory, index) => {
        return (
          <div key={index} className="py-2">
            <div className="flex h-fit flex-wrap items-center justify-between rounded-lg rounded-b-lg border-2 border-gray-20 bg-white p-4 shadow-lg">
              <div className="w-full flex-wrap items-center md:w-8/12 md:flex-nowrap">
                <div className="my-0 justify-center text-left">
                  <div className="flex justify-between">
                    <p className="text-lg font-medium leading-tight">
                      {workHistory.role_title}
                    </p>
                    <p className="md:hidden">{renderEditButton(workHistory)}</p>
                  </div>
                  <p className="mt-1 text-xs font-light tracking-widest text-gray-60">
                    {workHistory.employer_name}{" "}
                    {!!workHistory.care_setting &&
                      `(${workHistory.care_setting})`}
                  </p>
                  <p className="mt-1 text-xs font-light tracking-widest text-gray-60">
                    {workHistory.country}
                  </p>
                  <p className="mt-1 inline-block text-xs font-light tracking-widest text-gray-60">
                    {renderDates(workHistory)}
                  </p>
                </div>
              </div>
              <div className="hidden w-full md:flex md:w-4/12 md:justify-center">
                {renderEditButton(workHistory)}
              </div>
            </div>
          </div>
        );
      })}
      {renderModal(selectedWorkHistory)}
    </>
  );
};

export default WorkHistories;
