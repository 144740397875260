import React, { useState } from "react";

interface CheckoutButtonProps {
  checkoutType: string;
  checkoutSlug?: string;
  classNames?: string;
  containerClassNames?: string;
  ctaCopy: string;
  originPage: string;
  price?: string;
  trackEventName?: string;
  trackEventKeyValues?: {};
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({
  checkoutType,
  checkoutSlug,
  classNames,
  containerClassNames,
  ctaCopy,
  originPage,
  price,
  trackEventName,
  trackEventKeyValues,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const requestBodyKey = `${checkoutType}_slug`;

  const disableButton = () => {
    setIsDisabled(true);
  };

  const renderCtaCopy = () => {
    return !!price ? ctaCopy + " " + price : ctaCopy;
  };

  const checkout = async () => {
    fetch(`/checkouts/buy_${checkoutType}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        [requestBodyKey]: checkoutSlug,
        origin_page: originPage,
      }),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (session) {
        return window.stripeSession.redirectToCheckout({
          sessionId: session.id,
        });
      })
      .then(function (result) {
        if (result.error) {
          alert(result.error.message);
        }
      })
      .catch(function (error) {
        console.error("Error:", error);
      });
  };

  const trackEvent = () => {
    if (
      typeof window.analytics !== "object" ||
      !trackEventKeyValues ||
      !trackEventName
    )
      return;
    window.analytics.track(trackEventName, trackEventKeyValues, {
      All: false,
      Mixpanel: true,
    });
  };

  const checkoutAndTrack = () => {
    disableButton();
    checkout();
    trackEvent();
  };

  return (
    <div className={containerClassNames}>
      <button
        className={classNames || "btn btn-secondary block w-full text-sm"}
        onClick={checkoutAndTrack}
        disabled={isDisabled}
      >
        {isDisabled ? i18n.t("shared.please_wait") : renderCtaCopy()}
      </button>
    </div>
  );
};

export default CheckoutButton;
