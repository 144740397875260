import React, { useState } from "react";
import FormModal from "../Shared/FormModal";
import DownloadForm from "./DownloadForm";

interface DownloadProps {
  userName: string;
  userEmail: string;
}

const Download: React.FC<DownloadProps> = ({ userName, userEmail }) => {
  const [isModalVisible, setModalVisibility] = useState(false);

  return (
    <>
      <button
        className="w-fit rounded border border-teal-100 px-3 py-2 text-xs font-medium text-gray-100 hover:bg-teal-25"
        onClick={() => setModalVisibility(true)}
      >
        {i18n.t("shared.portfolio.download_cta")}
      </button>
      <FormModal
        isVisible={isModalVisible}
        title={i18n.t("shared.portfolio.modal_title")}
        onClose={() => setModalVisibility(false)}
      >
        <DownloadForm
          closeModal={() => setModalVisibility(false)}
          userName={userName}
          userEmail={userEmail}
        />
      </FormModal>
    </>
  );
};

export default Download;
