import React from "react";
import Modal from "react-modal";
import axios from "axios";
import "../styles.scss";

import { Close } from "components/Shared/Icons";
import { isMobileOnly } from "react-device-detect";
import { userDevice } from "helpers/tracking";

interface ReferAFriendProps {
  userId: number;
}

const ReferAFriend: React.FC<ReferAFriendProps> = ({ userId }) => {
  const [modalIsOpen, setIsOpen] = React.useState(true);

  const trackEvent = (event_name) => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      event_name,
      { user_device: userDevice() },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  const closeModalAndUpdateUser = () => {
    setIsOpen(false);

    const token = document.querySelector(
      "[name=csrf-token]"
    ) as HTMLMetaElement;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
    axios.patch(`/api/v0/users/${userId}`, {
      user: { show_referral_modal: false },
    });
  };

  const handleOnClick = () => {
    trackEvent("academy_raf_modal_button_clicked");
    closeModalAndUpdateUser();
    window.open(process.env.BASE_URL + "/referrals/new", "_blank");
  };

  function closeModal() {
    trackEvent("academy_raf_modal_closed");
    closeModalAndUpdateUser();
  }

  const customStyles = {
    content: {
      width: isMobileOnly ? "90%" : "35rem",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      borderRadius: "0.5rem",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Refer A Friend Modal"
        closeTimeoutMS={500}
        ariaHideApp={false}
      >
        <div className="relative">
          <div
            className={`border-b-1 border-color flex h-20 items-center justify-center bg-teal-25 text-2xl`}
          >
            10% off for you and a friend!
            <span
              onClick={() => closeModal()}
              className={`absolute ${
                isMobileOnly ? "right-1 top-1 h-8 w-8" : "right-5 h-12 w-12"
              } flex cursor-pointer items-center justify-center rounded`}
            >
              <Close />
            </span>
          </div>
        </div>
        <div className="p-8 text-center">
          <p className="my-4">
            Refer a friend and get 10% off your next purchase.
          </p>
          <p className="my-4">They'll get 10% off their first purchase.</p>
          <button
            onClick={() => handleOnClick()}
            className="btn btn-primary my-4"
          >
            Refer a friend
          </button>
          <p className="my-4 text-xs text-gray-70">
            Not right now? You can also find the referral page under "My
            Account"
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default ReferAFriend;
