import PropTypes from "prop-types";
import React from "react";
import { isMobileOnly, isTablet, isDesktop } from "react-device-detect";
import { userDevice } from "helpers/tracking";
import RegisterInterestForm from "./registerInterestForm";

const CourseCard = ({ courseData, showAuthorAccess, currentUserEmail }) => {
  const handleCourseTitle = () => {
    if (isMobileOnly && courseData.title.length > 49) {
      return courseData.title.substring(0, 50) + "...";
    } else if ((isTablet || isDesktop) && courseData.title.length > 54) {
      return courseData.title.substring(0, 55) + "...";
    } else {
      return courseData.title;
    }
  };

  const ctaButtonText = () => {
    const percentageComplete = courseData.percentage_complete;
    if (courseData.external && !!courseData.external_content_url) {
      return i18n.t("shared.courses.course.in_person_with_external_content");
    } else if (courseData.external) {
      return i18n.t("shared.courses.course.in_person");
    } else if (percentageComplete === 0) {
      return i18n.t("shared.courses.course.start_cta");
      // retake_cta only when all course chapters completed AND learner has taken the final assessment
    } else if (
      percentageComplete === 100 &&
      courseData.attempt_completed === true
    ) {
      return i18n.t("shared.courses.course.retake_cta");
    } else {
      return i18n.t("shared.courses.course.continue_cta");
    }
  };

  const trackEvent = (event) => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      "academy_carousel_course_clicked",
      {
        course: courseData.title,
        course_id: courseData.id,
        course_status: ctaButtonText(),
        user_device: userDevice(),
        course_category: courseData.course_categories,
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  const handleCtaButtonLink = (ctaText) => {
    if (ctaText === i18n.t("shared.courses.course.continue_cta")) {
      return courseData.link_path;
    } else if (
      ctaText ===
        i18n.t("shared.courses.course.in_person_with_external_content") &&
      !!courseData.external_content_url
    ) {
      return courseData.external_content_url;
    } else if (ctaText === i18n.t("shared.courses.course.in_person")) {
      return null;
    } else {
      return `/public/courses/${courseData.slug}`;
    }
  };

  const handleOneOffTag = () => {
    if (courseData.due_date === "One off") {
      return (
        <span className="absolute left-2 top-2 rounded bg-white px-4 py-1 text-base font-normal text-gray-100">
          {i18n.t("shared.courses.course.one_off")}
        </span>
      );
    }
  };

  const handleRequiredTag = () => {
    if (courseData.required) {
      return (
        <span className="absolute right-2 top-2 rounded bg-white px-4 py-1 text-base font-normal text-gray-100">
          {i18n.t("shared.courses.course.required")}
        </span>
      );
    }
  };

  const handleComingSoonTag = () => {
    if (courseData.coming_soon) {
      return (
        <span className="absolute right-2 top-2 rounded bg-white px-4 py-1 text-base font-normal text-gray-100">
          {i18n.t("shared.courses.course.coming_soon")}
        </span>
      );
    }
  };

  const handleCardImage = () => {
    return (
      <div className="relative h-32 rounded-t-lg bg-teal-25">
        {handleRequiredTag()}
        {handleOneOffTag()}
        {handleComingSoonTag()}
        <img
          src={courseData.image_url}
          alt="illustrative image related to course title and content"
          style={{
            height: "128px",
            width: "100%",
            objectFit: "cover",
            borderRadius: "8px 8px 0px 0px",
          }}
        />
      </div>
    );
  };

  const handleCardUpperSection = () => {
    if (courseData.coming_soon || courseData.external) {
      return <div>{handleCardImage()}</div>;
    } else {
      return (
        <a href={`/public/courses/${courseData.slug}`}>{handleCardImage()}</a>
      );
    }
  };

  const handleCardMiddleSection = () => {
    if (!courseData.coming_soon) {
      return (
        <div className="h-16">
          <div className="mb-5 flex justify-between text-base font-light text-gray-100">
            <span>
              {courseData.chapter_count}{" "}
              {i18n.t("shared.courses.course.chapters")}
            </span>
            {courseData.duration && (
              <span>
                {courseData.duration} {i18n.t("shared.courses.course.minutes")}
              </span>
            )}
          </div>
        </div>
      );
    } else {
      return <div className="h-16"></div>;
    }
  };

  const handleEditAsAdminLink = () => {
    if (
      showAuthorAccess &&
      (courseData.unpublished || courseData.coming_soon || courseData.external)
    ) {
      return (
        <a href={`editor/courses/${courseData.slug}`}>
          <button className="rounded-md border border-gray-40 px-3 py-1 text-base font-medium text-gray-60 hover:bg-red-25">
            Edit as admin
          </button>
        </a>
      );
    } else {
      return false;
    }
  };

  const handleCtaSection = () => {
    const hoverClass =
      courseData.external && !courseData.external_content_url
        ? "cursor-default"
        : "hover:bg-teal-25";
    if (courseData.coming_soon) {
      return showAuthorAccess ? (
        <div className="mt-2 flex justify-between">
          {handleEditAsAdminLink()}
        </div>
      ) : (
        <div className="mt-2 flex justify-between">
          <div className="flex-1">
            <RegisterInterestForm
              courseData={courseData}
              currentUserEmail={currentUserEmail}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="mt-2 flex justify-between">
          {handleEditAsAdminLink()}
          <a
            className={handleEditAsAdminLink() === false ? "flex-1" : ""}
            href={handleCtaButtonLink(ctaButtonText())}
            target={
              courseData.external && !!courseData.external_content_url
                ? "_blank"
                : ""
            }
          >
            <button
              className={`w-full rounded-md border border-teal-100 px-3 py-1 text-base font-medium text-gray-100 ${hoverClass}`}
              onClick={trackEvent}
            >
              {ctaButtonText()}
            </button>
          </a>
        </div>
      );
    }
  };

  const handleCourseTitleLink = () => {
    if (courseData.coming_soon) {
      return <div>{handleCourseTitle()}</div>;
    } else {
      return (
        <a
          href={`/public/courses/${courseData.slug}`}
          style={{ color: "#1D2630" }}
        >
          {handleCourseTitle()}
        </a>
      );
    }
  };

  const academyCourse = () => {
    return (
      <div className="rounded rounded-lg bg-white">
        {handleCardUpperSection()}
        <div className="h-52 rounded-b-lg border-2 border-gray-20 px-4 pb-4 pt-3 shadow-md">
          <div className="mb-1 h-16 text-lg font-medium leading-7 text-gray-100">
            {handleCourseTitleLink()}
          </div>
          {handleCardMiddleSection()}
          {handleCtaSection()}
        </div>
      </div>
    );
  };

  const externalCourse = () => {
    return (
      <div className="rounded rounded-lg bg-white">
        {handleCardUpperSection()}
        <div className="h-52 rounded-b-lg border-2 border-gray-20 px-4 pb-4 pt-3 shadow-md">
          <div className="mb-1 h-16 text-lg font-medium leading-7 text-gray-100">
            {handleCourseTitle()}
          </div>
          <div className="h-16"></div>
          {handleCtaSection()}
        </div>
      </div>
    );
  };

  return courseData.external && !courseData.coming_soon
    ? externalCourse()
    : academyCourse();
};

CourseCard.propTypes = {
  courseData: PropTypes.object.isRequired,
  showAuthorAccess: PropTypes.bool,
  currentUserEmail: PropTypes.string,
};

export default CourseCard;
