import React, { useState } from "react";
import TrainingTable from "./TrainingTable";
import TrainingFilterButton from "./TrainingFilterButton";

interface Content {
  card_image_url: string;
  expires_at: string;
  link_path: string;
  passed: boolean;
  percentage_complete: number;
  slug: string;
  title: string;
  external: boolean;
  external_content_url: string;
  is_bundle: boolean;
  is_required_training: boolean;
  due_on: string;
  due_status: string;
  latest_completed_at: string;
  one_off: boolean;
  program_course_position: string;
  certificate_present: boolean;
  attempt_completed: boolean;
  status: string;
  public_learner: boolean;
}

interface Course {
  id: string;
  title: string;
  content: Content[];
}

interface MyLearningProps {
  courses: Course[];
}

const MyLearning: React.FC<MyLearningProps> = ({ courses }) => {
  const calculateInitialFilter = () => {
    return courses[0].content.length == 0 ||
      courses[0].content[0].public_learner
      ? "individual-courses"
      : "required-trainings";
  };

  const [selectedFilter, setSelectedFilter] = useState<string | null>(
    calculateInitialFilter()
  );

  const handleFilterSelection = (id: string) => {
    setSelectedFilter(id);
  };

  const selectedCourse = courses.find((course) => course.id === selectedFilter);

  const isSelectedTrainingEmpty = () => {
    return selectedCourse.content.length == 0;
  };

  return (
    <>
      <div
        className="sticky top-14
       flex md:top-0 md:flex-wrap"
      >
        {courses.map((item) => (
          <TrainingFilterButton
            key={item.id}
            title={item.title}
            isSelected={item.id === selectedFilter}
            onFilterSelect={() => handleFilterSelection(item.id)}
          />
        ))}
      </div>
      {selectedFilter == "required-training" && isSelectedTrainingEmpty() && (
        <div className="mt-8 bg-gray-25 p-6 text-center">
          {i18n.t("shared.my_learning.required_trainings_empty_state")}
        </div>
      )}
      {selectedFilter == "individual-courses" && isSelectedTrainingEmpty() && (
        <div className="mt-8 bg-gray-25 p-6 text-center">
          {i18n.t("shared.my_learning.individual_courses_empty_state")}
        </div>
      )}
      {selectedCourse && !isSelectedTrainingEmpty() && (
        <TrainingTable training={selectedCourse.content} />
      )}
    </>
  );
};

export default MyLearning;
