import React from "react";
import classNames from "classnames";
import CheckoutButton from "../../Shared/CheckoutButton";

interface Certificate {
  certified_at: string;
  cta_copy: string;
  cta_url: string;
  expired: boolean;
  expired_at: string;
  image_url?: string;
  part_of_bundle: boolean;
  requires_payment: boolean;
  slug: string;
  title: string;
  course_slug?: string;
}

interface CertificatesProps {
  certificates: Certificate[];
  showLinkToPreviousCertificates?: boolean;
}

const Certificates: React.FC<CertificatesProps> = ({
  certificates,
  showLinkToPreviousCertificates,
}) => {
  const ctaClassNames = (certificate: Certificate) => {
    return classNames(
      `w-full rounded-md rounded py-2 text-sm font-medium ${
        certificate.requires_payment
          ? "btn btn-secondary px-2 min-h-40"
          : "border border-teal-100 px-3 text-gray-100 hover:bg-teal-25"
      }`
    );
  };

  const renderCertificateCardImage = (certificate: Certificate) => {
    return (
      <img
        src={certificate.image_url}
        alt="illustrative image related to course title and content"
        className="h-32 w-full rounded-lg rounded-b-none object-cover md:h-full md:rounded-b-lg"
      />
    );
  };

  const renderCertificateStatus = (certificate: Certificate) => {
    if (certificate.requires_payment) {
      return <div className="mt-8 md:mt-0"></div>;
    } else {
      return (
        <p className="mt-8 text-xs font-light tracking-widest md:mt-1">
          {certificate.expired
            ? i18n.t("learner.portfolio.certificates.expired_on")
            : i18n.t("learner.portfolio.certificates.certified_on")}{" "}
          {certificate.expired
            ? certificate.expired_at
            : certificate.certified_at}
        </p>
      );
    }
  };

  const renderCta = (certificate: Certificate) => {
    if (certificate.requires_payment) {
      return (
        <CheckoutButton
          checkoutType="certificate"
          checkoutSlug={certificate.slug}
          ctaCopy={certificate.cta_copy}
          originPage="portfolio"
          containerClassNames="w-full"
          classNames={ctaClassNames(certificate)}
        />
      );
    } else {
      return (
        <a className="w-full" href={certificate.cta_url}>
          <button
            className={ctaClassNames(certificate)}
            // onClick={trackEvent}
          >
            {certificate.cta_copy}
          </button>
        </a>
      );
    }
  };

  return (
    <>
      {certificates.map((certificate: Certificate) => {
        return (
          <div key={certificate.slug} className="py-6 md:py-2">
            <div className="flex h-fit flex-wrap items-center rounded-lg rounded-b-lg border-2 border-gray-20 bg-white p-0 shadow-lg md:p-4">
              <div className="relative flex w-full flex-wrap items-center md:w-8/12 md:flex-nowrap">
                <div className="relative h-full w-full md:mr-6 md:h-14 md:w-14 md:min-w-14">
                  {renderCertificateCardImage(certificate)}
                </div>
                <div className="mx-auto mb-0 justify-center pt-9 text-center md:mx-0 md:pt-0 md:text-left">
                  <p className="text-lg font-medium leading-tight">
                    {certificate.title}
                  </p>
                  {renderCertificateStatus(certificate)}
                </div>
              </div>
              <div className=" relative flex w-full flex-col justify-center gap-4 px-4 pb-5 pt-9 text-center md:w-4/12  md:px-5 md:pb-0 md:pt-0">
                {renderCta(certificate)}
                {showLinkToPreviousCertificates && certificate.course_slug && (
                  <a
                    href={`/learners/previous_certificates/${certificate.course_slug}`}
                    className="text-sm font-normal text-blue-8"
                  >
                    {i18n.t(
                      "learner.portfolio.certificates.previous_certificates_link"
                    )}
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Certificates;
