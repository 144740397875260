import { startCase, replace } from "lodash";
import React, { useState } from "react";
import classNames from "classnames";
import axios from "axios";

import FormModal from "../Shared/FormModal";
import ToggleSwitch from "../Shared/ToggleSwitch";
import JobPreferenceForm from "./JobPreferenceForm";

export interface JobPreference {
  id: number;
  uk_resident: boolean;
  sponsorship_required: boolean;
  post_code: string;
  role_type: string;
  accommodation_required: boolean;
  weekly_shift_schedule: string;
  daily_shift_schedule: string;
  full_time: boolean;
  dbs_up_to_date: boolean;
  vaccinations_up_to_date: boolean;
  valid_uk_driving_license: boolean;
}

interface OpenToWorkToggleProps {
  jobPreference: JobPreference;
  currentUserId: number;
  isOpenToWork: boolean;
  dailyScheduleOptions: string[];
  weeklyScheduleOptions: string[];
  roleTypeOptions: string[];
  hasAdequatePortfolioContent: boolean;
}

const OpenToWorkToggle: React.FC<OpenToWorkToggleProps> = ({
  jobPreference,
  currentUserId,
  isOpenToWork,
  dailyScheduleOptions,
  weeklyScheduleOptions,
  roleTypeOptions,
  hasAdequatePortfolioContent,
}) => {
  const [openToWorkStatus, setOpenToWorkStatus] = useState(
    isOpenToWork && !!jobPreference
  );
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModalVisible, setModalVisibility] = useState(false);

  const renderModal = () => {
    return (
      <FormModal
        isVisible={isModalVisible}
        title={i18n.t("shared.portfolio.open_to_work_modal_header")}
        onClose={() => {
          setModalVisibility(false);
          setOpenToWorkStatus(isOpenToWork && !!jobPreference);
        }}
      >
        <JobPreferenceForm
          jobPreference={jobPreference}
          userId={currentUserId}
          dailyScheduleOptions={dailyScheduleOptions}
          weeklyScheduleOptions={weeklyScheduleOptions}
          roleTypeOptions={roleTypeOptions}
          hasAdequatePortfolioContent={hasAdequatePortfolioContent}
        />
      </FormModal>
    );
  };

  const showModal = (isOpenToWork) => {
    if (isOpenToWork && !jobPreference) {
      setModalVisibility(true);
    }
  };

  const updateUserOpenToWorkAttribute = (isOpenToWork) => {
    const token = document.querySelector(
      "[name=csrf-token]"
    ) as HTMLMetaElement;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

    axios
      .patch(`/api/v0/users/${currentUserId}`, {
        user: { open_to_work: isOpenToWork },
      })
      .then((response) => {
        if (response.status === 200) {
          setOpenToWorkStatus(isOpenToWork);
        } else {
          setErrorMessage("Oops, something went wrong. Please try again.");
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const displayErrorMessage = () => {
    if (!!errorMessage) {
      return (
        <div className="text-sm text-gray-70 text-red-150">{errorMessage}</div>
      );
    }
  };

  const parsedBooleanValue = (value) => {
    return value ? "Yes" : "No";
  };

  return (
    <>
      <div
        className={classNames("rounded border p-3", {
          ["border-teal-25"]: openToWorkStatus === true,
          ["border-yellow-25"]: openToWorkStatus === false,
        })}
      >
        <ToggleSwitch
          isToggleChecked={openToWorkStatus}
          updateToggleState={(checked) => {
            updateUserOpenToWorkAttribute(checked);
            showModal(checked);
          }}
          toggleLabel={i18n.t("shared.portfolio.open_to_work")}
        />
      </div>
      {!!openToWorkStatus && !!jobPreference && (
        <div className="mt-4">
          <div className="mb-6 flex items-center justify-between">
            <p className="text-lg">Job preferences</p>
            <a
              className="cursor-pointer text-sm font-light text-blue-100 hover:underline"
              onClick={() => {
                setModalVisibility(true);
              }}
            >
              {i18n.t("shared.edit")}
            </a>
          </div>
          <div className="text-xs">
            <p className="mb-2.5">
              Full/Part time:{" "}
              <span className="font-light">
                {jobPreference.full_time ? "Full time" : "Part time"}
              </span>
            </p>
            <p className="mb-2.5">
              UK resident:{" "}
              <span className="font-light">
                {parsedBooleanValue(jobPreference.uk_resident)}
              </span>
            </p>
            <p className="mb-2.5">
              Sponsorship required:{" "}
              <span className="font-light">
                {parsedBooleanValue(jobPreference.sponsorship_required)}
              </span>
            </p>
            {!!jobPreference.post_code && (
              <p className="mb-2.5">
                Postcode:{" "}
                <span className="font-light">{jobPreference.post_code}</span>
              </p>
            )}
            <p className="mb-2.5">
              Daily schedule:{" "}
              <span className="font-light">
                {startCase(
                  replace(jobPreference.daily_shift_schedule, /_/g, " ")
                )}
              </span>
            </p>
            <p className="mb-2.5">
              Weekly schedule:{" "}
              <span className="font-light">
                {startCase(
                  replace(jobPreference.weekly_shift_schedule, /_/g, " ")
                )}
              </span>
            </p>
            <p className="mb-2.5">
              Role type:{" "}
              <span className="font-light">
                {startCase(replace(jobPreference.role_type, /_/g, " "))}
              </span>
            </p>
            <p className="mb-2.5">
              Vaccinations up to date:{" "}
              <span className="font-light">
                {parsedBooleanValue(jobPreference.vaccinations_up_to_date)}
              </span>
            </p>
            <p className="mb-2.5">
              Valid UK driving license:{" "}
              <span className="font-light">
                {parsedBooleanValue(jobPreference.valid_uk_driving_license)}
              </span>
            </p>
            <p className="mb-2.5">
              Accomodation required:{" "}
              <span className="font-light">
                {parsedBooleanValue(jobPreference.accommodation_required)}
              </span>
            </p>
          </div>
        </div>
      )}
      {displayErrorMessage()}
      {renderModal()}
    </>
  );
};

export default OpenToWorkToggle;
