document.addEventListener("turbolinks:load", function () {
  const modal = document.getElementById("courseNavModal");
  const openButton = document.getElementById("openNavMenuButton");
  const closeButton = document.getElementById("closeNavMenuButton");
  const e = document.getElementById("speak-info");

  function openModal() {
    modal.classList.remove("hidden");
    e.classList.add("hidden");
  }

  function closeModal() {
    modal.classList.add("hidden");
    e.classList.remove("hidden");
  }

  if (openButton) {
    openButton.addEventListener("click", openModal);
  }

  if (closeButton) {
    closeButton.addEventListener("click", closeModal);
  }

  if (modal) {
    // Close the modal when clicking anywhere outside the content area
    modal.addEventListener("click", function (event) {
      if (event.target === modal) {
        closeModal();
      }
    });
  }
});
