import React, { useState, useEffect } from "react";
import classNames from "classnames";

interface ToggleSwitchProps {
  isToggleChecked: boolean;
  updateToggleState: (checked) => void;
  toggleLabel?: string;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  isToggleChecked,
  updateToggleState,
  toggleLabel,
}) => {
  return (
    <>
      <div>
        <div className="flex items-center">
          <label>
            <input
              id="toggle"
              className="hidden"
              type="checkbox"
              checked={isToggleChecked}
              onChange={(event) => updateToggleState(event.target.checked)}
            />
            <div
              className={classNames(
                "relative inline-block h-7 w-12 cursor-pointer rounded-full p-1 transition duration-200 ease-in-out",
                {
                  ["bg-teal-100"]: isToggleChecked === true,
                  ["bg-gray-30"]: isToggleChecked === false,
                }
              )}
            >
              <div
                className={classNames(
                  "absolute h-5 w-5 rounded-full bg-white",
                  {
                    ["left-6"]: isToggleChecked === true,
                  }
                )}
              ></div>
            </div>
          </label>
          {!!toggleLabel && (
            <div className="mb-1 ml-4 text-xs font-light">{toggleLabel}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default ToggleSwitch;
