import ReactRailsUJS from "react_ujs";
import "./translations";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as comps from "../components/**/*";

const { default: allComponents, filenames } = comps;

const mappedComponents = {};

filenames.map((filename, i) => {
  const key = mapFilePathToComponentKey(filename);
  mappedComponents[key] = allComponents[i].default;
});

ReactRailsUJS.getConstructor = function (component) {
  return mappedComponents[component];
};

document.addEventListener("turbolinks:load", function () {
  ReactRailsUJS.mountComponents();
});

/**
 * esbuild-plugin-import-glob returns two lists all of the components and all of
 * the filenames we need to convert the filename to the right key for
 * react-rails. Basically we need to go from:
 *
 * ./components/Ui/AndroidNativeAppBanner/index.tsx -> Ui/AndroidNativeAppBanner
 */
function mapFilePathToComponentKey(filePath: string): string {
  return filePath
    .replace("../components/", "")
    .replace(".tsx", "")
    .replace(".ts", "")
    .replace(".jsx", "")
    .replace(".js", "")
    .replace("/index", "");
}
