import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["banner"];

  connect() {
    const hidden = localStorage.getItem("hideBanner");
    if (hidden == "true") {
      this.bannerTarget.style.display = "none";
    }
  }

  dismiss() {
    localStorage.setItem("hideBanner", true);
    this.bannerTarget.style.display = "none";
  }
}
