import React, { useState } from "react";
import axios from "axios";
import { userDevice } from "helpers/tracking";

const RegisterInterestForm = ({ courseData, currentUserEmail }) => {
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(
    courseData.existing_course_interest
  );
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFormSubmission = (event) => {
    event.preventDefault();

    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios
      .post("/course_interests", {
        course_interest: { course_id: event.target[0].value },
      })
      .then((response) => {
        if (response.data.message === "course_interest_created") {
          setIsSubmitButtonDisabled(true);
        } else if (response.data.message === "course_interest_not_created") {
          setErrorMessage("Oops, something went wrong. Please try again.");
        } else if (
          response.data.message === "course_interest_already_registered"
        ) {
          setErrorMessage(
            "You've already registered your interest in this course"
          );
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const displayErrorMessage = () => {
    if (!!errorMessage) {
      return (
        <div className="absolute bottom-20 text-sm text-gray-70">
          {errorMessage}
        </div>
      );
    }
  };

  const trackEvent = (event) => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      "academy_coming_soon_course_clicked",
      {
        user_email: !!currentUserEmail ? currentUserEmail : "logged_out_user",
        course: courseData.title,
        course_id: courseData.id,
        user_device: userDevice(),
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  const disabledClasses = "cursor-default border-gray-30";
  const enabledClasses = "border-teal-100 hover:bg-teal-25";

  return (
    <form
      onSubmit={() => {
        handleFormSubmission, trackEvent();
      }}
    >
      <input
        value={courseData.id}
        type="hidden"
        name="course_interest[course_id]"
      ></input>
      {displayErrorMessage()}
      <button
        type="submit"
        className={
          isSubmitButtonDisabled
            ? `w-full rounded-md border py-1 px-3 text-base font-medium text-gray-100 ${disabledClasses}`
            : `w-full rounded-md border py-1 px-3 text-base font-medium text-gray-100 ${enabledClasses}`
        }
        disabled={isSubmitButtonDisabled}
      >
        {isSubmitButtonDisabled
          ? i18n.t("shared.courses.course.interest_registered")
          : i18n.t("shared.courses.course.register_interest_cta")}
      </button>
    </form>
  );
};

export default RegisterInterestForm;
