import { Application } from "stimulus";

import BannerController from "./banner_controller";
import CheckboxController from "./checkbox_controller";
import FormsController from "./forms_controller";
import HovercardController from "./hovercard_controller";
import NavController from "./nav_controller";
import RevealerController from "./revealer_controller";
import ToggleController from "./toggle_controller";

window.Stimulus = Application.start();
Stimulus.register("banner", BannerController);
Stimulus.register("checkbox", CheckboxController);
Stimulus.register("forms", FormsController);
Stimulus.register("hovercard", HovercardController);
Stimulus.register("nav", NavController);
Stimulus.register("revealer", RevealerController);
Stimulus.register("toggle", ToggleController);
