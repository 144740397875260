import React from "react";
import Modal from "react-modal";
import "../styles.scss";

import {
  Linkedin,
  Facebook,
  Twitter,
  Whatsapp,
  Close,
} from "components/Shared/Icons";
import { isMobileOnly } from "react-device-detect";
import { userDevice } from "helpers/tracking";

const Share = ({ certificate_slug }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const trackEvent = (social_media) => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      "academy_user_social_share_click",
      { platform: social_media, user_device: userDevice() },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      width: isMobileOnly ? "90%" : "35rem",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      borderRadius: "0.5rem",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },
  };

  const renderLinkedInShareButton = () => {
    return (
      <div className="flex cursor-pointer justify-center">
        <a
          href={
            "https://www.linkedin.com/shareArticle?mini=true&url=https://academy.florence.co.uk/public/certificates/" +
            certificate_slug +
            "?utm_source%3Dlinkedin%26utm_campaign%3Dsocial_sharing%26utm_medium%3Dsocial"
          }
          onClick={() => {
            trackEvent("linkedin");
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="mb-2 flex h-24 w-24 items-center justify-center rounded bg-teal-25">
            {<Linkedin />}
          </span>
          <span className="flex justify-center text-sm font-light">
            LinkedIn
          </span>
        </a>
      </div>
    );
  };

  const renderFacebookShareButton = () => {
    return (
      <div className="flex cursor-pointer justify-center">
        <a
          href={
            "https://www.facebook.com/sharer/sharer.php?u=https://academy.florence.co.uk/public/certificates/" +
            certificate_slug +
            "?utm_source%3Dfacebook%26utm_campaign%3Dsocial_sharing%26utm_medium%3Dsocial"
          }
          onClick={() => {
            trackEvent("facebook");
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="mb-2 flex h-24 w-24 items-center justify-center rounded bg-teal-25">
            {<Facebook />}
          </span>
          <span className="flex justify-center text-sm font-light">
            Facebook
          </span>
        </a>
      </div>
    );
  };

  const renderTwitterShareButton = () => {
    return (
      <div className="flex cursor-pointer justify-center">
        <a
          href={
            "https://twitter.com/intent/tweet?text=%F0%9F%8E%93%C2%A0I%20just%20completed%20a%20course%20with%20Florence%20Academy!%20%40WeAreFlorence%20%23Florence.%20Check%20it%20out%20%E2%9E%A1%EF%B8%8F%20https%3A//academy.florence.co.uk/public/certificates/" +
            certificate_slug +
            "?utm_source%3Dtwitter%26utm_campaign%3Dsocial_sharing%26utm_medium%3Dsocial"
          }
          onClick={() => {
            trackEvent("twitter");
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="mb-2 flex h-24 w-24 items-center justify-center rounded bg-teal-25">
            {<Twitter />}
          </span>
          <span className="flex justify-center text-sm font-light">
            Twitter
          </span>
        </a>
      </div>
    );
  };

  const renderWhatsappShareButton = () => {
    return (
      <div className="flex cursor-pointer justify-center">
        <a
          href={
            "whatsapp://send?text=🎓 I just completed a course with Florence Academy! Check it out ➡️ https://academy.florence.co.uk/public/certificates/" +
            certificate_slug +
            "?utm_source%3Dwhatsapp%26utm_campaign%3Dsocial_sharing%26utm_medium%3Dsocial"
          }
          onClick={() => {
            trackEvent("whatsapp");
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="mb-2 flex h-24 w-24 items-center justify-center rounded bg-teal-25">
            {<Whatsapp />}
          </span>
          <span className="flex justify-center text-sm font-light">
            WhatsApp
          </span>
        </a>
      </div>
    );
  };

  return (
    <div>
      <a className="btn btn-primary block" onClick={openModal}>
        {i18n.t("shared.certificates.index.share")}
      </a>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Social Share Modal"
        closeTimeoutMS={500}
        ariaHideApp={false}
      >
        <div className="relative">
          <div
            className={`border-b-1 border-color flex h-20 items-center justify-center bg-teal-25 text-2xl`}
          >
            {i18n.t("shared.certificates.index.share_title")}
            <span
              onClick={() => closeModal()}
              className={`absolute ${
                isMobileOnly ? "right-1 top-1 h-8 w-8" : "right-5 h-12 w-12"
              } flex cursor-pointer items-center justify-center rounded`}
            >
              <Close />
            </span>
          </div>
          <div className="px-8 pt-6 text-center text-sm font-light">
            {i18n.t("shared.certificates.index.learner_of_the_month")}
          </div>
        </div>
        <div
          className={`relative grid ${
            isMobileOnly
              ? "mx-2 h-1/2 grid-cols-2 grid-rows-2 gap-y-4"
              : "h-25 mx-8 grid-cols-3 grid-rows-1"
          } my-8 items-center justify-between`}
        >
          {renderLinkedInShareButton()}
          {renderFacebookShareButton()}
          {renderTwitterShareButton()}
          {isMobileOnly && renderWhatsappShareButton()}
        </div>
      </Modal>
    </div>
  );
};

export default Share;
