import React, {useState} from 'react'
import FormModal from '../../Portfolio/Shared/FormModal'

interface CertificateAlertProps {
  show: boolean
}

const CertificateAlert: React.FC<CertificateAlertProps> = ({show}) => {
  const [isModalVisible, setModalVisibility] = useState<boolean>(show)

  return (
    <>
      <FormModal
        isVisible={isModalVisible}
        title={'New certificate purchase'}
        onClose={() => setModalVisibility(false)}
      >
        <div>
          <p className="text-base font-light text-gray-100">
            {i18n.t("shared.certificates.payment_alert.modal_text")}
          </p>
        </div>
        <button
          className="btn-primary btn-flex btn mt-4 w-full"
          onClick={() => setModalVisibility(false)}
        >
          I understand
        </button>
      </FormModal>
    </>
  )
}

export default CertificateAlert
