{
  "en": {
    "activerecord": {
      "errors": {
        "messages": {
          "record_invalid": "Validation failed: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Cannot delete record because dependent %{record} exist",
            "has_one": "Cannot delete record because a dependent %{record} exists"
          }
        }
      }
    },
    "administrate": {
      "actions": {
        "back": "Back",
        "confirm": "Are you sure?",
        "destroy": "Destroy",
        "edit": "Edit",
        "edit_resource": "Edit %{name}",
        "new_resource": "New %{name}",
        "show_resource": "Show %{name}"
      },
      "controller": {
        "create": {
          "success": "%{resource} was successfully created."
        },
        "destroy": {
          "success": "%{resource} was successfully destroyed."
        },
        "update": {
          "success": "%{resource} was successfully updated."
        }
      },
      "fields": {
        "active_storage": {
          "add": "Add",
          "attachment": "Attachment",
          "no_attachment": "No attachment",
          "remove": "Remove",
          "replace": "Replace"
        },
        "has_many": {
          "more": "Showing %{count} of %{total_count}",
          "none": "None"
        }
      },
      "form": {
        "error": "error",
        "errors": "%{pluralized_errors} prohibited this %{resource_name} from being saved:"
      },
      "navigation": {
        "back_to_app": "Back to app"
      },
      "search": {
        "clear": "Clear search",
        "label": "Search %{resource}"
      }
    },
    "client": {
      "dashboard": {
        "overview_banners": {
          "care_certificates": "staff with Care Certificate awaiting approval",
          "expired_training": "staff with overdue training",
          "new_certificates": "new certificates last week"
        },
        "staff_overview_header": "Here is an overview of your staff's training.",
        "stat_cards": {
          "active_staff_card": {
            "caption": "paused out of",
            "label": "Staff members",
            "primary_cta": "Manage staff",
            "secondary_cta": "Manage roles",
            "title": "Active staff"
          },
          "care_certificate_card": {
            "caption": "awarded out of",
            "label": "In progress",
            "primary_cta": "Care Certificate Progress",
            "title": "Care Certificate"
          },
          "company_documents_card": {
            "caption": "read out of",
            "label": "Assigned to staff",
            "primary_cta": "Download report",
            "secondary_cta": "Manage documents",
            "title": "Internal documents"
          },
          "job_posting": {
            "caption": "active out of",
            "extra_cta": "Company info",
            "label": "Jobs posted",
            "primary_cta": "Post new",
            "secondary_cta": "View all",
            "title": "Academy match job posting"
          },
          "mandatory_training_card": {
            "caption": "fully compliant out of",
            "extra_cta": "Locations",
            "label": "Average compliance",
            "primary_cta": "Matrix",
            "secondary_cta": "Training",
            "title": "Mandatory training"
          }
        },
        "welcome_back": "Welcome back"
      },
      "jobs": {
        "index": {
          "empty_state": "No jobs posted",
          "hired_job_listing_title": "Hired job listings",
          "inactive_job_listing_title": "Inactive job listings",
          "job_listing_title": "Current job listings",
          "subheader": "listings",
          "view_job_button": "View"
        }
      },
      "matrix": {
        "api_error": "Whoops something went wrong, please try refreshing the page.",
        "care_certificate": {
          "awarded": "Awarded %{date}",
          "in_progress": "In progress",
          "not_started": "Not started"
        },
        "download": {
          "please_wait": "Please wait...",
          "request": "Send to email"
        },
        "download_failed": {
          "message": "Please wait 5 minutes and try again.",
          "status": "Download failed..."
        },
        "download_success": {
          "message": "You will receive the file by email shortly.",
          "status": "Generating matrix..."
        },
        "empty_state_title": "No information to display for this filter combination",
        "filter": {
          "courses_subtitle": "COURSES",
          "locations_subtitle": "LOCATIONS",
          "statuses_subtitle": "STATUSES"
        },
        "filter_controls": {
          "apply_cta": "Apply filters",
          "open_cta": "Matrix filters",
          "reset_cta": "Reset"
        },
        "page_header": "Staff compliance details",
        "tables": {
          "courses": {
            "care_certificate_header": "Care certificate",
            "elearning_header": "eLearning",
            "learner_compliance_header": "Learner compliance"
          },
          "name_header": "Name"
        }
      },
      "paused": {
        "show": {
          "contact": "Please contact your account manager or email",
          "header": "This account has been paused."
        }
      },
      "shared": {
        "all_locations": "All locations",
        "permissions": {
          "director": "Director",
          "manager": "Manager"
        }
      },
      "training": {
        "export_all": "Export all rows",
        "export_selected": "Export selected rows",
        "mark_as_complete": "Mark as complete"
      },
      "users": {
        "index": {
          "empty_state": "No staff in this category",
          "new_resource_cta": "Add staff",
          "page_header": "Staff list",
          "statuses": {
            "active": "Active",
            "all": "All",
            "emails_bouncing": "Emails are bouncing",
            "invitation_sent": "Invitation sent",
            "paused": "Paused"
          },
          "table_header_five": "Compliance",
          "table_header_four": "Last login",
          "table_header_one": "Staff name",
          "table_header_three": "Status",
          "table_header_two": "Role"
        },
        "new": {
          "billing_annual_charge_per_seat_copy": "Please note, when this user accepts the invitation their annual charge will be added to your next bill.",
          "form_input_eight": "Status",
          "form_input_eight_hint": "Paused staff will be excluded from stats and email reminders.",
          "form_input_five": "Role",
          "form_input_four": "Last name",
          "form_input_four_hint": "Changes to the user's name will be applied on the platform. However, if the user needs to change how their name appears on their training certificates, please contact <a href='mailto:academy@florence.co.uk', style='color: #00818F;text-decoration: underline;'>Florence Academy</a>.",
          "form_input_four_placeholder": "e.g. Doe",
          "form_input_manager_inputs": {
            "form_input_one": "Manager at",
            "form_input_one_hint": "Please select the locations this manager can access. Hold Ctrl or Cmd to select multiple",
            "form_input_two": "Weekly digest email",
            "form_input_two_hint": "Communication"
          },
          "form_input_nine": "Home location",
          "form_input_nine_blank_option": "No location",
          "form_input_one": "Email",
          "form_input_one_hint": "For security reasons, you cannot change this user's email from this page. If you wish to change the email, please contact <a href='mailto:academy@florence.co.uk', style='color: #00818F;text-decoration: underline;'>Florence Academy</a>.",
          "form_input_one_placeholder": "jane@example.com",
          "form_input_seven": "Start date",
          "form_input_six": "Extra info (optional)",
          "form_input_three": "First name",
          "form_input_three_placeholder": "e.g. Jane",
          "form_input_two": "Permission",
          "form_input_two_blank_option": "Please select an option",
          "form_input_two_hint_not_persisted": "Managers can manage staff at their locations, assign and approve training",
          "form_input_two_hint_persisted": "Please contact <a href='mailto:academy@florence.co.uk', style='color: #00818F;text-decoration: underline;'>Florence Academy</a> to update this user's permission.",
          "form_input_two_options": {
            "director": "Director",
            "learner": "Learner",
            "manager": "Manager"
          },
          "page_header": "Add a staff member",
          "remove_cta": "Remove from company",
          "remove_cta_confirm": "Are you sure?",
          "save_cta": "Save changes"
        }
      }
    },
    "currency": "£",
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%d %B, %Y",
        "long": "%B %d, %Y",
        "month_and_year": "%B, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about 1 hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about 1 month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about 1 year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost 1 year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than 1 second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over 1 year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "1 day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "1 month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "1 second",
          "other": "%{count} seconds"
        }
      },
      "dotiw": {
        "days": {
          "one": "1 day",
          "other": "%{count} days"
        },
        "hours": {
          "one": "1 hour",
          "other": "%{count} hours"
        },
        "less_than_x": "less than %{distance}",
        "minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "months": {
          "one": "1 month",
          "other": "%{count} months"
        },
        "seconds": {
          "one": "1 second",
          "other": "%{count} seconds"
        },
        "weeks": {
          "one": "1 week",
          "other": "%{count} weeks"
        },
        "years": {
          "one": "1 year",
          "other": "%{count} years"
        }
      },
      "dotiw_compact": {
        "about_x_years": {
          "any": "~%{count}y",
          "one": "~1y"
        },
        "almost_x_years": {
          "any": "~%{count}y",
          "one": "~1y"
        },
        "days": {
          "one": "1d",
          "other": "%{count}d"
        },
        "hours": {
          "one": "1h",
          "other": "%{count}h"
        },
        "last_word_connector": "",
        "less_than_x": "<%{distance}",
        "minutes": {
          "one": "1m",
          "other": "%{count}m"
        },
        "months": {
          "one": "1mo",
          "other": "%{count}mo"
        },
        "over_x_years": {
          "one": ">1y",
          "other": ">%{count}y"
        },
        "seconds": {
          "one": "1s",
          "other": "%{count}s"
        },
        "two_words_connector": "",
        "weeks": {
          "one": "1w",
          "other": "%{count}w"
        },
        "words_connector": "",
        "years": {
          "one": "1y",
          "other": "%{count}y"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Seconds",
        "year": "Year"
      }
    },
    "devise": {
      "confirmations": {
        "confirmed": "Your email address has been successfully confirmed.",
        "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
      },
      "failure": {
        "already_authenticated": "You are already signed in.",
        "inactive": "Your account is not activated yet.",
        "invalid": "Invalid %{authentication_keys} or password.",
        "last_attempt": "You have one more attempt before your account is locked.",
        "locked": "Your account is locked.",
        "not_found_in_database": "Invalid %{authentication_keys} or password.",
        "timeout": "Your session expired. Please sign in again to continue.",
        "unauthenticated": "You need to sign in or sign up before continuing.",
        "unconfirmed": "You have to confirm your email address before continuing."
      },
      "mailer": {
        "confirmation_instructions": {
          "subject": "Confirmation instructions"
        },
        "email_changed": {
          "subject": "Email Changed"
        },
        "password_change": {
          "subject": "Password Changed"
        },
        "reset_password_instructions": {
          "subject": "Reset password instructions"
        },
        "unlock_instructions": {
          "subject": "Unlock instructions"
        }
      },
      "omniauth_callbacks": {
        "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
        "success": "Successfully authenticated from %{kind} account."
      },
      "passwords": {
        "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
        "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
        "updated": "Your password has been changed successfully. You are now signed in.",
        "updated_not_active": "Your password has been changed successfully."
      },
      "registrations": {
        "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
        "signed_up": "Welcome! You have signed up successfully.",
        "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
        "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
        "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
        "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirm link to confirm your new email address.",
        "updated": "Your account has been updated successfully.",
        "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
      },
      "sessions": {
        "already_signed_out": "Signed out successfully.",
        "signed_in": "Signed in successfully.",
        "signed_out": "Signed out successfully."
      },
      "unlocks": {
        "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
        "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
        "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
      }
    },
    "errors": {
      "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "must be accepted",
        "already_confirmed": "was already confirmed, please try signing in",
        "blank": "can't be blank",
        "confirmation": "doesn't match %{attribute}",
        "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
        "empty": "can't be empty",
        "equal_to": "must be equal to %{count}",
        "even": "must be even",
        "exclusion": "is reserved",
        "expired": "has expired, please request a new one",
        "greater_than": "must be greater than %{count}",
        "greater_than_or_equal_to": "must be greater than or equal to %{count}",
        "inclusion": "is not included in the list",
        "invalid": "is invalid",
        "less_than": "must be less than %{count}",
        "less_than_or_equal_to": "must be less than or equal to %{count}",
        "model_invalid": "Validation failed: %{errors}",
        "not_a_number": "is not a number",
        "not_an_integer": "must be an integer",
        "not_found": "not found",
        "not_locked": "was not locked",
        "not_saved": {
          "one": "1 error prohibited this %{resource} from being saved:",
          "other": "%{count} errors prohibited this %{resource} from being saved:"
        },
        "odd": "must be odd",
        "other_than": "must be other than %{count}",
        "present": "must be blank",
        "required": "must exist",
        "taken": "has already been taken",
        "too_long": {
          "one": "is too long (maximum is 1 character)",
          "other": "is too long (maximum is %{count} characters)"
        },
        "too_short": {
          "one": "is too short (minimum is 1 character)",
          "other": "is too short (minimum is %{count} characters)"
        },
        "wrong_length": {
          "one": "is the wrong length (should be 1 character)",
          "other": "is the wrong length (should be %{count} characters)"
        }
      },
      "unacceptable_request": "A supported version is expected in the Accept header.\n",
      "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
    },
    "flash": {
      "actions": {
        "create": {
          "notice": "%{resource_name} was successfully created."
        },
        "destroy": {
          "alert": "%{resource_name} could not be destroyed.",
          "notice": "%{resource_name} was successfully destroyed."
        },
        "update": {
          "notice": "%{resource_name} was successfully updated."
        }
      }
    },
    "helpers": {
      "label": {
        "certificate_payment": {
          "certificate_latest_downloaded_at": "Certificate downloaded at",
          "formatted_amount": "Amount"
        },
        "company": {
          "billing_user_cost": "Cost per user in pence",
          "hubspot_id": "Hubspot ID"
        },
        "course_certificate_charge": {
          "formatted_amount": "Amount",
          "formatted_vatable_amount": "VAT-able amount"
        },
        "course_charge": {
          "formatted_amount": "Amount",
          "formatted_vatable_amount": "VAT-able amount"
        },
        "course_payment": {
          "formatted_amount": "Amount"
        },
        "event_payment": {
          "formatted_amount": "Amount"
        },
        "payment": {
          "formatted_amount": "Amount",
          "formatted_type": "Type"
        }
      },
      "page_entries_info": {
        "entry": {
          "one": "entry",
          "other": "entries",
          "zero": "entries"
        },
        "more_pages": {
          "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
        },
        "one_page": {
          "display_entries": {
            "one": "Displaying <b>1</b> %{entry_name}",
            "other": "Displaying <b>all %{count}</b> %{entry_name}",
            "zero": "No %{entry_name} found"
          }
        }
      },
      "select": {
        "prompt": "Please select"
      },
      "submit": {
        "create": "Create %{model}",
        "submit": "Save %{model}",
        "update": "Update %{model}"
      }
    },
    "http_statuses": {
      "404_error": {
        "heading": "Not found",
        "message": "The page you're looking for has moved or no longer exists."
      },
      "422_error": {
        "heading": "Change rejected",
        "message": "You must have taken a wrong turn."
      },
      "500_error": {
        "heading": "Uh oh",
        "message_line_1": "Something went wrong.",
        "message_line_2": "We're looking into it and will fix it as soon as possible."
      }
    },
    "learner": {
      "events": {
        "booked": {
          "page_header": "Booked",
          "starting_in": "The event commences"
        },
        "confirmation": {
          "confirmed_result": "Confirmed",
          "email_sent_to": "An email confirmation has been sent to",
          "login_or_register": "Please create an account or sign in to confirm your booking.",
          "thank_you_for_registering_for": "Thank you for registering for",
          "unconfirmed_result": "Nearly done!",
          "view_event_cta": "View event"
        },
        "event": {
          "booked_notice": "Booked",
          "event_date": "Event date",
          "event_duration": "Event duration",
          "event_time": "Event time",
          "free_notice": "Free",
          "payment_amount": "Payment amount",
          "payment_date": "Payment date",
          "payment_reference": "Payment reference",
          "sold_out_notice": "Sold out",
          "tickets_cta": "Tickets",
          "venue_information": "Venue information",
          "view_cta": "View",
          "view_sold_out_cta": "View (sold out)"
        },
        "index": {
          "non_account_line_one": "Fit professional development",
          "non_account_line_two": "into your life.",
          "page_description": "New online events and events for nurses, carers and care home staff. Build CPD hours and own your professional development - free events available.",
          "page_title": "Online events for health & care staff | Florence Academy",
          "upcoming_header": "Upcoming events",
          "upcoming_subtitle": "All our events count towards your CPD hours for participatory learning."
        },
        "local_time": "local time",
        "show": {
          "about_subtitle": "About this event",
          "breakdown": {
            "date_header": "Date",
            "end_time_header": "End time",
            "host_header": "Host",
            "host_subheader": "This event is hosted by",
            "price_header": "Price",
            "start_time_header": "Start time"
          },
          "dates_and_times": "Dates & times",
          "example_certificate_subtitle": "Training Certificate",
          "exc_vat": "exc VAT",
          "free_event_header": "Free CPD event",
          "free_ticket_cta": "Get your free ticket",
          "length_in": "minutes",
          "multiple_dates_available": "Multiple dates & times available",
          "no_refunds": "We are unable to offer refunds for this event once a purchase has been made.",
          "or": "or",
          "provided_by": "Provided by",
          "purchase_required_first": "Buy your certificate",
          "select_date_to_attend": "Please select the date & time of the event you want to attend",
          "selected_date_and_time": "Selected date & time",
          "tickets_cta": "Tickets",
          "to_book_your_ticket": "to book your ticket",
          "training_required_cta_copy": "here.",
          "training_required_first": "Complete the training and buy your certificate",
          "training_required_header": "A valid certificate for %{course_title} is required before you can attend this practical session. If you are not in possession of a valid certificate, you may be refused entry to the event.",
          "training_required_present": "You have already completed %{course_title}. You can find the certificate in your",
          "uk_time": "(UK time)"
        },
        "sold_out": {
          "message": "is a popular and we've sold all our tickets! New events are posted all the time, so check again soon.",
          "page_header": "Sold out",
          "upcoming_events_cta": "Click here to our other upcoming events"
        },
        "too_late": {
          "message": "is closed for bookings. New events are posted all the time, so check again soon.",
          "page_header": "Closed for bookings"
        }
      },
      "home": {
        "company_documents_widget": {
          "documents_cta": "View documents"
        },
        "compliancy_widget": {
          "compliancy": "compliant",
          "empty_state": "You have no assigned training",
          "resume_training_cta": "View my learning"
        },
        "job_matchers_widget": {
          "widget_copy": "Job matches",
          "widget_sub_copy": "You've got %{number_of_matches} job %{matches}"
        },
        "open_to_work_widget": {
          "setup_portfolio_cta": "View portfolio",
          "widget_copy": "Are you open to work?",
          "widget_copy_already_open": "Are your work details up to date?",
          "widget_sub_copy": "Share your achievements with employers",
          "widget_sub_copy_already_open": "Update your achievements to share with employers"
        },
        "portfolio_widget": {
          "certificate_count": "Certificate",
          "view_portfolio_cta": "View portfolio"
        },
        "resume_training_widget": {
          "resume_training_cta": "View my learning",
          "widget_copy_not_started": "Kick off your training...",
          "widget_copy_started": "Continue where you left off...",
          "widget_sub_copy_collection_started": "You're %{percentage}% through the %{collection_title}",
          "widget_sub_copy_course_started": "You're %{percentage}% through",
          "widget_sub_copy_final_assessment": "Take your final assessment for",
          "widget_sub_copy_not_started": "Start the %{collection_title} today"
        },
        "view_courses_widget": {
          "private_widget_copy": "Explore other training opportunities",
          "public_widget_copy": "Stay up to date with your compliance training",
          "view_coruses_cta": "View courses"
        }
      },
      "jobs": {
        "show": {
          "body": "Add a short message to %{contact} saying why you’re interested in applying for this job.",
          "decline_job_button": "No, thank you",
          "email_sent": "Email sent",
          "say_youre_interested": "Say you’re interested",
          "say_youre_interested_button": "Yes, I am interested",
          "send_email": "Yes, send email"
        }
      },
      "my_purchases": {
        "date": "Date",
        "no_purchases": "You have no purchases yet.",
        "paid": "Paid",
        "price": "Price",
        "purchases": "Purchases",
        "refunded": "Refunded",
        "status": "Status",
        "type": "Type"
      },
      "portfolio": {
        "certificates": {
          "certified_on": "Certified on",
          "expired_on": "Expired on",
          "previous_certificates_link": "See previous certificates"
        }
      }
    },
    "mailers": {
      "course_feedback": {
        "congratulations_on_completing": "Congratulations on completing ",
        "congratulations_on_your_completion": "Congratulations on your completion of ",
        "hi": "Hi ",
        "start_survey": "Start survey",
        "survey_questions_information": " on Florence Academy. We’d love to get your feedback on the course, your learning experience, and Florence Academy overall. Please complete this short seven-question survey. It’s totally anonymous and we use any information you give to help improve Florence Academy.",
        "warm_regards": "Warm regards,"
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB"
          }
        }
      },
      "nth": {
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "shared": {
      "address": "Techspace Aldgate East, 32-38 Leman Street, London, E1 8EW",
      "banners": {
        "free_portfolio": "Try out the Portfolio for free for a limited time"
      },
      "certificates": {
        "index": {
          "browse_courses_cta": "Browse courses.",
          "complete_expired_state_title": "Your certificates have now expired",
          "copied": "Copied",
          "copy_link": "Copy link",
          "edit_profile": "Edit profile",
          "empty_state_title": "You haven't completed any Academy courses yet.",
          "experience": "Experience",
          "expired_state_subtitle": "Please take courses again",
          "expired_state_title": "Expired certificates",
          "learner_of_the_month": "Every month we choose a Florence Academy learner of the month. Remember to tag Florence when you share your certificate news to enter!",
          "make_profile_public": "Public profile",
          "my_certifications": "My certifications",
          "occupation": "Occupation",
          "page_header": "Completed certificates",
          "page_subtitle": "Your certificates are automatically added to your accounts for",
          "share": "Share the news",
          "share_profile": "Make your profile public and share the link so anyone can view your achievements",
          "share_title": "Share your achievement"
        },
        "payment_alert": {
          "modal_text": "Retaking this assessment will generate a new certificate, which you will be charged for again. You will still be able to access your previous certificates free of charge."
        },
        "pdf": {
          "academy_director_title": "Director of Nursing and Governance",
          "accredited_by": "Florence Academy is CPD accredited and CSTF aligned in the relevant courses.",
          "address": "Techspace Aldgate East, 32-38 Leman Street, London, E1 8EW",
          "candidate_code": "Candidate code",
          "delivered_by_and_on": "e-learning course delivered by Florence Academy on",
          "expires": "Expires",
          "has_completed": "Has completed the",
          "here": "here",
          "more_info": "Need effective staff training? Get more info",
          "verify": "You can verify this candidate by visiting https://academy.florence.co.uk/public/portfolio/verify"
        },
        "show": {
          "certificate_florence": "Certificate_Florence",
          "certificate_info": "Details",
          "completed_subtitle": "Completed",
          "congratulations": "Congratulations <strong>%{first_name} %{last_name}</strong> 🎉 you have completed <strong>%{course}</strong> on %{completion_date}. The following learning outcomes summarise what your have learnt. Now you can share the news with your employer, Linkedin or on social media.",
          "course_created_by": "Course created by",
          "download_cta": "Download certificate",
          "expired_at": "Expired at",
          "private_until_paid": "Your certificate is private until paid.",
          "purchase_to_share": "Purchase to share online with your network.",
          "retake_cta": "Retake course",
          "retake_standard_cta": "Retake standard",
          "verifies": "Has completed <strong>%{course}</strong> delivered by Florence academy on <strong>%{completed_at_date}</strong>",
          "view_certificate_cta": "View certificate",
          "view_standards_cta": "View Standards"
        },
        "social_media": {
          "og_description": "Boost your career with online courses from Florence Academy. CPD accredited and Skills for Care endorsed learning platform.",
          "og_image_alt": "image of a certificate with the Florence Academy logo",
          "og_title": "I've completed a course on Florence Academy!"
        }
      },
      "checkouts": {
        "error_message": "There was an error processing your payment. Please try again.",
        "payment_reference": "Payment reference"
      },
      "continue": "Continue",
      "course_attempts": {
        "new": {
          "audio_not_supported": "Your browser does not support the audio element.",
          "page_header": "Introduction",
          "start_cta": "Start Course"
        }
      },
      "course_categories": {
        "recommended_for_you": {
          "recommended_for_you_header": "Recommended for you"
        },
        "required_training": {
          "required_training_header": "Required Training"
        },
        "show": {
          "courses_empty_state": "Your organisation has no courses in this category"
        },
        "view_all": "View all"
      },
      "courses": {
        "chapter": {
          "back_cta": "Previous chapter",
          "check_your_answer_cta": "Check your answer",
          "next_cta": "Next chapter",
          "take_course_assessment": "Take course assessment",
          "try_again_cta": "Try again"
        },
        "course": {
          "chapter": "chapter",
          "chapters": "chapters",
          "coming_soon": "Coming soon",
          "continue_cta": "Continue training",
          "in_person": "In person",
          "in_person_with_external_content": "External content",
          "interest_registered": "You've registered your interest",
          "minutes": "minutes",
          "one_off": "One off",
          "overview": "Course overview",
          "register_interest_cta": "Register interest",
          "required": "Required",
          "retake_cta": "Retake training",
          "start_cta": "Start training",
          "statuses": {
            "due_on": "Due on",
            "one_off": "One off",
            "overdue": "Overdue"
          }
        },
        "feedback_form": {
          "course_score": "How did you find this course?",
          "recommend_to_friend_score": "On a scale of zero to ten, how likely are you to recommend Florence Academy to a friend or colleague?",
          "submit_cta": "Send feedback and continue",
          "suggestions": "Any suggestions for how this course can improve?"
        },
        "final_assessment": {
          "page_header": "Final assessment",
          "submit_cta": "Submit Answers"
        },
        "index": {
          "account_paused_with": "Account paused with",
          "all_courses_header": "All courses",
          "complete_for_free": "Complete your care training for free.",
          "contact_manager_message": "Please contact a manager if you think this is a mistake",
          "courses_offered_by": "Courses offered by",
          "description": "Free CPD Accredited mandatory training courses. Sign up today and complete your training for free.",
          "empty_state": "No courses match this set of filters",
          "mandatory_training_empty_state": "You haven't got any assigned online training.",
          "mandatory_training_header": "Mandatory training",
          "mandatory_training_subtitle": "Training that your organisation requires you to do.",
          "personal_training_header": "Personal development training",
          "personal_training_subtitle": "Here is a list of all the available training you can complete for your own development.",
          "recommended_courses_empty_state": "There are no course recommendations for you at this moment.",
          "separator": "and",
          "sign_up": "Sign up today.",
          "title": "Available Training Certificates",
          "uncategorised_training_header": "Uncategorised Training",
          "uncategorised_training_subtitle": "Training with no category assigned."
        },
        "nav_menu": {
          "assessment": "Assessment",
          "chapters": "Chapters",
          "course_menu": "Course menu",
          "open_course_menu": "VIEW COURSE MENU",
          "overview": "Overview"
        },
        "paper_certificate": {
          "delivered_by": "course delivered by Florence Academy on",
          "has_completed": "Has completed the",
          "page_title": "Training Certificate"
        },
        "results": {
          "buy_and_save": "Your certificate is ready. Get certified today, and benefit from:",
          "correct_needed": "correct needed",
          "credibility": "It can increase credibility and enhance professional reputation",
          "download_certificate": "You can now download your certificate!",
          "download_certificate_cta": "Download certificate",
          "fail_message": "You didn't pass this time",
          "fail_your_answers": "Your answers",
          "flex_learners_message": "If you are a Florence worker we will automatically add this to your account for free.",
          "free_to_learn": "Free to learn, £2.99 for a certificate",
          "get_certified_cta": "Get certified for",
          "job_prospects": "It can improve job prospects and increase earnings potential",
          "now_download": "You can now download your certificate!",
          "number_correct": "of your answers were correct",
          "page_title": "Result",
          "page_title_successful": "Congratulations 🎉",
          "passed_congratulations": "Congratulations, you passed",
          "passed_with_score": "You passed with a score of",
          "payment_complete": "Payment Complete",
          "previous_certificates_link": "See previous certificates",
          "processing_payment": "We're just processing your payment, your certificate will be available to download from your",
          "profile_link": "profile page",
          "purchase_of": "Thank you for your purchase of",
          "retake": "Take course again",
          "retake_assessment": "Try again",
          "retake_standard": "Retake standard",
          "review_answers_requiring_attention": "Below you can review the answers that require your attention:",
          "see_answers": "See your answers",
          "shortly": "shortly",
          "standard_passed_congratulations": "Congratulations, you have completed Standard",
          "with_score": "with a score of",
          "you_might_also_like": "You might also like..."
        },
        "search": {
          "did_you_mean": "Did you mean",
          "filters": {
            "audience": "Audience",
            "bundle": "Collection",
            "categories_placeholder": "All categories",
            "category": "Category",
            "duration": "Duration",
            "durations_placeholder": "All durations",
            "reset_filters": "Reset filters"
          },
          "no_results_text": "Oops, your search found no results.",
          "placeholder": "Search courses",
          "results_text": "Search results for",
          "returned_results_text": "Your search returned",
          "showing": "Showing",
          "view_all_cta": "View all courses"
        }
      },
      "create_account_cta": "Create an account",
      "delete": "Delete",
      "edit": "Edit",
      "external_assessments": {
        "pdf": {
          "delivered_by_and_on": "course awarded by"
        }
      },
      "extra_info": {
        "continue_cta": "Continue",
        "form_input_four": "What are you hoping to get from Florence Academy?*",
        "form_input_four_options": [
          [
            "To enter the care industry",
            "enter_care_industry"
          ],
          [
            "Essential care training",
            "essential_care_training"
          ],
          [
            "Professional development",
            "professional_development"
          ]
        ],
        "form_input_one": "Where do you live?*",
        "form_input_three": "How much work experience do you have in the care sector?*",
        "form_input_three_options": [
          [
            "None",
            "new_to_care"
          ],
          [
            "Less than a year",
            "zero_to_twelve_months"
          ],
          [
            "Between 1 and 2 years",
            "one_to_two_years"
          ],
          [
            "Between 2 and 5 years",
            "two_to_five_years"
          ],
          [
            "More than 5 years",
            "five_years_plus"
          ]
        ],
        "form_input_two": "What is your current role?*",
        "form_input_two_categories": [
          [
            [
              "-Direct care roles-"
            ],
            [
              "Care worker",
              "care_worker"
            ],
            [
              "Support worker",
              "support_worker"
            ],
            [
              "Senior care worker",
              "senior_care_worker"
            ],
            [
              "Home care worker",
              "home_care_worker"
            ],
            [
              "Unpaid carer",
              "unpaid_carer"
            ],
            [
              "Other social care and support role",
              "other_social_care_and_support_role"
            ]
          ],
          [
            [
              "—Management roles—"
            ],
            [
              "Team leader / manager",
              "team_leader_manager"
            ]
          ],
          [
            [
              "-Regulated roles-"
            ],
            [
              "Registered / qualified nurse",
              "registered_qualified_nurse"
            ],
            [
              "Student nurse",
              "student_nurse"
            ]
          ],
          [
            [
              "-Other-"
            ],
            [
              "Other care role",
              "other_care_role"
            ],
            [
              "I don’t currently work in care",
              "i_dont_currently_work_in_care"
            ]
          ]
        ],
        "invalid_postcode": "Invalid postcode. Please enter a valid UK postcode and re-submit the form.",
        "page_header": "Tell us more about yourself",
        "page_subtitle": "We need a little more information so we can personalise your learning experience",
        "postcode_label": "Postcode",
        "postcode_subtitle": "We ask for this so we can tailor the information we send you",
        "privacy_policy": "I agree to the Terms and Conditions and to my personal data being processed in accordance with Florence’s Privacy Policy, see <a href='https://www.florence.co.uk/legal' target='_blank'>here</a> for policies. Florence may use this data to provide me with further information about its services."
      },
      "flash": {
        "alert": "Alert",
        "error": "Error",
        "notice": "Notice",
        "success": "Success"
      },
      "footer": {
        "accounts": "Accounts",
        "company": "Company",
        "legal": "Legal",
        "legal_url": "https://www.florence.co.uk/legal",
        "privacy": "Privacy Policy",
        "t&c": "Terms & Conditions"
      },
      "login_cta": "Log in",
      "modals": {
        "flex_banner": {
          "headline": "Thousands of opportunities to work flexible shifts on our marketplace",
          "headline_cta": "Find out more",
          "inner_modal": {
            "average_hourly_rate": "Average hourly rate per shift: ",
            "average_monthly_shifts": "Average shifts posted on Florence each month: ",
            "carer_db_value": [
              "carer"
            ],
            "carer_label": "Carer",
            "cta_active": "Search",
            "header": "Flexible shifts the easy way",
            "nurse_db_value": [
              "nurse"
            ],
            "nurse_label": "Nurse",
            "postcode_placeholder": "Search by postcode...",
            "signup_now": "Signup now",
            "subheader": "Find shift information in your area"
          }
        },
        "flex_synced_certificates": {
          "body_one": "All mandatory compliance courses you complete on Academy will be synced to your Florence worker account automatically and free of charge.",
          "body_two": "If you would like to download a PDF of your certificate, or are taking a non-required course please continue to payment.",
          "header": "Looks like you're currently onboarding to our Florence worker platform."
        },
        "happy_learning": "Happy learning!"
      },
      "models": {
        "required_training": {
          "statuses": {
            "assigned_not_complete": "Assigned incomplete",
            "complete_in_date": "Complete",
            "due_soon": "Due soon",
            "one_off_and_completed": "One off and completed",
            "overdue": "Overdue"
          }
        }
      },
      "my_learning": {
        "individual_courses": "Individual Courses",
        "individual_courses_empty_state": "You haven't started any courses yet.",
        "no_courses_started": "Looks like you haven't completed any of the courses in your %{menuTitle}",
        "required_trainings": "Required Training",
        "required_trainings_empty_state": "You don't have any training required yet.",
        "table": {
          "columns": {
            "course": "Course",
            "status": "Status"
          },
          "row": {
            "overdue": "Overdue!",
            "statuses": {
              "completed": "Completed",
              "failed": "Failed",
              "in_progress": "In progress",
              "not_started": "Not started"
            }
          }
        },
        "training_failed": "Training failed"
      },
      "nav": {
        "admin": "Admin",
        "all_courses": "All courses",
        "care_certificate": "Care Certificate",
        "collections": "Collections",
        "courses": "Courses",
        "dashboard": "Dashboard",
        "documents": "Policies",
        "editor": "Editor",
        "events": "Events",
        "faqs": "FAQs",
        "home": "Home",
        "learner_view": "Learner view",
        "login": "Log in",
        "my_account": "My Account",
        "my_learning": "My Learning",
        "new_course": "New Course",
        "portfolio": "Portfolio",
        "profile": "My Profile",
        "purchases": "Purchases",
        "referrals": "Refer a friend",
        "register": "Register",
        "roles": "Roles",
        "settings": "Settings",
        "sign_out": "Sign out",
        "staff": "Staff",
        "subscriptions": "Subscriptions",
        "training": "Training"
      },
      "onboarding": {
        "continue_cta": "Continue",
        "form_input_one": "First name",
        "form_input_two": "Last name",
        "more_details": "We just need a few more details from you",
        "notifications": {
          "complete_all_fields": "Please complete all fields",
          "enter_full_name": "Please enter your full name",
          "invalid_postcode": "Invalid postcode. Please enter a valid UK postcode and re-submit the form.",
          "updated_successfully": "Your account has been successfully updated",
          "welcome": "Welcome to Florence Academy!"
        },
        "page_header": "Almost there!"
      },
      "partials": {
        "bundle_advert": {
          "body": "Work towards compliance with our collection of 10 of the most frequently needed courses for working in care.",
          "cta": "Get started",
          "new": "New"
        }
      },
      "please_wait": "Please wait...",
      "portfolio": {
        "accommodation_required_label": "Do you require accommodation?",
        "add_external_certificate": "Add external certificate",
        "add_work_experience": "Add work experience",
        "care_setting": "Care setting",
        "care_setting_example": "e.g. Care home, Private hospital, Private home",
        "certificate": "Certificate",
        "certificate_completion_date": "When did you get this certificate?",
        "click_on_a_skill": "Click on a skill to add or remove it from your portfolio",
        "country": "Country",
        "course_title": "Course name",
        "current": "Current",
        "current_position": "Current position",
        "daily_schedule_label": "Daily schedule preference",
        "dbs_up_to_date_label": "Is your DBS up to date?",
        "details": "Details",
        "download_all_certificates_cta": "Download all certificates",
        "download_cta": "Download PDF",
        "download_description": "Download your entire portfolio in PDF format",
        "download_portfolio_cta": "Download portfolio summary",
        "employer_name": "Employer name",
        "empty_state": "You haven't added any skills yet",
        "empty_state_external": "No Academy courses completed yet",
        "end_date": "End date",
        "externally_certified_on": "Externally certified on",
        "full_time_toggle_label": "Full time",
        "inadequate_content_copy": "You must have at least one record of work experience and one certificate in your portfolio before you can set yourself as open to work.",
        "learner_generated_skills": "These skills are generated by the learner",
        "looking_in_the_uk_error": "We are currently planning to offer job matching in the UK only",
        "looking_in_uk_label": "Are you looking for work in the UK?",
        "modal_title": "Download options",
        "my_skills": "My Skills",
        "not_selected": "Not selected",
        "occupation": {
          "care_worker": "Care Worker",
          "home_care_worker": "Home Care Worker",
          "i_dont_currently_work_in_care": "I don't currently work in care",
          "other_care_role": "Other Care Role",
          "other_social_care_and_support_role": "Other Social Care and Support Role",
          "registered_qualified_nurse": "Registered / Qualified Nurse",
          "senior_care_worker": "Senior Care Worker",
          "student_nurse": "Student Nurse",
          "team_leader_manager": "Team Leader / Manager",
          "unpaid_carer": "Unpaid Carer"
        },
        "open_to_work": "Open to work in the UK",
        "open_to_work_header": "Add a few extra details to your portfolio to let potential UK employers know you're open to work",
        "open_to_work_modal_header": "What type of shifts are you looking for?",
        "part_time_toggle_label": "Part time",
        "post_code_helper": "This will help potential employers find you more easily",
        "post_code_label": "Postcode",
        "public_certificate_alert": "Your certificate will be public and may show up on public search engines",
        "role_title": "Role title",
        "role_type_label": "Role type preference",
        "select_option": " -- Select an option -- ",
        "selected": "Selected",
        "skill_name": "Skill name",
        "skills": "Skills",
        "skills_list": {
          "allergies": "Allergies",
          "alzheimers": "Alzheimer's",
          "bereavement_support": "Bereavement support",
          "buccal_medication": "Buccal medication",
          "care_planning": "Care planning",
          "childrens_residential": "Children's residential",
          "de_escalation": "De-escalation",
          "dementia": "Dementia",
          "diabetes": "Diabetes",
          "driving": "Driving",
          "duty_of_care": "Duty of care",
          "eating_disorders": "Eating disorders",
          "emars": "EMARS",
          "end_of_life_care": "End of life care",
          "enteral_feeding": "Enteral feeding",
          "experience_with_autism": "Experience with Autism",
          "experience_with_challenging_behaviours": "Experience with challenging behaviours",
          "experience_with_dysphagia": "Experience with Dysphagia",
          "experience_with_epilepsy": "Experience with Epilepsy",
          "experience_with_learning_disability": "Experience with learning disability",
          "experience_with_neurological_conditions": "Experience with Neurological conditions",
          "falls_prevention": "Falls prevention",
          "female_catheter_care": "Female Catheter care",
          "first_aid": "First aid",
          "frailty": "Frailty",
          "gastrostomy_tube_care": "Gastrostomy tube care",
          "genetic_conditions": "Genetic conditions",
          "head_injury": "Head injury",
          "health_and_safety": "Health and safety",
          "hepma": "HEPMA",
          "homelessness": "Homelessness",
          "incident_management": "Incident management",
          "incontinence_care": "Incontinence care",
          "infection_control": "Infection control",
          "legislation": "Legislation",
          "male_catheter_care": "Male Catheter care",
          "management": "Management",
          "managing_abuse": "Managing abuse",
          "mental_health_experience": "Mental health experience",
          "nutrition": "Nutrition",
          "palliative_care": "Palliative care",
          "parkinsons": "Parkinson's",
          "peg_feeding": "PEG feeding",
          "peg_medication": "PEG medication",
          "physical_disability_experience": "Physical disability experience",
          "practical_medication_administration": "Practical medication administration",
          "pvg": "PVG",
          "record_keeping": "Record keeping",
          "restraint_training": "Restraint training",
          "safeguarding": "Safeguarding",
          "seizures": "Seizures",
          "sensory_impairment": "Sensory impairment",
          "sign_language": "Sign language",
          "stoma_care_awareness": "Stoma care awareness",
          "substance_misuse_experience": "Substance misuse experience",
          "suction_feeding": "Suction feeding",
          "suicidal_ideation": "Suicidal ideation",
          "suppository_administration": "Suppository Administration",
          "syringe_driver": "Syringe driver",
          "tracheostomy_administration": "Tracheostomy Administration",
          "working_with_children_and_young_adults": "Working with children and young adults"
        },
        "sponsorship_required_label": "Do you require UK sponsorship?",
        "start_date": "Start date",
        "training_certificates": "Training Certificates",
        "uk_resident_label": "Are you a UK resident",
        "upload_certificate": "Upload your certificate",
        "uploaded_file": "Uploaded file",
        "vaccinations_up_to_date_label": "Are your vaccinations up to date?",
        "valid_uk_driving_license_label": "Do you have a valid UK driving license?",
        "weekly_schedule_label": "Weekly schedule preference",
        "will_replace_file": "(will replace exisiting file)",
        "work_experience": "Work Experience",
        "work_experience_duration": {
          "five_years_plus": "More than 5 years",
          "new_to_care": "New to care",
          "one_to_two_years": "Between 1 and 2 years",
          "two_to_five_years": "Between 2 and 5 years",
          "zero_to_twelve_months": "Less than a year"
        },
        "work_history": "Work History"
      },
      "previous_certificates": {
        "empty_state": "Nothing to show",
        "header": "Previous Certificates"
      },
      "public": {
        "bundles": {
          "card": {
            "get_started": "Get started",
            "start_learning": "Start learning"
          },
          "index": {
            "subtitle": "Collections are curated groups of courses on a particular topic with a discount on the certificates at the end."
          },
          "learning_unlocked": {
            "buy_your_certificate": "Buy your certificate",
            "get_certificates": "Get ten course certificates for the price of eight when you take this course as part of the %{bundle}",
            "more_and_save": "Unlock more learning and save",
            "save_money_with_a_collection": "Save money with a collection"
          },
          "payment_success": {
            "courses_in_learning_area": "courses in the My Learning area",
            "cta": "Get started",
            "error": "Oops, something went wrong",
            "find_your": "Find your",
            "learning_area": "My Learning area",
            "processing_payment": "We're just processing your payment, your collection will be available in the",
            "updated_skills": "We will update your portfolio with new skills you have just learnt"
          },
          "show": {
            "about": "About this collection",
            "bundle_with_charge_cta": "Buy now",
            "bundle_without_charge_cta": "Start",
            "certificate_ad_one": "Get certified for %{price_and_old_price}",
            "certificate_ad_three": "Pay %{old_price} for %{number_of_courses} individual certificates or save with a collection for only %{price}",
            "certificate_ad_two": "Showcase your skills to employers",
            "certificates_included": "Certificates included",
            "curated_by": "Collection created by",
            "flex_detail": "Free for active Florence workers",
            "includes_downloadable_certificates": "Includes %{course_count} certificates",
            "not_available": "This bundle is no longer available",
            "what_you_will_learn": "What you will learn"
          }
        },
        "courses": {
          "join_as": {
            "already_have_an_account": "Already have an account?",
            "as_individual": "As an individual",
            "as_organisation": "As an organisation",
            "how_will_use_academy": "How will you be using Academy?"
          },
          "show": {
            "about_this_course": "About this course",
            "alt_text": "Illustrative image related to course content",
            "certificate": "Certificate",
            "certificate_ad_one": "Showcase your skills with a course certificate, for use in your professional records, portfolio or in job interviews",
            "certificate_ad_one_alternative": "A downloadable, shareable digital certificate",
            "certificate_ad_three": "Increase professional credibility",
            "certificate_ad_three_alternative": "Increase your credibility amongst your peers and professionally",
            "certificate_ad_three_bundle_alternative": "Get certificate as part of the %{bundle_link} %{price}",
            "certificate_ad_two": "Individual course certificate %{price}",
            "certificate_included": "(Certificate included on completion)",
            "chapter": "Chapter",
            "cost_to_learn": "to learn",
            "course_curriculum": "Course curriculum",
            "faq": "FAQ",
            "flex_detail": "Certificates are free for active Florence workers",
            "free": "Free",
            "get_certified": "Get certified",
            "get_certified_for_a_price": "Get certified for",
            "industry_backed": "Industry-backed eLearning",
            "join_cta": "Join now",
            "learning_outcomes": "Learning outcomes",
            "minutes": "minutes",
            "pay_info_one": "Free to learn",
            "pay_info_two": "for a certificate",
            "purchase_now": "Buy now",
            "purchased": "Purchased",
            "rated_by": "Rated %{rating}/5 by our learners",
            "recognised_by": "We're recognised by",
            "related_courses": "Related courses",
            "save_with_collection": "Save with a collection",
            "start_learning_cta": "Start learning",
            "suitable_for": "Suitable for"
          }
        }
      },
      "referrals": {
        "email_address": "Email address of the person you want to refer",
        "error_message": "There was an error creating your referral code. Please try again later."
      },
      "save": "Save",
      "settings": {
        "form_input_one": "First name",
        "form_input_three": "E-mail",
        "form_input_two": "Last name",
        "page_header": "Settings",
        "security_reason_section": "For security reasons you cannot change your name or email from this page. For help, contact <a href='mailto:academy@florence.co.uk'>academy@florence.co.uk</a>"
      },
      "signup_cta": "Sign up",
      "update": "Update",
      "verification": {
        "candidate_code": "Candidate code",
        "full_name": "Full name",
        "missing_candidate_number": "Please enter the 6 character code written on the certificate",
        "missing_name": "Please make sure to enter the name as it shows on the certificate",
        "private_profile": "This user has a private profile. Please contact the user to make their profile public so their certificates can be verified.",
        "subtitle": "This service is used for employers who would like to verify a candidate's certificate",
        "title": "Candidate Verification",
        "unverified": "The details do not match a user in our database. Please check the details and try again.",
        "verify": "Verify"
      }
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Please review the problems below:"
      },
      "no": "No",
      "required": {
        "mark": "*",
        "text": "required"
      },
      "yes": "Yes"
    },
    "support": {
      "array": {
        "last_word_connector": ", and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      }
    },
    "time": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "am": "am",
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "date_and_time": "%d %B %Y · %H:%M",
        "default": "%d %B, %Y",
        "long": "%B %d, %Y %H:%M",
        "month_and_year": "%B, %Y",
        "short": "%d %b %H:%M",
        "time_only": "%H:%M"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "day",
        "month",
        "year"
      ],
      "pm": "pm"
    },
    "views": {
      "pagination": {
        "first": "&laquo; First",
        "last": "Last &raquo;",
        "next": "Next &rsaquo;",
        "previous": "&lsaquo; Prev",
        "truncate": "&hellip;"
      }
    }
  },
  "en-ca": {
    "client": {
      "dashboard": {
        "overview_banners": {
          "care_certificates": "staff with Care Certificate awaiting approval",
          "expired_training": "staff with overdue training",
          "new_certificates": "new certificates last week"
        },
        "staff_overview_header": "Here is an overview of your staff's training.",
        "stat_cards": {
          "active_staff_card": {
            "caption": "paused out of",
            "label": "Staff members",
            "primary_cta": "Manage staff",
            "secondary_cta": "Manage roles",
            "title": "Active staff"
          },
          "care_certificate_card": {
            "caption": "awarded out of",
            "label": "In progress",
            "primary_cta": "Care Certificate Progress",
            "title": "Care Certificate"
          },
          "company_documents_card": {
            "caption": "read out of",
            "label": "Assigned to staff",
            "primary_cta": "Download report",
            "secondary_cta": "Manage documents",
            "title": "Internal documents"
          },
          "job_posting": {
            "caption": "active out of",
            "extra_cta": "Company info",
            "label": "Jobs posted",
            "primary_cta": "Post new",
            "secondary_cta": "View all",
            "title": "Academy match job posting"
          },
          "mandatory_training_card": {
            "caption": "fully compliant out of",
            "extra_cta": "Locations",
            "label": "Average compliance",
            "primary_cta": "Matrix",
            "secondary_cta": "Training",
            "title": "Mandatory training"
          }
        },
        "welcome_back": "Welcome back"
      },
      "jobs": {
        "index": {
          "empty_state": "No jobs posted",
          "hired_job_listing_title": "Hired job listings",
          "inactive_job_listing_title": "Inactive job listings",
          "job_listing_title": "Current job listings",
          "subheader": "listings",
          "view_job_button": "View"
        }
      },
      "matrix": {
        "api_error": "Whoops something went wrong, please try refreshing the page.",
        "download": {
          "please_wait": "Please wait...",
          "request": "Send to email"
        },
        "download_failed": {
          "message": "Please wait 5 minutes and try again.",
          "status": "Download failed..."
        },
        "download_success": {
          "message": "You will receive the file by email shortly.",
          "status": "Generating matrix..."
        },
        "empty_state_title": "No information to display for this filter combination",
        "filter": {
          "courses_subtitle": "COURSES",
          "locations_subtitle": "LOCATIONS",
          "statuses_subtitle": "STATUSES"
        },
        "filter_controls": {
          "apply_cta": "Apply filters",
          "open_cta": "Matrix filters",
          "reset_cta": "Reset"
        },
        "page_header": "Staff compliance details",
        "tables": {
          "courses": {
            "care_certificate_header": "Care certificate",
            "elearning_header": "eLearning",
            "learner_compliance_header": "Learner compliance"
          },
          "name_header": "Name"
        }
      },
      "paused": {
        "show": {
          "contact": "Please contact your account manager or email",
          "header": "This account has been paused."
        }
      },
      "shared": {
        "all_locations": "All locations",
        "permissions": {
          "director": "Director",
          "manager": "Manager"
        }
      },
      "training": {
        "export_all": "Export all rows",
        "export_selected": "Export selected rows",
        "mark_as_complete": "Mark as complete"
      },
      "users": {
        "index": {
          "empty_state": "No staff in this category",
          "new_resource_cta": "Add staff",
          "page_header": "Staff list",
          "statuses": {
            "active": "Active",
            "all": "All",
            "emails_bouncing": "Emails are bouncing",
            "invitation_sent": "Invitation sent",
            "paused": "Paused"
          },
          "table_header_five": "Compliance",
          "table_header_four": "Last login",
          "table_header_one": "Staff name",
          "table_header_three": "Status",
          "table_header_two": "Role"
        },
        "new": {
          "form_input_eight": "Status",
          "form_input_eight_hint": "Paused staff will be excluded from stats and email reminders.",
          "form_input_five": "Role",
          "form_input_four": "Last name",
          "form_input_four_hint": "Changes to the user's name will be applied on the platform. However, if the user needs to change how their name appears on their training certificates, please contact <a href='mailto:academy@florence.co.uk', style='color: #00818F;text-decoration: underline;'>Florence Academy</a>.",
          "form_input_four_placeholder": "e.g. Doe",
          "form_input_manager_inputs": {
            "form_input_one": "Manager at",
            "form_input_one_hint": "Please select the locations this manager can access. Hold Ctrl or Cmd to select multiple",
            "form_input_two": "Weekly digest email",
            "form_input_two_hint": "Communication"
          },
          "form_input_nine": "Home location",
          "form_input_nine_blank_option": "No location",
          "form_input_one": "Email",
          "form_input_one_hint": "For security reasons, you cannot change this user's email from this page. If you wish to change the email, please contact <a href='mailto:academy@florence.co.uk', style='color: #00818F;text-decoration: underline;'>Florence Academy</a>.",
          "form_input_one_placeholder": "jane@example.com",
          "form_input_seven": "Start date",
          "form_input_six": "Extra info (optional)",
          "form_input_three": "First name",
          "form_input_three_placeholder": "e.g. Jane",
          "form_input_two": "Permission",
          "form_input_two_blank_option": "Please select an option",
          "form_input_two_hint_not_persisted": "Managers can manage staff at their locations, assign and approve training",
          "form_input_two_hint_persisted": "Please contact <a href='mailto:academy@florence.co.uk', style='color: #00818F;text-decoration: underline;'>Florence Academy</a> to update this user's permission.",
          "form_input_two_options": {
            "director": "Director",
            "learner": "Learner",
            "manager": "Manager"
          },
          "page_header": "Add a staff member",
          "remove_cta": "Remove from company",
          "remove_cta_confirm": "Are you sure?",
          "save_cta": "Save changes"
        }
      }
    },
    "currency": "$",
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%d %B, %Y",
        "month_and_year": "%B, %Y"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "devise": {
      "confirmations": {
        "confirmed": "Your email address has been successfully confirmed.",
        "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
      },
      "failure": {
        "already_authenticated": "You are already signed in.",
        "inactive": "Your account is not activated yet.",
        "invalid": "Invalid %{authentication_keys} or password.",
        "last_attempt": "You have one more attempt before your account is locked.",
        "locked": "Your account is locked.",
        "not_found_in_database": "Invalid %{authentication_keys} or password.",
        "timeout": "Your session expired. Please sign in again to continue.",
        "unauthenticated": "You need to sign in or sign up before continuing.",
        "unconfirmed": "You have to confirm your email address before continuing."
      },
      "mailer": {
        "confirmation_instructions": {
          "subject": "Confirmation instructions"
        },
        "email_changed": {
          "subject": "Email Changed"
        },
        "password_change": {
          "subject": "Password Changed"
        },
        "reset_password_instructions": {
          "subject": "Reset password instructions"
        },
        "unlock_instructions": {
          "subject": "Unlock instructions"
        }
      },
      "omniauth_callbacks": {
        "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
        "success": "Successfully authenticated from %{kind} account."
      },
      "passwords": {
        "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
        "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
        "updated": "Your password has been changed successfully. You are now signed in.",
        "updated_not_active": "Your password has been changed successfully."
      },
      "registrations": {
        "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
        "signed_up": "Welcome! You have signed up successfully.",
        "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
        "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
        "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
        "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirm link to confirm your new email address.",
        "updated": "Your account has been updated successfully."
      },
      "sessions": {
        "already_signed_out": "Signed out successfully.",
        "signed_in": "Signed in successfully.",
        "signed_out": "Signed out successfully."
      },
      "unlocks": {
        "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
        "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
        "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
      }
    },
    "errors": {
      "messages": {
        "already_confirmed": "was already confirmed, please try signing in",
        "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
        "expired": "has expired, please request a new one",
        "not_found": "not found",
        "not_locked": "was not locked",
        "not_saved": {
          "one": "1 error prohibited this %{resource} from being saved:",
          "other": "%{count} errors prohibited this %{resource} from being saved:"
        }
      }
    },
    "helpers": {
      "label": {
        "certificate_payment": {
          "certificate_latest_downloaded_at": "Certificate downloaded at",
          "formatted_amount": "Amount"
        },
        "company": {
          "hubspot_id": "Hubspot ID"
        },
        "course_certificate_charge": {
          "formatted_amount": "Amount",
          "formatted_vatable_amount": "VAT-able amount"
        },
        "course_charge": {
          "formatted_amount": "Amount",
          "formatted_vatable_amount": "VAT-able amount"
        },
        "course_payment": {
          "formatted_amount": "Amount"
        },
        "event_payment": {
          "formatted_amount": "Amount"
        },
        "payment": {
          "formatted_amount": "Amount",
          "formatted_type": "Type"
        }
      }
    },
    "http_statuses": {
      "404_error": {
        "heading": "Not found",
        "message": "The page you're looking for has moved or no longer exists."
      },
      "422_error": {
        "heading": "Change rejected",
        "message": "You must have taken a wrong turn."
      },
      "500_error": {
        "heading": "Uh oh",
        "message_line_1": "Something went wrong.",
        "message_line_2": "We're looking into it and will fix it as soon as possible."
      }
    },
    "learner": {
      "events": {
        "booked": {
          "page_header": "Booked",
          "starting_in": "The event commences"
        },
        "confirmation": {
          "confirmed_result": "Confirmed",
          "email_sent_to": "An email confirmation has been sent to",
          "login_or_register": "Please create an account or sign in to confirm your booking.",
          "thank_you_for_registering_for": "Thank you for registering for",
          "unconfirmed_result": "Nearly done!",
          "view_event_cta": "View event"
        },
        "event": {
          "booked_notice": "Booked",
          "event_date": "Event date",
          "event_duration": "Event duration",
          "event_time": "Event time",
          "free_notice": "Free",
          "payment_amount": "Payment amount",
          "payment_date": "Payment date",
          "payment_reference": "Payment reference",
          "sold_out_notice": "Sold out",
          "tickets_cta": "Tickets",
          "venue_information": "Venue information",
          "view_cta": "View",
          "view_sold_out_cta": "View (sold out)"
        },
        "index": {
          "non_account_line_one": "Fit professional development",
          "non_account_line_two": "into your life.",
          "page_description": "New online events and events for nurses, carers and care home staff. Build CPD hours and own your professional development - free events available.",
          "page_title": "Online events for health & care staff | Florence Academy",
          "upcoming_header": "Upcoming events",
          "upcoming_subtitle": "All our events count towards your CPD hours for participatory learning."
        },
        "local_time": "local time",
        "show": {
          "about_subtitle": "About this event",
          "breakdown": {
            "date_header": "Date",
            "end_time_header": "End time",
            "host_header": "Host",
            "host_subheader": "This event is hosted by",
            "price_header": "Price",
            "start_time_header": "Start time"
          },
          "dates_and_times": "Dates & times",
          "example_certificate_subtitle": "Training Certificate",
          "exc_vat": "exc VAT",
          "free_event_header": "Free CPD event",
          "free_ticket_cta": "Get your free ticket",
          "length_in": "minutes",
          "multiple_dates_available": "Multiple dates & times available",
          "no_refunds": "We are unable to offer refunds for this event once a purchase has been made.",
          "or": "or",
          "provided_by": "Provided by",
          "purchase_required_first": "Buy your certificate",
          "select_date_to_attend": "Please select the date & time of the event you want to attend",
          "selected_date_and_time": "Selected date & time",
          "tickets_cta": "Tickets",
          "to_book_your_ticket": "to book your ticket",
          "training_required_cta_copy": "here.",
          "training_required_first": "Complete the training and buy your certificate",
          "training_required_header": "A valid certificate for %{course_title} is required before you can attend this practical session. If you are not in possession of a valid certificate, you may be refused entry to the event.",
          "training_required_present": "You have already completed %{course_title}. You can find the certificate in your",
          "uk_time": "(UK time)"
        },
        "sold_out": {
          "message": "is a popular and we've sold all our tickets! New events are posted all the time, so check again soon.",
          "page_header": "Sold out",
          "upcoming_events_cta": "Click here to our other upcoming events"
        }
      },
      "home": {
        "company_documents_widget": {
          "documents_cta": "View documents"
        },
        "compliancy_widget": {
          "compliancy": "compliant",
          "empty_state": "You have no assigned training",
          "resume_training_cta": "View my learning"
        },
        "job_matchers_widget": {
          "widget_copy": "Job matches",
          "widget_sub_copy": "You've got %{number_of_matches} job %{matches}"
        },
        "open_to_work_widget": {
          "setup_portfolio_cta": "View portfolio",
          "widget_copy": "Are you open to work?",
          "widget_copy_already_open": "Are your work details up to date?",
          "widget_sub_copy": "Share your achievements with employers",
          "widget_sub_copy_already_open": "Update your achievements to share with employers"
        },
        "portfolio_widget": {
          "certificate_count": "Certificate",
          "view_portfolio_cta": "View portfolio"
        },
        "resume_training_widget": {
          "resume_training_cta": "View my learning",
          "widget_copy_not_started": "Kick off your training...",
          "widget_copy_started": "Continue where you left off...",
          "widget_sub_copy_collection_started": "You're %{percentage}% through the %{collection_title}",
          "widget_sub_copy_course_started": "You're %{percentage}% through",
          "widget_sub_copy_final_assessment": "Take your final assessment for",
          "widget_sub_copy_not_started": "Start the %{collection_title} today"
        },
        "view_courses_widget": {
          "private_widget_copy": "Explore other training opportunities",
          "public_widget_copy": "Stay up to date with your compliance training",
          "view_coruses_cta": "View courses"
        }
      },
      "jobs": {
        "show": {
          "body": "Add a short message to %{contact} saying why you’re interested in applying for this job.",
          "decline_job_button": "No, thank you",
          "email_sent": "Email sent",
          "say_youre_interested": "Say you’re interested",
          "say_youre_interested_button": "Yes, I am interested",
          "send_email": "Yes, send email"
        }
      },
      "my_purchases": {
        "date": "Date",
        "no_purchases": "You have no purchases yet.",
        "paid": "Paid",
        "price": "Price",
        "purchases": "Purchases",
        "refunded": "Refunded",
        "status": "Status",
        "type": "Type"
      },
      "portfolio": {
        "certificates": {
          "certified_on": "Certified on",
          "expired_on": "Expired on",
          "previous_certificates_link": "See previous certificates"
        }
      }
    },
    "mailers": {
      "course_feedback": {
        "congratulations_on_completing": "Congratulations on completing ",
        "congratulations_on_your_completion": "Congratulations on your completion of ",
        "hi": "Hi ",
        "start_survey": "Start survey",
        "survey_questions_information": " on Florence Academy. We’d love to get your feedback on the course, your learning experience, and Florence Academy overall. Please complete this short seven-question survey. It’s totally anonymous and we use any information you give to help improve Florence Academy.",
        "warm_regards": "Warm regards,"
      }
    },
    "shared": {
      "address": "Techspace Aldgate East, 32-38 Leman Street, London, E1 8EW",
      "banners": {
        "free_portfolio": "Try out the Portfolio for free for a limited time"
      },
      "certificates": {
        "index": {
          "browse_courses_cta": "Browse courses.",
          "complete_expired_state_title": "Your certificates have now expired",
          "copied": "Copied",
          "copy_link": "Copy link",
          "edit_profile": "Edit profile",
          "empty_state_title": "You haven't completed any Academy courses yet.",
          "experience": "Experience",
          "expired_state_subtitle": "Please take courses again",
          "expired_state_title": "Expired certificates",
          "learner_of_the_month": "Every month we choose a Florence Academy learner of the month. Remember to tag Florence when you share your certificate news to enter!",
          "make_profile_public": "Public profile",
          "my_certifications": "My certifications",
          "occupation": "Occupation",
          "page_header": "Completed certificates",
          "page_subtitle": "Your certificates are automatically added to your accounts for",
          "share": "Share the news",
          "share_profile": "Make your profile public and share the link so anyone can view your achievements",
          "share_title": "Share your achievement"
        },
        "payment_alert": {
          "modal_text": "Retaking this assessment will generate a new certificate, which you will be charged for again. You will still be able to access your previous certificates free of charge."
        },
        "pdf": {
          "academy_director_title": "Director of Nursing & Governance",
          "accredited_by": "Florence Academy is CPD accredited and CSTF aligned in the relevant courses.",
          "address": "Techspace Aldgate East, 32-38 Leman Street, London, E1 8EW",
          "candidate_code": "Candidate code",
          "delivered_by_and_on": "e-learning course delivered by Florence Academy on",
          "expires": "Expires",
          "has_completed": "Has completed the",
          "here": "here",
          "more_info": "Need effective staff training? Get more info",
          "verify": "You can verify this candidate by visiting https://academy.florence.co.uk/public/portfolio/verify"
        },
        "show": {
          "certificate_florence": "Certificate_Florence",
          "certificate_info": "Details",
          "completed_subtitle": "Completed",
          "congratulations": "Congratulations <strong>%{first_name} %{last_name}</strong> 🎉 you have completed <strong>%{course}</strong> on %{completion_date}. The following learning outcomes summarise what your have learnt. Now you can share the news with your employer, Linkedin or on social media.",
          "course_created_by": "Course created by",
          "download_cta": "Download certificate",
          "expired_at": "Expired at",
          "private_until_paid": "Your certificate is private until paid.",
          "purchase_to_share": "Purchase to share online with your network.",
          "retake_cta": "Retake course",
          "retake_standard_cta": "Retake standard",
          "verifies": "Has completed <strong>%{course}</strong> delivered by Florence academy on <strong>%{completed_at_date}</strong>",
          "view_certificate_cta": "View certificate",
          "view_standards_cta": "View Standards"
        },
        "social_media": {
          "og_description": "Boost your career with online courses from Florence Academy. CPD accredited and Skills for Care endorsed learning platform.",
          "og_image_alt": "image of a certificate with the Florence Academy logo",
          "og_title": "I've completed a course on Florence Academy!"
        }
      },
      "checkouts": {
        "error_message": "There was an error processing your payment. Please try again.",
        "payment_reference": "Payment reference"
      },
      "continue": "Continue",
      "course_attempts": {
        "new": {
          "audio_not_supported": "Your browser does not support the audio element.",
          "page_header": "Introduction",
          "start_cta": "Start Course"
        }
      },
      "course_categories": {
        "recommended_for_you": {
          "recommended_for_you_header": "Recommended for you"
        },
        "required_training": {
          "required_training_header": "Required Training"
        },
        "show": {
          "courses_empty_state": "Your organisation has no courses in this category"
        },
        "view_all": "View all"
      },
      "courses": {
        "chapter": {
          "back_cta": "Previous chapter",
          "check_your_answer_cta": "Check your answer",
          "next_cta": "Next chapter",
          "take_course_assessment": "Take course assessment",
          "try_again_cta": "Try again"
        },
        "course": {
          "chapter": "chapter",
          "chapters": "chapters",
          "coming_soon": "Coming soon",
          "continue_cta": "Continue training",
          "in_person": "In person",
          "in_person_with_external_content": "External content",
          "interest_registered": "You've registered your interest",
          "minutes": "minutes",
          "one_off": "One off",
          "overview": "Course overview",
          "register_interest_cta": "Register interest",
          "required": "Required",
          "retake_cta": "Retake training",
          "start_cta": "Start training",
          "statuses": {
            "due_on": "Due on",
            "one_off": "One off",
            "overdue": "Overdue"
          }
        },
        "feedback_form": {
          "course_score": "How did you find this course?",
          "recommend_to_friend_score": "On a scale of zero to ten, how likely are you to recommend Florence Academy to a friend or colleague?",
          "submit_cta": "Send feedback and continue",
          "suggestions": "Any suggestions for how this course can improve?"
        },
        "final_assessment": {
          "page_header": "Final assessment",
          "submit_cta": "Submit Answers"
        },
        "index": {
          "account_paused_with": "Account paused with",
          "all_courses_header": "All courses",
          "complete_for_free": "Complete your care training for free.",
          "contact_manager_message": "Please contact a manager if you think this is a mistake",
          "courses_offered_by": "Courses offered by",
          "description": "Free CPD Accredited mandatory training courses. Sign up today and complete your training for free.",
          "empty_state": "No courses match this set of filters",
          "mandatory_training_empty_state": "You haven't got any assigned online training.",
          "mandatory_training_header": "Mandatory training",
          "mandatory_training_subtitle": "Training that your organisation requires you to do.",
          "personal_training_header": "Personal development training",
          "personal_training_subtitle": "Here is a list of all the available training you can complete for your own development.",
          "recommended_courses_empty_state": "There are no course recommendations for you at this moment.",
          "separator": "and",
          "sign_up": "Sign up today.",
          "title": "Available Training Certificates",
          "uncategorised_training_header": "Uncategorised Training",
          "uncategorised_training_subtitle": "Training with no category assigned."
        },
        "nav_menu": {
          "assessment": "Assessment",
          "chapters": "Chapters",
          "course_menu": "Course menu",
          "open_course_menu": "VIEW COURSE MENU",
          "overview": "Overview"
        },
        "paper_certificate": {
          "delivered_by": "course delivered by Florence Academy on",
          "has_completed": "Has completed the",
          "page_title": "Training Certificate"
        },
        "results": {
          "buy_and_save": "Your certificate is ready. Get certified today, and benefit from:",
          "correct_needed": "correct needed",
          "credibility": "It can increase credibility and enhance professional reputation",
          "download_certificate": "You can now download your certificate!",
          "download_certificate_cta": "Download certificate",
          "fail_message": "You didn't pass this time",
          "fail_your_answers": "Your answers",
          "flex_learners_message": "If you are a Florence worker we will automatically add this to your account for free.",
          "free_to_learn": "Free to learn, £2.99 for a certificate",
          "get_certified_cta": "Get certified for",
          "job_prospects": "It can improve job prospects and increase earnings potential",
          "now_download": "You can now download your certificate!",
          "number_correct": "of your answers were correct",
          "page_title": "Result",
          "page_title_successful": "Congratulations 🎉",
          "passed_congratulations": "Congratulations, you passed",
          "passed_with_score": "You passed with a score of",
          "payment_complete": "Payment Complete",
          "previous_certificates_link": "See previous certificates",
          "processing_payment": "We're just processing your payment, your certificate will be available to download from your",
          "profile_link": "profile page",
          "purchase_of": "Thank you for your purchase of",
          "retake": "Take course again",
          "retake_assessment": "Try again",
          "retake_standard": "Retake standard",
          "review_answers_requiring_attention": "Below you can review the answers that require your attention:",
          "see_answers": "See your answers",
          "shortly": "shortly",
          "standard_passed_congratulations": "Congratulations, you have completed Standard",
          "with_score": "with a score of",
          "you_might_also_like": "You might also like..."
        },
        "search": {
          "did_you_mean": "Did you mean",
          "filters": {
            "audience": "Audience",
            "bundle": "Collection",
            "categories_placeholder": "All categories",
            "category": "Category",
            "duration": "Duration",
            "durations_placeholder": "All durations",
            "reset_filters": "Reset filters"
          },
          "no_results_text": "Oops, your search found no results.",
          "placeholder": "Search courses",
          "results_text": "Search results for",
          "returned_results_text": "Your search returned",
          "showing": "Showing",
          "view_all_cta": "View all courses"
        }
      },
      "create_account_cta": "Create an account",
      "delete": "Delete",
      "edit": "Edit",
      "external_assessments": {
        "pdf": {
          "delivered_by_and_on": "course awarded by"
        }
      },
      "extra_info": {
        "continue_cta": "Continue",
        "form_input_four": "What are you hoping to get from Florence Academy?*",
        "form_input_four_options": [
          [
            "To enter the care industry",
            "enter_care_industry"
          ],
          [
            "Essential care training",
            "essential_care_training"
          ],
          [
            "Professional development",
            "professional_development"
          ]
        ],
        "form_input_one": "Where do you live?*",
        "form_input_three": "How much work experience do you have in the care sector?*",
        "form_input_three_options": [
          [
            "None",
            "new_to_care"
          ],
          [
            "Less than a year",
            "zero_to_twelve_months"
          ],
          [
            "Between 1 and 2 years",
            "one_to_two_years"
          ],
          [
            "Between 2 and 5 years",
            "two_to_five_years"
          ],
          [
            "More than 5 years",
            "five_years_plus"
          ]
        ],
        "form_input_two": "What is your current role?*",
        "form_input_two_categories": [
          [
            [
              "-Direct care roles-"
            ],
            [
              "Care worker",
              "care_worker"
            ],
            [
              "Support worker",
              "support_worker"
            ],
            [
              "Senior care worker",
              "senior_care_worker"
            ],
            [
              "Home care worker",
              "home_care_worker"
            ],
            [
              "Unpaid carer",
              "unpaid_carer"
            ],
            [
              "Other social care and support role",
              "other_social_care_and_support_role"
            ]
          ],
          [
            [
              "—Management roles—"
            ],
            [
              "Team leader / manager",
              "team_leader_manager"
            ]
          ],
          [
            [
              "-Regulated roles-"
            ],
            [
              "Registered / qualified nurse",
              "registered_qualified_nurse"
            ],
            [
              "Student nurse",
              "student_nurse"
            ]
          ],
          [
            [
              "-Other-"
            ],
            [
              "Other care role",
              "other_care_role"
            ],
            [
              "I don’t currently work in care",
              "i_dont_currently_work_in_care"
            ]
          ]
        ],
        "page_header": "Tell us more about yourself",
        "page_subtitle": "We need a little more information so we can personalise your learning experience",
        "postcode_label": "Postcode",
        "postcode_subtitle": "We ask for this so we can tailor the information we send you",
        "privacy_policy": "I agree to the Terms and Conditions and to my personal data being processed in accordance with Florence’s Privacy Policy, see <a href='https://www.florence.co.uk/legal' target='_blank'>here</a> for policies. Florence may use this data to provide me with further information about its services."
      },
      "flash": {
        "alert": "Alert",
        "error": "Error",
        "notice": "Notice",
        "success": "Success"
      },
      "footer": {
        "accounts": "Accounts",
        "company": "Company",
        "legal": "Legal",
        "legal_url": "https://www.florenceapp.com/ca/legal",
        "privacy": "Privacy Policy",
        "t&c": "Terms & Conditions"
      },
      "login_cta": "Log in",
      "modals": {
        "flex_banner": {
          "headline": "Thousands of opportunities to work flexible shifts on our marketplace",
          "headline_cta": "Find out more",
          "inner_modal": {
            "average_hourly_rate": "Average hourly rate per shift: ",
            "average_monthly_shifts": "Average shifts posted on Florence each month: ",
            "carer_db_value": [
              "personal_support_worker"
            ],
            "carer_label": "Support Worker",
            "cta_active": "Search",
            "header": "Flexible shifts the easy way",
            "nurse_db_value": [
              "registered_practical_nurse",
              "licensed_practical_nurse"
            ],
            "nurse_label": "Practical Nurse",
            "postcode_placeholder": "Search by postcode...",
            "signup_now": "Signup now",
            "subheader": "Find shift information in your area"
          }
        },
        "flex_synced_certificates": {
          "body_one": "All mandatory compliance courses you complete on Academy will be synced to your Florence worker account automatically and free of charge.",
          "body_two": "If you would like to download a PDF of your certificate, or are taking a non-required course please continue to payment.",
          "header": "Looks like you're currently onboarding to our Florence worker platform."
        },
        "happy_learning": "Happy learning!"
      },
      "models": {
        "required_training": {
          "statuses": {
            "assigned_not_complete": "Assigned incomplete",
            "complete_in_date": "Complete",
            "due_soon": "Due soon",
            "one_off_and_completed": "One off and completed",
            "overdue": "Overdue"
          }
        }
      },
      "my_learning": {
        "individual_courses": "Individual Courses",
        "individual_courses_empty_state": "You haven't started any courses yet.",
        "no_courses_started": "Looks like you haven't completed any of the courses in your %{menuTitle}",
        "required_trainings_empty_state": "You don't have any training required yet.",
        "table": {
          "columns": {
            "course": "Course",
            "status": "Status"
          },
          "row": {
            "overdue": "Overdue!",
            "statuses": {
              "completed": "Completed",
              "failed": "Failed",
              "in_progress": "In progress",
              "not_started": "Not started"
            }
          }
        },
        "training_failed": "Training failed"
      },
      "nav": {
        "admin": "Admin",
        "all_courses": "All courses",
        "care_certificate": "Care Certificate",
        "collections": "Collections",
        "courses": "Courses",
        "dashboard": "Dashboard",
        "documents": "Policies",
        "events": "Events",
        "faqs": "FAQs",
        "home": "Home",
        "learner_view": "Learner view",
        "login": "Log in",
        "my_account": "My Account",
        "my_learning": "My Learning",
        "new_course": "New Course",
        "portfolio": "Portfolio",
        "profile": "My Profile",
        "purchases": "Purchases",
        "referrals": "Refer a friend",
        "register": "Register",
        "roles": "Roles",
        "settings": "Settings",
        "sign_out": "Sign out",
        "staff": "Staff",
        "subscriptions": "Subscriptions",
        "training": "Training"
      },
      "onboarding": {
        "continue_cta": "Continue",
        "form_input_one": "First name",
        "form_input_two": "Last name",
        "more_details": "We just need a few more details from you",
        "notifications": {
          "complete_all_fields": "Please complete all fields",
          "enter_full_name": "Please enter your full name",
          "invalid_postcode": "Invalid postcode. Please enter a valid UK postcode and re-submit the form.",
          "updated_successfully": "Your account has been successfully updated",
          "welcome": "Welcome to Florence Academy!"
        },
        "page_header": "Almost there!"
      },
      "partials": {
        "bundle_advert": {
          "body": "Work towards compliance with our collection of 10 of the most frequently needed courses for working in care.",
          "cta": "Get started",
          "new": "New"
        }
      },
      "please_wait": "Please wait...",
      "portfolio": {
        "accommodation_required_label": "Do you require accommodation?",
        "add_external_certificate": "Add external certificate",
        "add_work_experience": "Add work experience",
        "care_setting": "Care setting",
        "care_setting_example": "e.g. Care home, Private hospital, Private home",
        "certificate": "Certificate",
        "certificate_completion_date": "When did you get this certificate?",
        "click_on_a_skill": "Click on a skill to add or remove it from your portfolio",
        "country": "Country",
        "course_title": "Course name",
        "current": "Current",
        "current_position": "Current position",
        "daily_schedule_label": "Daily schedule preference",
        "dbs_up_to_date_label": "Is your DBS up to date?",
        "details": "Details",
        "download_all_certificates_cta": "Download all certificates",
        "download_cta": "Download PDF",
        "download_description": "Download your entire portfolio in PDF format",
        "download_portfolio_cta": "Download portfolio summary",
        "employer_name": "Employer name",
        "empty_state": "You haven't added any skills yet",
        "empty_state_external": "No Academy courses completed yet",
        "end_date": "End date",
        "externally_certified_on": "Externally certified on",
        "full_time_toggle_label": "Full time",
        "inadequate_content_copy": "You must have at least one record of work experience and one certificate in your portfolio before you can set yourself as open to work.",
        "learner_generated_skills": "These skills are generated by the learner",
        "looking_in_the_uk_error": "We are currently planning to offer job matching in the UK only",
        "looking_in_uk_label": "Are you looking for work in the UK?",
        "modal_title": "Download options",
        "my_skills": "My Skills",
        "not_selected": "Not selected",
        "occupation": {
          "care_worker": "Care Worker",
          "home_care_worker": "Home Care Worker",
          "i_dont_currently_work_in_care": "I don't currently work in care",
          "other_care_role": "Other Care Role",
          "other_social_care_and_support_role": "Other Social Care and Support Role",
          "registered_qualified_nurse": "Registered / Qualified Nurse",
          "senior_care_worker": "Senior Care Worker",
          "student_nurse": "Student Nurse",
          "team_leader_manager": "Team Leader / Manager",
          "unpaid_carer": "Unpaid Carer"
        },
        "open_to_work": "Open to work in the UK",
        "open_to_work_header": "Add a few extra details to your portfolio to let potential UK employers know you're open to work",
        "open_to_work_modal_header": "What type of shifts are you looking for?",
        "part_time_toggle_label": "Part time",
        "post_code_helper": "This will help potential employers find you more easily",
        "post_code_label": "Postcode",
        "public_certificate_alert": "Your certificate will be public and may show up on public search engines",
        "role_title": "Role title",
        "role_type_label": "Role type preference",
        "select_option": " -- Select an option -- ",
        "selected": "Selected",
        "skill_name": "Skill name",
        "skills": "Skills",
        "skills_list": {
          "allergies": "Allergies",
          "alzheimers": "Alzheimer's",
          "bereavement_support": "Bereavement support",
          "buccal_medication": "Buccal medication",
          "care_planning": "Care planning",
          "childrens_residential": "Children's residential",
          "de_escalation": "De-escalation",
          "dementia": "Dementia",
          "diabetes": "Diabetes",
          "driving": "Driving",
          "duty_of_care": "Duty of care",
          "eating_disorders": "Eating disorders",
          "emars": "EMARS",
          "end_of_life_care": "End of life care",
          "enteral_feeding": "Enteral feeding",
          "experience_with_autism": "Experience with Autism",
          "experience_with_challenging_behaviours": "Experience with challenging behaviours",
          "experience_with_dysphagia": "Experience with Dysphagia",
          "experience_with_epilepsy": "Experience with Epilepsy",
          "experience_with_learning_disability": "Experience with learning disability",
          "experience_with_neurological_conditions": "Experience with Neurological conditions",
          "falls_prevention": "Falls prevention",
          "female_catheter_care": "Female Catheter care",
          "first_aid": "First aid",
          "frailty": "Frailty",
          "gastrostomy_tube_care": "Gastrostomy tube care",
          "genetic_conditions": "Genetic conditions",
          "head_injury": "Head injury",
          "health_and_safety": "Health and safety",
          "hepma": "HEPMA",
          "homelessness": "Homelessness",
          "incident_management": "Incident management",
          "incontinence_care": "Incontinence care",
          "infection_control": "Infection control",
          "legislation": "Legislation",
          "male_catheter_care": "Male Catheter care",
          "management": "Management",
          "managing_abuse": "Managing abuse",
          "mental_health_experience": "Mental health experience",
          "nutrition": "Nutrition",
          "palliative_care": "Palliative care",
          "parkinsons": "Parkinson's",
          "peg_feeding": "PEG feeding",
          "peg_medication": "PEG medication",
          "physical_disability_experience": "Physical disability experience",
          "practical_medication_administration": "Practical medication administration",
          "pvg": "PVG",
          "record_keeping": "Record keeping",
          "restraint_training": "Restraint training",
          "safeguarding": "Safeguarding",
          "seizures": "Seizures",
          "sensory_impairment": "Sensory impairment",
          "sign_language": "Sign language",
          "stoma_care_awareness": "Stoma care awareness",
          "substance_misuse_experience": "Substance misuse experience",
          "suction_feeding": "Suction feeding",
          "suicidal_ideation": "Suicidal ideation",
          "suppository_administration": "Suppository Administration",
          "syringe_driver": "Syringe driver",
          "tracheostomy_administration": "Tracheostomy Administration",
          "working_with_children_and_young_adults": "Working with children and young adults"
        },
        "sponsorship_required_label": "Do you require UK sponsorship?",
        "start_date": "Start date",
        "training_certificates": "Training Certificates",
        "uk_resident_label": "Are you a UK resident",
        "upload_certificate": "Upload your certificate",
        "uploaded_file": "Uploaded file",
        "vaccinations_up_to_date_label": "Are your vaccinations up to date?",
        "valid_uk_driving_license_label": "Do you have a valid UK driving license?",
        "weekly_schedule_label": "Weekly schedule preference",
        "will_replace_file": "(will replace exisiting file)",
        "work_experience": "Work Experience",
        "work_experience_duration": {
          "five_years_plus": "More than 5 years",
          "new_to_care": "New to care",
          "one_to_two_years": "Between 1 and 2 years",
          "two_to_five_years": "Between 2 and 5 years",
          "zero_to_twelve_months": "Less than a year"
        },
        "work_history": "Work History"
      },
      "previous_certificates": {
        "empty_state": "Nothing to show",
        "header": "Previous Certificates"
      },
      "public": {
        "bundles": {
          "card": {
            "get_started": "Get started",
            "start_learning": "Start learning"
          },
          "index": {
            "subtitle": "Collections are curated groups of courses on a particular topic with a discount on the certificates at the end."
          },
          "learning_unlocked": {
            "buy_your_certificate": "Buy your certificate",
            "get_certificates": "Get ten course certificates for the price of eight when you take this course as part of the %{bundle}",
            "more_and_save": "Unlock more learning and save",
            "save_money_with_a_collection": "Save money with a collection"
          },
          "payment_success": {
            "courses_in_learning_area": "courses in the My Learning area",
            "cta": "Get started",
            "error": "Oops, something went wrong",
            "find_your": "Find your",
            "learning_area": "My Learning area",
            "processing_payment": "We're just processing your payment, your collection will be available in the",
            "updated_skills": "We will update your portfolio with new skills you have just learnt"
          },
          "show": {
            "about": "About this collection",
            "bundle_with_charge_cta": "Buy now",
            "bundle_without_charge_cta": "Start",
            "certificate_ad_one": "Get certified for %{price_and_old_price}",
            "certificate_ad_three": "Pay %{old_price} for %{number_of_courses} individual certificates or save with a collection for only %{price}",
            "certificate_ad_two": "Showcase your skills to employers",
            "certificates_included": "Certificates included",
            "curated_by": "Collection created by",
            "flex_detail": "Free for active Florence workers",
            "includes_downloadable_certificates": "Includes %{course_count} certificates",
            "not_available": "This bundle is no longer available",
            "what_you_will_learn": "What you will learn"
          }
        },
        "courses": {
          "join_as": {
            "already_have_an_account": "Already have an account?",
            "as_individual": "As an individual",
            "as_organisation": "As an organisation",
            "how_will_use_academy": "How will you be using Academy?"
          },
          "show": {
            "about_this_course": "About this course",
            "alt_text": "Illustrative image related to course content",
            "certificate": "Certificate",
            "certificate_ad_one": "Showcase your skills with a course certificate, for use in your professional records, portfolio or in job interviews",
            "certificate_ad_one_alternative": "A downloadable, shareable digital certificate",
            "certificate_ad_three": "Increase professional credibility",
            "certificate_ad_three_alternative": "Increase your credibility amongst your peers and professionally",
            "certificate_ad_three_bundle_alternative": "Get certificate as part of the %{bundle_link} %{price}",
            "certificate_ad_two": "Individual course certificate %{price}",
            "certificate_included": "(Certificate included on completion)",
            "chapter": "Chapter",
            "cost_to_learn": "to learn",
            "course_curriculum": "Course curriculum",
            "faq": "FAQ",
            "flex_detail": "Certificates are free for active Florence workers",
            "free": "Free",
            "get_certified": "Get certified",
            "get_certified_for_a_price": "Get certified for",
            "industry_backed": "Industry-backed eLearning",
            "join_cta": "Join now",
            "learning_outcomes": "Learning outcomes",
            "minutes": "minutes",
            "pay_info_one": "Free to learn",
            "pay_info_two": "for a certificate",
            "purchase_now": "Buy now",
            "purchased": "Purchased",
            "rated_by": "Rated %{rating}/5 by our learners",
            "recognised_by": "We're recognised by",
            "related_courses": "Related courses",
            "save_with_collection": "Save with a collection",
            "start_learning_cta": "Start learning",
            "suitable_for": "Suitable for"
          }
        }
      },
      "referrals": {
        "email_address": "Email address of the person you want to refer",
        "error_message": "There was an error creating your referral code. Please try again later."
      },
      "save": "Save",
      "settings": {
        "form_input_one": "First name",
        "form_input_three": "E-mail",
        "form_input_two": "Last name",
        "page_header": "Settings",
        "security_reason_section": "For security reasons you cannot change your name or email from this page. For help, contact <a href='mailto:academy@florence.co.uk'>academy@florence.co.uk</a>"
      },
      "signup_cta": "Sign up",
      "update": "Update",
      "verification": {
        "candidate_code": "Candidate code",
        "full_name": "Full name",
        "missing_candidate_number": "Please enter the 6 character code written on the certificate",
        "missing_name": "Please make sure to enter the name as it shows on the certificate",
        "private_profile": "This user has a private profile. Please contact the user to make their profile public so their certificates can be verified.",
        "subtitle": "This service is used for employers who would like to verify a candidate's certificate",
        "title": "Candidate Verification",
        "unverified": "The details do not match a user in our database. Please check the details and try again.",
        "verify": "Verify"
      }
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Please review the problems below:"
      },
      "no": "No",
      "required": {
        "mark": "*",
        "text": "required"
      },
      "yes": "Yes"
    },
    "time": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "date_and_time": "%d %B %Y · %H:%M",
        "default": "%d %B, %Y",
        "month_and_year": "%B, %Y",
        "time_only": "%H:%M"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    }
  },
  "fr": {
    "administrate": {
      "actions": {
        "back": "Précédent",
        "confirm": "Êtes-vous sûr(e) ?",
        "destroy": "Supprimer",
        "edit": "Modifier",
        "edit_resource": "Modifier %{name}",
        "new_resource": "Création %{name}",
        "show_resource": "Détails %{name}"
      },
      "controller": {
        "create": {
          "success": "%{resource} a été correctement créé(e)."
        },
        "destroy": {
          "success": "%{resource} a été correctement supprimé(e)."
        },
        "update": {
          "success": "%{resource} a été correctement modifié(e)."
        }
      },
      "fields": {
        "has_many": {
          "more": "%{count} sur %{total_count}",
          "none": "Aucun"
        }
      },
      "form": {
        "error": "erreur",
        "errors": "%{pluralized_errors} ont empêché %{resource_name} d'être sauvegardé(e) :"
      },
      "navigation": {
        "back_to_app": "Retour à l'application"
      },
      "search": {
        "clear": "Effacer la recherche",
        "label": "Chercher %{resource}"
      }
    },
    "client": {
      "dashboard": {
        "overview_banners": {
          "care_certificates": "personnel avec certificat de soins en attente d'approbation",
          "expired_training": "personnel avec une formation en retard",
          "new_certificates": "nouveaux certificats la semaine dernière"
        },
        "staff_overview_header": "Voici un aperçu de la formation de votre personnel.",
        "stat_cards": {
          "active_staff_card": {
            "caption": "en pause sur",
            "label": "Les membres du personnel",
            "primary_cta": "Gérer le personnel",
            "secondary_cta": "Gérer les rôles",
            "title": "Personnel actif"
          },
          "care_certificate_card": {
            "caption": "récompensés sur",
            "label": "En cours",
            "primary_cta": "Progrès du certificat de soins",
            "title": "Certificat de soins"
          },
          "company_documents_card": {
            "caption": "lire à partir de",
            "label": "Attribué au personnel",
            "primary_cta": "Télécharger le rapport",
            "secondary_cta": "Gérer les documents",
            "title": "Documents internes"
          },
          "job_posting": {
            "caption": "actif hors de",
            "extra_cta": "Informations sur l'entreprise",
            "label": "Emplois affichés",
            "primary_cta": "Poster nouveau",
            "secondary_cta": "Voir tout",
            "title": "Offre d’emploi de match de l’Académie"
          },
          "mandatory_training_card": {
            "caption": "entièrement conforme sur",
            "extra_cta": "Emplacements",
            "label": "Conformité moyenne",
            "primary_cta": "Matrice",
            "secondary_cta": "Entraînement",
            "title": "Formation obligatoire"
          }
        },
        "welcome_back": "Bienvenue"
      },
      "jobs": {
        "index": {
          "empty_state": "Aucun emploi publié",
          "hired_job_listing_title": "Offres d'emploi embauchées",
          "inactive_job_listing_title": "Offres d'emploi inactives",
          "job_listing_title": "Offres d'emploi actuelles",
          "subheader": "Annonces",
          "view_job_button": "Voir"
        }
      },
      "matrix": {
        "api_error": "Oups, quelque chose s'est mal passé, veuillez essayer de rafraîchir la page.",
        "download": {
          "please_wait": "S'il vous plaît, attendez...",
          "request": "Envoyer par email"
        },
        "download_failed": {
          "message": "Veuillez patienter 5 minutes et réessayer.",
          "status": "Échec du téléchargement..."
        },
        "download_success": {
          "message": "Vous recevrez le fichier par email sous peu.",
          "status": "Génération de matrice..."
        },
        "empty_state_title": "Aucune information à afficher pour cette combinaison de filtres",
        "filter": {
          "courses_subtitle": "COURS",
          "locations_subtitle": "EMPLACEMENTS",
          "statuses_subtitle": "STATUTS"
        },
        "filter_controls": {
          "apply_cta": "Appliquer des filtres",
          "open_cta": "Filtres matriciels",
          "reset_cta": "Réinitialiser"
        },
        "page_header": "Détails de conformité du personnel",
        "tables": {
          "courses": {
            "care_certificate_header": "Certificat de soins",
            "elearning_header": "Apprentissage en ligne",
            "learner_compliance_header": "Conformité de l'apprenant"
          },
          "name_header": "Nom"
        }
      },
      "shared": {
        "all_locations": "Tous les emplacements",
        "permissions": {
          "director": "Directeur",
          "manager": "Gestionnaire"
        }
      },
      "training": {
        "export_all": "Export all rows",
        "export_selected": "Export selected rows",
        "mark_as_complete": "Mark as complete"
      },
      "users": {
        "index": {
          "empty_state": "Aucun membre du personnel dans cette catégorie",
          "new_resource_cta": "Ajouter du personnel",
          "page_header": "Liste du personnel",
          "statuses": {
            "active": "Actif",
            "all": "Tout",
            "emails_bouncing": "Les e-mails rebondissent",
            "invitation_sent": "Invitation envoyée",
            "paused": "En pause"
          },
          "table_header_five": "Conformité",
          "table_header_four": "Dernière connexion",
          "table_header_one": "Nom du personnel",
          "table_header_three": "Statut",
          "table_header_two": "Rôle"
        },
        "new": {
          "form_input_eight": "Statut",
          "form_input_eight_hint": "Le personnel en pause sera exclu des statistiques et des rappels par e-mail.",
          "form_input_five": "Rôle",
          "form_input_four": "Nom de famille",
          "form_input_four_hint": "Les modifications apportées au nom de l'utilisateur seront appliquées sur la plate-forme. Toutefois, si l'utilisateur a besoin de modifier la façon dont son nom apparaît sur ses certificats de formation, veuillez contacter <a href='mailto:academy@florence.co.uk', style='color: #00818F;text-decoration: underline;'>Florence Academy</a>.",
          "form_input_four_placeholder": "par exemple. Doe",
          "form_input_manager_inputs": {
            "form_input_one": "Gérant chez",
            "form_input_one_hint": "Veuillez sélectionner les emplacements auxquels ce gestionnaire peut accéder. Maintenez Ctrl ou Cmd pour sélectionner plusieurs",
            "form_input_two": "Courrier électronique de synthèse hebdomadaire",
            "form_input_two_hint": "Communication"
          },
          "form_input_nine": "Lieu d'origine",
          "form_input_nine_blank_option": "Pas d'emplacement",
          "form_input_one": "Email",
          "form_input_one_hint": "Pour des raisons de sécurité, vous ne pouvez pas modifier l'adresse e-mail de cet utilisateur à partir de cette page. Si vous souhaitez modifier l'adresse e-mail, veuillez contacter <a href='mailto:academy@florence.co.uk', style='color: #00818F;text-decoration: underline;'>Florence Academy</a>.",
          "form_input_one_placeholder": "jeanne@exemple.com",
          "form_input_seven": "Date de début",
          "form_input_six": "Informations supplémentaires (facultatif)",
          "form_input_three": "Prénom",
          "form_input_three_placeholder": "par exemple. Jeanne",
          "form_input_two": "Autorisation",
          "form_input_two_blank_option": "Veuillez sélectionner une option",
          "form_input_two_hint_not_persisted": "Les responsables peuvent gérer le personnel sur leurs sites, attribuer et approuver la formation",
          "form_input_two_hint_persisted": "Veuillez contacter <a href='mailto:academy@florence.co.uk', style='color: #00818F;text-decoration: underline;'>Florence Academy</a> pour mettre à jour l'autorisation de cet utilisateur.",
          "form_input_two_options": {
            "director": "Directeur",
            "learner": "Apprenant",
            "manager": "Gestionnaire"
          },
          "page_header": "Ajouter un membre du personnel",
          "remove_cta": "Retirer de l'entreprise",
          "remove_cta_confirm": "Êtes-vous sûr?",
          "save_cta": "Sauvegarder les modifications"
        }
      }
    },
    "currency": "€",
    "date": {
      "abbr_day_names": [
        "Dim",
        "Lun",
        "Mar",
        "Mer",
        "Jeu",
        "Ven",
        "Sam"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Août",
        "Sep",
        "Oct",
        "Nov",
        "Déc"
      ],
      "day_names": [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi"
      ],
      "formats": {
        "default": "%d %B, %Y",
        "month_and_year": "%B, %Y"
      },
      "month_names": [
        null,
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "dotiw": {
        "days": {
          "one": "1 jour",
          "other": "%{count} jours"
        },
        "hours": {
          "one": "1 heure",
          "other": "%{count} heures"
        },
        "less_than_x": "moins de %{distance}",
        "minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "months": {
          "one": "1 mois",
          "other": "%{count} mois"
        },
        "seconds": {
          "one": "1 seconde",
          "other": "%{count} secondes"
        },
        "weeks": {
          "one": "1 semaine",
          "other": "%{count} semaines"
        },
        "years": {
          "one": "1 an",
          "other": "%{count} ans"
        }
      },
      "dotiw_compact": {
        "about_x_years": {
          "any": "~%{count}a",
          "one": "~1a"
        },
        "almost_x_years": {
          "any": "~%{count}a",
          "one": "~1a"
        },
        "days": {
          "one": "1j",
          "other": "%{count}j"
        },
        "hours": {
          "one": "1h",
          "other": "%{count}h"
        },
        "last_word_connector": "",
        "less_than_x": "<%{distance}",
        "minutes": {
          "one": "1m",
          "other": "%{count}m"
        },
        "months": {
          "one": "1mo",
          "other": "%{count}mo"
        },
        "over_x_years": {
          "one": ">1a",
          "other": ">%{count}a"
        },
        "seconds": {
          "one": "1s",
          "other": "%{count}s"
        },
        "two_words_connector": "",
        "weeks": {
          "one": "1se",
          "other": "%{count}se"
        },
        "words_connector": "",
        "years": {
          "one": "1a",
          "other": "%{count}a"
        }
      }
    },
    "helpers": {
      "label": {
        "certificate_payment": {
          "certificate_latest_downloaded_at": "Certificat téléchargé sur",
          "formatted_amount": "Montant"
        },
        "company": {
          "hubspot_id": "Identifiant Hubspot"
        },
        "course_certificate_charge": {
          "formatted_amount": "Montant",
          "formatted_vatable_amount": "VAT-able montant"
        },
        "course_charge": {
          "formatted_amount": "Montant",
          "formatted_vatable_amount": "VAT-able montant"
        },
        "course_payment": {
          "formatted_amount": "Montant"
        },
        "event_payment": {
          "formatted_amount": "Montant"
        },
        "payment": {
          "formatted_amount": "Montant",
          "formatted_type": "Type"
        }
      }
    },
    "http_statuses": {
      "404_error": {
        "heading": "Pas trouvé",
        "message": "La page que vous recherchez a été déplacée ou n'existe plus."
      },
      "422_error": {
        "heading": "Modification refusée",
        "message": "Vous avez dû vous tromper de direction."
      },
      "500_error": {
        "heading": "Oh oh",
        "message_line_1": "Quelque chose s'est mal passé.",
        "message_line_2": "Nous l'examinons et y remédierons dès que possible."
      }
    },
    "learner": {
      "events": {
        "booked": {
          "page_header": "Réservé",
          "starting_in": "Le événement commence"
        },
        "confirmation": {
          "confirmed_result": "Confirmé",
          "email_sent_to": "Un e-mail de confirmation a été envoyé à",
          "login_or_register": "Créez un compte ou connectez-vous pour confirmer votre réservation.",
          "thank_you_for_registering_for": "Merci pour votre inscription",
          "unconfirmed_result": "Presque fini!",
          "view_event_cta": "Voir les événement"
        },
        "event": {
          "booked_notice": "Réservé",
          "event_date": "Date de l'événement",
          "event_duration": "Durée de l'événement",
          "event_time": "Heure de l'évènement",
          "free_notice": "Libre",
          "payment_amount": "Montant du paiement",
          "payment_date": "Date de paiement",
          "payment_reference": "Référence de paiement",
          "sold_out_notice": "Épuisé",
          "tickets_cta": "Des billets",
          "venue_information": "Informations sur le lieu",
          "view_cta": "Voir",
          "view_sold_out_cta": "Voir (épuisé)"
        },
        "index": {
          "non_account_line_one": "Ajuster le développement professionnel",
          "non_account_line_two": "dans votre vie.",
          "page_description": "Nouveaux événements en ligne et événements pour les infirmières, les soignants et le personnel des foyers de soins. Construisez des heures de FPC et maîtrisez votre développement professionnel - événements gratuits disponibles.",
          "page_title": "événements en ligne pour le personnel de santé et de soins | Florence Academy",
          "upcoming_header": "événements à venir",
          "upcoming_subtitle": "Tous nos événements comptent dans vos heures de DPC pour l'apprentissage participatif."
        },
        "local_time": "heure locale",
        "show": {
          "about_subtitle": "À propos de ce événement",
          "breakdown": {
            "date_header": "Date",
            "end_time_header": "Heure de fin",
            "host_header": "Hôte",
            "host_subheader": "Ce événement est animé par",
            "price_header": "Prix",
            "start_time_header": "Heure de début"
          },
          "dates_and_times": "Dates & horaires",
          "example_certificate_subtitle": "Attestation de formation",
          "exc_vat": "à l'exclusion VAT",
          "free_event_header": "événement gratuit sur le DPC",
          "free_ticket_cta": "Obtenez votre billet gratuit",
          "length_in": "minutes",
          "multiple_dates_available": "Plusieurs dates et heures disponibles",
          "no_refunds": "Nous ne pouvons pas offrir de remboursement pour cet événement une fois qu'un achat a été effectué.",
          "or": "ou",
          "provided_by": "Fourni par",
          "purchase_required_first": "Achetez votre certificat",
          "select_date_to_attend": "Veuillez sélectionner la date et l'heure de l'événement auquel vous souhaitez assister",
          "selected_date_and_time": "Date et heure sélectionnées",
          "tickets_cta": "Des billets",
          "to_book_your_ticket": "pour réserver votre billet",
          "training_required_cta_copy": "ici.",
          "training_required_first": "Complétez la formation et achetez votre certificat",
          "training_required_header": "Un certificat valide pour %{course_title} est requis avant de pouvoir vous inscrire à cette session pratique. Si vous n'êtes pas en possession d'un certificat valide, l'entrée à l'événement peut vous être refusée.",
          "training_required_present": "Vous avez déjà terminé %{course_title}. Vous pouvez trouver le certificat dans votre",
          "uk_time": "(L'heure de 'Angleterre)"
        },
        "sold_out": {
          "message": "est populaire et nous avons vendu tous nos billets ! De nouveaux événements sont publiés en permanence, alors revenez bientôt.",
          "page_header": "Épuisé",
          "upcoming_events_cta": "Cliquez ici voir les prochains événements"
        }
      },
      "home": {
        "company_documents_widget": {
          "documents_cta": "Consulter documents"
        },
        "compliancy_widget": {
          "compliancy": "conforme",
          "empty_state": "Vous n'avez aucune formation assignée",
          "resume_training_cta": "Reprendre l'apprentissage"
        },
        "job_matchers_widget": {
          "widget_copy": "Correspondances d'emploi",
          "widget_sub_copy": "Vous avez %{number_of_matches} travail %{matches}"
        },
        "open_to_work_widget": {
          "setup_portfolio_cta": "Voir le portefeuille",
          "widget_copy": "Êtes-vous ouvert au travail?",
          "widget_copy_already_open": "Les détails de votre travail sont-ils à jour?",
          "widget_sub_copy": "Partagez vos réalisations avec les employeurs",
          "widget_sub_copy_already_open": "Mettez à jour vos réalisations pour les partager avec les employeurs"
        },
        "portfolio_widget": {
          "certificate_count": "Certificat",
          "view_portfolio_cta": "Voir le portefeuille"
        },
        "resume_training_widget": {
          "resume_training_cta": "Voir mon apprentissage",
          "widget_copy_not_started": "Démarrez votre formation...",
          "widget_copy_started": "Continuez là où vous vous êtes arrêté...",
          "widget_sub_copy_collection_started": "Vous êtes à %{percentage}% via la %{collection_title}",
          "widget_sub_copy_course_started": "Vous êtes à %{percentage}% via",
          "widget_sub_copy_final_assessment": "Faites votre évaluation finale pour",
          "widget_sub_copy_not_started": "Commencez la %{collection_title} aujourd'hui"
        },
        "view_courses_widget": {
          "private_widget_copy": "Découvrez d'autres opportunités de formation",
          "public_widget_copy": "Restez à jour avec votre formation en conformité",
          "view_coruses_cta": "Voir les cours"
        }
      },
      "jobs": {
        "show": {
          "body": "Ajoutez un court message à %{contact} expliquant pourquoi vous souhaitez postuler pour ce poste.",
          "decline_job_button": "Non, merci",
          "email_sent": "Email envoyé",
          "say_youre_interested": "Dis que tu es intéressé",
          "say_youre_interested_button": "Oui je suis intéressé",
          "send_email": "Oui, envoyer un e-mail"
        }
      },
      "my_purchases": {
        "date": "Date",
        "no_purchases": "Vous n'avez pas encore d'achats.",
        "paid": "Payé",
        "price": "Prix",
        "purchases": "Achats",
        "refunded": "Remboursé",
        "status": "Statut",
        "type": "Taper"
      },
      "portfolio": {
        "certificates": {
          "certified_on": "Certifié le",
          "expired_on": "A expiré le",
          "previous_certificates_link": "Voir les certificats précédents"
        }
      }
    },
    "mailers": {
      "course_feedback": {
        "congratulations_on_completing": "Félicitations pour avoir terminé ",
        "congratulations_on_your_completion": "Félicitations pour votre achèvement de ",
        "hi": "Salut ",
        "start_survey": "Commencer l'enquête",
        "survey_questions_information": " sur l'Académie de Florence. Nous aimerions avoir vos commentaires sur le cours, votre expérience d'apprentissage et l'Académie de Florence en général. Veuillez répondre à ce court sondage de sept questions. C'est totalement anonyme et nous utilisons toutes les informations que vous fournissez pour aider à améliorer Florence Academy.",
        "warm_regards": "Meilleurs voeux,"
      }
    },
    "shared": {
      "address": "6 rue Auber 75009 Paris.",
      "banners": {
        "free_portfolio": "Essayez le Portfolio gratuitement pendant une durée limitée"
      },
      "certificates": {
        "index": {
          "browse_courses_cta": "Parcourir les cours.",
          "complete_expired_state_title": "Vos certificats ont maintenant expiré",
          "copied": "Copié",
          "copy_link": "Copier le lien",
          "edit_profile": "Editer le profil",
          "empty_state_title": "Vous n'avez pas encore suivi de cours de Academy.",
          "experience": "Expérience",
          "expired_state_subtitle": "Merci de reprendre les cours",
          "expired_state_title": "Certificats expirés",
          "learner_of_the_month": "Chaque mois, nous choisissons un apprenant du mois de la Florence Academy. N'oubliez pas de taguer Florence lorsque vous partagez les nouvelles de votre certificat pour participer!",
          "make_profile_public": "Profil public",
          "my_certifications": "Mes certifications",
          "occupation": "Profession",
          "page_header": "Certificats complétés",
          "page_subtitle": "Vos certificats sont automatiquement ajoutés à vos comptes pour",
          "share": "Partager la nouvelle",
          "share_profile": "Rendez votre profil public et partagez le lien afin que tout le monde puisse voir vos réalisations",
          "share_title": "Partagez votre réussite"
        },
        "payment_alert": {
          "modal_text": "La reprise de cette évaluation générera un nouveau certificat, qui vous sera à nouveau facturé. Vous pourrez toujours accéder gratuitement à vos anciens certificats."
        },
        "pdf": {
          "academy_director_title": "Directeur des soins et de la gouvernance",
          "accredited_by": "Florence Academy est la propriété de Florence Staffing SAS.",
          "address": "6 rue Auber 75009 Paris.",
          "candidate_code": "Code candidat",
          "delivered_by_and_on": "dispensé par Florence Academy le",
          "expires": "Expire le",
          "has_completed": "A suivi la formation en ligne",
          "here": "ici",
          "more_info": "Besoin d'une formation efficace pour votre personnel ? Obtenez plus d'informations",
          "verify": "Vous pouvez vérifier ce candidat en visitant https://academy.florence.co.uk/public/portfolio/verify"
        },
        "show": {
          "certificate_florence": "Certificat_Florence",
          "certificate_info": "Détails",
          "completed_subtitle": "Complété",
          "congratulations": "Félicitations <strong>%{first_name} %{last_name}</strong> 🎉 vous avez terminé <strong>%{course}</strong> le %{completion_date}. Les résultats d'apprentissage suivants résument ce que vous avez appris. Vous pouvez désormais partager l'actualité avec votre employeur, Linkedin ou sur les réseaux sociaux.",
          "course_created_by": "Cours créé par",
          "download_cta": "Télécharger le certificat",
          "expired_at": "Expiré à",
          "private_until_paid": "Votre certificat est privé jusqu'au paiement.",
          "purchase_to_share": "Achetez pour partager en ligne avec votre réseau.",
          "retake_cta": "Reprendre le cours",
          "retake_standard_cta": "Norme de reprise",
          "verifies": "A terminé <strong>%{course}</strong> délivré par l'académie de Florence le <strong>%{completed_at_date}</strong>",
          "view_certificate_cta": "Voir le certificat",
          "view_standards_cta": "Afficher les normes"
        },
        "social_media": {
          "og_description": "Boostez votre carrière avec les cours en ligne de Florence Academy. Plateforme d'apprentissage accréditée CPD et approuvée par Skills for Care.",
          "og_image_alt": "image d'un certificat avec le logo de Florence Academy",
          "og_title": "J'ai terminé un cours sur Florence Academy!"
        }
      },
      "checkouts": {
        "error_message": "Une erreur s'est produite lors du traitement de votre paiement. Veuillez réessayer.",
        "payment_reference": "Référence de paiement"
      },
      "continue": "Continuer",
      "course_attempts": {
        "new": {
          "audio_not_supported": "Votre navigateur ne supporte pas l'élément audio.",
          "page_header": "Introduction",
          "start_cta": "Démarrer le cours"
        }
      },
      "course_categories": {
        "recommended_for_you": {
          "recommended_for_you_header": "Recommandé pour vous"
        },
        "required_training": {
          "required_training_header": "Formation Requise"
        },
        "show": {
          "courses_empty_state": "Votre organisation n'a pas de cours dans cette catégorie"
        },
        "view_all": "Voir tout"
      },
      "courses": {
        "chapter": {
          "back_cta": "Chapitre précédent",
          "check_your_answer_cta": "Vérifie ta réponse",
          "next_cta": "Chapitre suivant",
          "take_course_assessment": "Passer l'évaluation du cours",
          "try_again_cta": "Réessayer"
        },
        "course": {
          "chapter": "chapitre",
          "chapters": "chapitres",
          "coming_soon": "À venir",
          "continue_cta": "Continuer la formation",
          "in_person": "En personne",
          "in_person_with_external_content": "Contenu externe",
          "interest_registered": "Vous avez enregistré votre intérêt",
          "minutes": "minutes",
          "one_off": "Unique",
          "overview": "Aperçu du cours",
          "register_interest_cta": "Enregistrer l'intérêt",
          "required": "Requis",
          "retake_cta": "Reprendre la formation",
          "start_cta": "Démarrer le cours",
          "statuses": {
            "due_on": "Dû le",
            "one_off": "Unique",
            "overdue": "En retard"
          }
        },
        "feedback_form": {
          "course_score": "Comment avez-vous trouvé ce cours?",
          "recommend_to_friend_score": "Sur une échelle de zéro à dix, quelle est la probabilité que vous recommandiez Florence Academy à un ami ou à un collègue?",
          "submit_cta": "Envoyer des commentaires et continuer",
          "suggestions": "Des suggestions pour améliorer ce cours?"
        },
        "final_assessment": {
          "page_header": "Évaluation finale",
          "submit_cta": "Soumettre les réponses"
        },
        "index": {
          "account_paused_with": "Compte suspendu avec",
          "all_courses_header": "Tous les cours",
          "complete_for_free": "Complétez gratuitement votre formation en soins.",
          "contact_manager_message": "Veuillez contacter un responsable si vous pensez qu'il s'agit d'une erreur",
          "courses_offered_by": "Cours offerts par",
          "description": "Cours de formation obligatoires accrédités CPD gratuits. Inscrivez-vous aujourd'hui et complétez votre formation gratuitement.",
          "empty_state": "Aucun cours ne correspond à cet ensemble de filtres",
          "mandatory_training_empty_state": "Vous n'avez pas de formation en ligne assignée.",
          "mandatory_training_header": "Formation obligatoire",
          "mandatory_training_subtitle": "Formation que votre organisation vous demande de suivre.",
          "personal_training_header": "Formation développement personnel",
          "personal_training_subtitle": "Voici une liste de toutes les formations disponibles que vous pouvez suivre pour votre propre développement.",
          "recommended_courses_empty_state": "Il n'y a pas de recommandations de cours pour vous en ce moment.",
          "separator": "et",
          "sign_up": "Inscrivez-vous aujourd'hui.",
          "title": "Certificats de formation disponibles",
          "uncategorised_training_header": "Formation Non Catégorisée",
          "uncategorised_training_subtitle": "Formation sans catégorie attribuée."
        },
        "nav_menu": {
          "assessment": "Évaluation",
          "chapters": "Chapitres",
          "course_menu": "Menu du cours",
          "open_course_menu": "VOIR LE MENU DU COURS",
          "overview": "Aperçu"
        },
        "paper_certificate": {
          "delivered_by": "cours dispensé par Florence Academy le",
          "has_completed": "A terminé le",
          "page_title": "Attestation de formation"
        },
        "results": {
          "buy_and_save": "Votre certificat est prêt. Obtenez une certification aujourd'hui et bénéficiez de:",
          "correct_needed": "correct nécessaire",
          "credibility": "Cela peut augmenter la crédibilité et améliorer la réputation professionnelle",
          "download_certificate": "Vous pouvez maintenant télécharger votre certificat!",
          "download_certificate_cta": "Télécharger le certificat",
          "fail_message": "Vous n'avez pas réussi cette fois",
          "fail_your_answers": "Vos réponses",
          "flex_learners_message": "Si vous êtes un travailleur de Florence, nous l'ajouterons automatiquement et gratuitement à votre compte.",
          "free_to_learn": "Apprentissage gratuit, 2,99 £ pour un certificat",
          "get_certified_cta": "Obtenez une certification pour",
          "job_prospects": "Il peut améliorer les perspectives d'emploi et augmenter le potentiel de revenus",
          "now_download": "Vous pouvez maintenant télécharger votre certificat!",
          "number_correct": "de vos réponses étaient correctes",
          "page_title": "Résultat",
          "page_title_successful": "Félicitations 🎉",
          "passed_congratulations": "Félicitations, vous avez réussi",
          "passed_with_score": "Vous avez réussi avec un score de",
          "payment_complete": "Paiement terminé",
          "previous_certificates_link": "Voir les certificats précédents",
          "processing_payment": "Nous sommes en train de traiter votre paiement, votre certificat pourra être téléchargé à partir de votre",
          "profile_link": "page de profil",
          "purchase_of": "Merci pour votre achat de",
          "retake": "Reprenez le cours",
          "retake_assessment": "Essayer à nouveau",
          "retake_standard": "Norme de reprise",
          "review_answers_requiring_attention": "Ci-dessous, vous pouvez consulter les réponses qui nécessitent votre attention:",
          "see_answers": "Voir vos réponses",
          "shortly": "brièvement",
          "standard_passed_congratulations": "Félicitations, vous avez terminé Standard",
          "with_score": "avec un score de",
          "you_might_also_like": "Vous pourriez aussi aimer..."
        },
        "search": {
          "did_you_mean": "Vouliez-vous dire",
          "filters": {
            "audience": "Public",
            "bundle": "Collection",
            "categories_placeholder": "Toutes catégories",
            "category": "Catégorie",
            "duration": "Durée",
            "durations_placeholder": "Toutes les durées",
            "reset_filters": "Réinitialiser les filtres"
          },
          "no_results_text": "Oups, votre recherche n'a trouvé aucun résultat.",
          "placeholder": "Rechercher des cours",
          "results_text": "Résultats de recherche pour",
          "returned_results_text": "Votre recherche a renvoyé",
          "showing": "Montrant",
          "view_all_cta": "Voir toutes les cours"
        }
      },
      "create_account_cta": "Créer un compte",
      "delete": "Supprimer",
      "edit": "Modifier",
      "external_assessments": {
        "pdf": {
          "delivered_by_and_on": "cours décerné par"
        }
      },
      "extra_info": {
        "continue_cta": "Continuer",
        "form_input_four": "vous êtes ici pour...*",
        "form_input_four_options": [
          [
            "Pour démarrer dans le secteur du soin",
            "enter_care_industry"
          ],
          [
            "Pour me former aux soins de base",
            "essential_care_training"
          ],
          [
            "Pour développer mes compétences professionnelles",
            "professional_development"
          ]
        ],
        "form_input_one": "Dans quel pays vivez-vous?*",
        "form_input_three": "Quelle est votre expérience professionnelle dans le secteur des soins ?*",
        "form_input_three_options": [
          [
            "Aucune",
            "new_to_care"
          ],
          [
            "Moins d'un an",
            "zero_to_twelve_months"
          ],
          [
            "Entre 1 et 2 ans",
            "one_to_two_years"
          ],
          [
            "Entre 2 et 5 ans",
            "two_to_five_years"
          ],
          [
            "Plus de 5 ans",
            "five_years_plus"
          ]
        ],
        "form_input_two": "Quel est votre profession actuelle ?*",
        "form_input_two_categories": [
          [
            [
              "-Aide-soignant-"
            ],
            [
              "Aide-soignant",
              "care_worker"
            ],
            [
              "Personnel de soutien",
              "support_worker"
            ],
            [
              "Aide-soignant Senior",
              "senior_care_worker"
            ],
            [
              "Aide-soignant à domicile",
              "home_care_worker"
            ],
            [
              "Aide-soignant bénévole",
              "unpaid_carer"
            ],
            [
              "Autre catégorie d’aide-soignant",
              "other_social_care_and_support_role"
            ]
          ],
          [
            [
              "—Manager—"
            ],
            [
              "Chef d’équipe/Cadre",
              "team_leader_manager"
            ]
          ],
          [
            [
              "-Infirmier(ère)-"
            ],
            [
              "Infirmi(ère) diplômé(e)",
              "registered_qualified_nurse"
            ],
            [
              "Elève infirmier(ère)",
              "student_nurse"
            ]
          ],
          [
            [
              "-Autre-"
            ],
            [
              "Autre profession de soin",
              "other_care_role"
            ],
            [
              "Je ne travaille pas actuellement dans le soin",
              "i_dont_currently_work_in_care"
            ]
          ]
        ],
        "page_header": "Dites-nous en plus à propos de vous",
        "page_subtitle": "Nous avons besoin d'un peu plus d'informations pour personnaliser votre expérience d'apprentissage",
        "postcode_label": "Code postal",
        "postcode_subtitle": "Nous vous le demandons afin de pouvoir adapter les informations que nous vous envoyons",
        "privacy_policy": "J'accepte les termes et conditions et que mes données personnelles soient traitées conformément à la politique de confidentialité de Florence, voir <a href='https://www.florence.co.uk/legal' target='_blank'>ici</a> pour les politiques. Florence peut utiliser ces données pour me fournir de plus amples informations sur ses services."
      },
      "flash": {
        "alert": "Alerte",
        "error": "Erreur",
        "notice": "Notification",
        "success": "Succès"
      },
      "footer": {
        "accounts": "Comptes",
        "company": "Compagnie",
        "legal": "Juridique",
        "legal_url": "https://www.florenceapp.com/fr/legal",
        "privacy": "Politique de Confidentialité",
        "t&c": "Termes et Conditions"
      },
      "login_cta": "Connexion",
      "modals": {
        "flex_banner": {
          "headline": "Des centaines de mission de vacations vous attendent près de chez vous",
          "headline_cta": "En savoir plus",
          "inner_modal": {
            "average_hourly_rate": "Taux horaire moyen par quart de travail: ",
            "average_monthly_shifts": "Moyenne des quarts de travail affichés sur Florence chaque mois: ",
            "carer_db_value": [
              "carer"
            ],
            "carer_label": "Soignant",
            "cta_active": "Recherche",
            "header": "Des quarts de travail flexibles en toute simplicité",
            "nurse_db_value": [
              "nurse"
            ],
            "nurse_label": "Infirmier",
            "postcode_placeholder": "Recherche par code postal...",
            "signup_now": "S'inscrire maintenant",
            "subheader": "Trouvez des informations sur les quarts de travail dans votre région"
          }
        },
        "flex_synced_certificates": {
          "body_one": "Tous les cours de conformité obligatoires que vous suivez sur Academy seront automatiquement et gratuitement synchronisés avec votre compte de travailleur Florence.",
          "body_two": "Si vous souhaitez télécharger un PDF de votre certificat ou si vous suivez un cours non obligatoire, veuillez poursuivre le paiement.",
          "header": "Il semble que vous soyez actuellement en train d'intégrer notre plate-forme de travailleurs de Florence."
        },
        "happy_learning": "Bon apprentissage!"
      },
      "models": {
        "required_training": {
          "statuses": {
            "assigned_not_complete": "Attribué, mais pas complet",
            "complete_in_date": "Complet et à jour",
            "due_soon": "Bientôt",
            "one_off_and_completed": "Unique et terminé",
            "overdue": "En retard"
          }
        }
      },
      "my_learning": {
        "all_courses_complete": "Toutes nos félicitations! Vous avez terminé %{n}% de votre %{menuTitle}.",
        "care_certificate_awaiting_approval": "Votre responsable examine vos résultats et vous recevrez bientôt le certificat de soins.",
        "care_certificate_complete": "Vous avez obtenu le certificat de soins.",
        "courses_started_percentage_completed": "Vous avez terminé %{n}% de votre %{menuTitle}. Maintenez votre élan en complétant chaque cours.",
        "individual_courses": "Cours Individuels",
        "individual_courses_empty_state": "Vous n'avez pas encore commencé de cours.",
        "no_courses_started": "Il semble que vous n'ayez terminé aucun des cours de votre %{menuTitle}",
        "required_trainings": "Formation requise",
        "required_trainings_empty_state": "Vous n'avez pas encore de formation requise.",
        "table": {
          "columns": {
            "course": "Cours",
            "status": "Statut"
          },
          "row": {
            "overdue": "En retard!",
            "statuses": {
              "completed": "Complété",
              "failed": "Échoué",
              "in_progress": "En cours",
              "not_started": "Pas commencé"
            }
          }
        },
        "training_failed": "Échec de la formation"
      },
      "nav": {
        "admin": "Administrateur",
        "all_courses": "Tous les cours",
        "care_certificate": "Certificat de soins",
        "collections": "Collections",
        "courses": "Cours",
        "dashboard": "Tableau de bord",
        "documents": "Politiques",
        "editor": "Éditeur",
        "events": "événements",
        "faqs": "FAQs",
        "home": "Maison",
        "learner_view": "Vue de l'apprenant",
        "login": "Connexion",
        "my_account": "Mon compte",
        "my_learning": "Mon apprentissage",
        "new_course": "Nouveau cours",
        "portfolio": "Portefeuille",
        "profile": "Mon Profil",
        "purchases": "Achats",
        "referrals": "Référez un ami",
        "register": "S'inscrire",
        "roles": "Les rôles",
        "settings": "Paramètres",
        "sign_out": "Déconnexion",
        "staff": "Personnel",
        "subscriptions": "Abonnements",
        "training": "Formation"
      },
      "onboarding": {
        "continue_cta": "Continuer",
        "form_input_one": "Prénom",
        "form_input_two": "Nom",
        "more_details": "Nous avons juste besoin de quelques détails supplémentaires de votre part",
        "notifications": {
          "complete_all_fields": "Veuillez compléter tous les champs s'il vous plaît",
          "enter_full_name": "S'il vous plait entrez votre nom entier",
          "invalid_postcode": "Code postal invalide. Veuillez saisir un code postal britannique valide et soumettre à nouveau le formulaire.",
          "updated_successfully": "Votre compte a été mis à jour avec succès",
          "welcome": "Bienvenue sur Florence Academy!"
        },
        "page_header": "Vous y êtes presque!"
      },
      "partials": {
        "bundle_advert": {
          "body": "Travaillez vers la conformité avec notre collection de 10 des cours les plus fréquemment nécessaires pour travailler dans les soins.",
          "cta": "Commencer",
          "new": "Nouvelle"
        }
      },
      "please_wait": "S'il vous plaît, attendez...",
      "portfolio": {
        "accommodation_required_label": "Avez-vous besoin d'un hébergement?",
        "add_external_certificate": "Ajouter un certificat externe",
        "add_work_experience": "Ajouter un historique de travail",
        "care_setting": "Milieu de soins",
        "care_setting_example": "par exemple. Maison de retraite, Hôpital privé, Maison privée",
        "certificate": "Certificat",
        "certificate_completion_date": "Quand avez-vous obtenu ce certificat?",
        "click_on_a_skill": "Cliquez sur une compétence pour l'ajouter ou la supprimer de votre portfolio",
        "country": "Pays",
        "course_title": "Nom du cours",
        "current": "Actuelle",
        "current_position": "Position actuelle",
        "daily_schedule_label": "Préférence d'horaire quotidien",
        "dbs_up_to_date_label": "Votre DBS est-il à jour?",
        "details": "Détails",
        "download_all_certificates_cta": "Télécharger tous les certificats",
        "download_cta": "Télécharger le PDF",
        "download_description": "Téléchargez l'intégralité de votre portfolio au format PDF",
        "download_portfolio_cta": "Télécharger le résumé du portefeuille",
        "employer_name": "Nom de l'employeur",
        "empty_state": "Vous n'avez pas encore ajouté de compétences",
        "empty_state_external": "Aucun cours de l'Académie n'est encore terminé",
        "end_date": "Date de fin",
        "externally_certified_on": "Certifié externe sur",
        "full_time_toggle_label": "À temps plein",
        "inadequate_content_copy": "Vous devez avoir au moins un dossier d'expérience professionnelle et un certificat dans votre portefeuille avant de pouvoir vous déclarer ouvert au travail.",
        "learner_generated_skills": "Ces compétences sont générées par le apprenant",
        "looking_in_the_uk_error": "Nous prévoyons actuellement de proposer une offre d'emploi au Royaume-Uni uniquement.",
        "looking_in_uk_label": "Cherchez-vous du travail au Royaume-Uni?",
        "modal_title": "Options de téléchargement",
        "my_skills": "Mes compétences",
        "not_selected": "Non séléctionné",
        "occupation": {
          "care_worker": "Aide-soignant",
          "home_care_worker": "Aide-soignant à domicile",
          "i_dont_currently_work_in_care": "Je ne travaille pas actuellement dans le soin",
          "other_care_role": "Autre profession de soin",
          "other_social_care_and_support_role": "Autre catégorie d’aide-soignant",
          "registered_qualified_nurse": "Infirmi(ère) diplômé(e)",
          "senior_care_worker": "Aide-soignant Senior",
          "student_nurse": "Elève infirmier(ère)",
          "team_leader_manager": "Chef d’équipe/Cadre",
          "unpaid_carer": "Aide-soignant bénévole"
        },
        "open_to_work": "Ouvert à travailler au Royaume-Uni",
        "open_to_work_header": "Ajoutez quelques détails supplémentaires à votre portfolio pour informer les employeurs britanniques potentiels que vous êtes prêt à travailler.",
        "open_to_work_modal_header": "Quel type de quarts de travail recherchez-vous?",
        "part_time_toggle_label": "À temps partiel",
        "post_code_helper": "Cela aidera les employeurs potentiels à vous trouver plus facilement",
        "post_code_label": "Code postal",
        "public_certificate_alert": "Votre certificat sera public et pourra apparaître sur les moteurs de recherche publics",
        "role_title": "Titre du rôle",
        "role_type_label": "Préférence de type de rôle",
        "select_option": " -- Choisir une option -- ",
        "selected": "Séléctionné",
        "skill_name": "Nom de la compétence",
        "skills": "Compétences",
        "skills_list": {
          "allergies": "Allergies",
          "alzheimers": "Alzheimer",
          "bereavement_support": "Soutien au deuil",
          "buccal_medication": "Médicament buccal",
          "care_planning": "Planification des soins",
          "childrens_residential": "Résidence pour enfants",
          "de_escalation": "Désescalade",
          "dementia": "Démence",
          "diabetes": "Diabète",
          "driving": "Conduite",
          "duty_of_care": "Devoir d'attention",
          "eating_disorders": "Troubles de l'alimentation",
          "emars": "EMARS",
          "end_of_life_care": "Soins de fin de vie",
          "enteral_feeding": "Alimentation entérale",
          "experience_with_autism": "Expérience avec l'autisme",
          "experience_with_challenging_behaviours": "Expérience avec des comportements difficiles",
          "experience_with_dysphagia": "Expérience avec la dysphagie",
          "experience_with_epilepsy": "Expérience avec l'épilepsie",
          "experience_with_learning_disability": "Expérience avec des troubles d'apprentissage",
          "experience_with_neurological_conditions": "Expérience avec les conditions neurologiques",
          "falls_prevention": "Prévention des chutes",
          "female_catheter_care": "Soins du cathéter féminin",
          "first_aid": "PREMIERS SECOURS",
          "frailty": "Fragilité",
          "gastrostomy_tube_care": "Soins des sondes de gastrostomie",
          "genetic_conditions": "Conditions génétiques",
          "head_injury": "Blessure à la tête",
          "health_and_safety": "Santé et sécurité",
          "hepma": "HEPMA",
          "homelessness": "L'itinérance",
          "incident_management": "La gestion des incidents",
          "incontinence_care": "Soins d'incontinence",
          "infection_control": "Contrôle d'infection",
          "legislation": "Législation",
          "male_catheter_care": "Soins du cathéter masculin",
          "management": "Gestion",
          "managing_abuse": "Gérer les abus",
          "mental_health_experience": "Expérience en santé mentale",
          "nutrition": "Nutrition",
          "palliative_care": "Soins palliatifs",
          "parkinsons": "Parkinson",
          "peg_feeding": "Alimentation PEG",
          "peg_medication": "Médicament PEG",
          "physical_disability_experience": "Expérience de handicap physique",
          "practical_medication_administration": "Administration pratique des médicaments",
          "pvg": "PVG",
          "record_keeping": "Tenue de registres",
          "restraint_training": "Formation à la contention",
          "safeguarding": "Sauvegarde",
          "seizures": "Saisies",
          "sensory_impairment": "Déficience sensorielle",
          "sign_language": "Langage des signes",
          "stoma_care_awareness": "Sensibilisation aux soins des stomies",
          "substance_misuse_experience": "Expérience de toxicomanie",
          "suction_feeding": "Alimentation par aspiration",
          "suicidal_ideation": "Idées suicidaires",
          "suppository_administration": "Administration de suppositoires",
          "syringe_driver": "Chauffe-seringue",
          "tracheostomy_administration": "Administration de trachéotomie",
          "working_with_children_and_young_adults": "Travailler avec des enfants et des jeunes adultes"
        },
        "sponsorship_required_label": "Avez-vous besoin d'un parrainage britannique?",
        "start_date": "Date de début",
        "training_certificates": "Certificats de formation",
        "uk_resident_label": "Êtes-vous un résident du Royaume-Uni",
        "upload_certificate": "Téléchargez votre certificat",
        "uploaded_file": "Fichier téléchargé",
        "vaccinations_up_to_date_label": "Vos vaccins sont-ils à jour?",
        "valid_uk_driving_license_label": "Avez-vous un permis de conduire britannique valide?",
        "weekly_schedule_label": "Préférence d'horaire hebdomadaire",
        "will_replace_file": "(remplacera le fichier existant)",
        "work_experience": "L'expérience professionnelle",
        "work_experience_duration": {
          "five_years_plus": "Plus de 5 ans",
          "new_to_care": "Aucune",
          "one_to_two_years": "Entre 1 et 2 ans",
          "two_to_five_years": "Entre 2 et 5 ans",
          "zero_to_twelve_months": "Moins d'un an"
        },
        "work_history": "Antécédents de travail"
      },
      "previous_certificates": {
        "empty_state": "Rien à montrer",
        "header": "Certificats précédents"
      },
      "public": {
        "bundles": {
          "card": {
            "get_started": "Commencer",
            "start_learning": "Commencer à apprendre"
          },
          "index": {
            "subtitle": "Les collections sont des groupes de cours organisés sur un sujet particulier avec une réduction sur les certificats à la fin."
          },
          "learning_unlocked": {
            "buy_your_certificate": "Achetez votre certificat",
            "get_certificates": "Obtenez dix certificats de cours pour le prix de huit lorsque vous suivez ce cours dans le cadre du %{bundle}",
            "more_and_save": "Débloquez plus d'apprentissage et économisez",
            "save_money_with_a_collection": "Économisez de l'argent avec une collection"
          },
          "payment_success": {
            "courses_in_learning_area": "cours dans l'espace Mon Apprentissage",
            "cta": "Commencer",
            "error": "Oups, quelque chose s'est mal passé",
            "find_your": "Trouver votre",
            "learning_area": "Mon espace Apprentissage",
            "processing_payment": "Nous traitons juste votre paiement, votre collection sera disponible dans le",
            "updated_skills": "Nous mettrons à jour votre portfolio avec les nouvelles compétences que vous venez d'apprendre"
          },
          "show": {
            "about": "À propos de cette collection",
            "bundle_with_charge_cta": "Acheter maintenant",
            "bundle_without_charge_cta": "Commencer",
            "certificate_ad_one": "Obtenez une certification pour %{price_and_old_price}",
            "certificate_ad_three": "Payez %{old_price} pour %{number_of_courses} certificats individuels ou économisez avec une collection pour seulement %{price}",
            "certificate_ad_two": "Présentez vos compétences aux employeurs",
            "certificates_included": "Certificats inclus",
            "curated_by": "Collection créée par",
            "flex_detail": "Gratuit pour les travailleurs florentins actifs",
            "includes_downloadable_certificates": "Comprend %{course_count} certificats",
            "not_available": "Cette collection n'est plus disponible",
            "what_you_will_learn": "Ce que vous apprendrez"
          }
        },
        "courses": {
          "join_as": {
            "already_have_an_account": "Vous avez déjà un compte?",
            "as_individual": "En tant qu'individu",
            "as_organisation": "En tant qu'organisation",
            "how_will_use_academy": "Comment allez-vous utiliser Academy?"
          },
          "show": {
            "about_this_course": "À propos de ce cours",
            "alt_text": "Image illustrative liée au contenu du cours",
            "certificate": "Certificat",
            "certificate_ad_one": "Présentez vos compétences avec un certificat de cours, à utiliser dans vos dossiers professionnels, votre portfolio ou lors d'un entretien d'embauches",
            "certificate_ad_one_alternative": "Un certificat numérique téléchargeable et partageable",
            "certificate_ad_three": "Augmenter la crédibilité professionnelle",
            "certificate_ad_three_alternative": "Augmentez votre crédibilité auprès de vos pairs et professionnellement",
            "certificate_ad_three_bundle_alternative": "Obtenez un certificat dans le cadre du %{bundle_link} %{price}",
            "certificate_ad_two": "Certificat de cours individuel %{price}",
            "certificate_included": "(Certificat inclus à la fin)",
            "chapter": "Chapitre",
            "cost_to_learn": "d'apprendre",
            "course_curriculum": "Programme du cours",
            "faq": "FAQ",
            "flex_detail": "Les certificats sont gratuits pour les travailleurs actifs de Florence",
            "free": "Gratuit",
            "get_certified": "Obtenir une certification",
            "get_certified_for_a_price": "Obtenez une certification pour",
            "industry_backed": "Apprentissage en ligne soutenu par l'industrie",
            "join_cta": "Adhérer maintenant",
            "learning_outcomes": "Résultats d'apprentissage",
            "minutes": "minutes",
            "pay_info_one": "Apprentissage gratuit",
            "pay_info_two": "pour un certificat",
            "purchase_now": "Achetez maintenant",
            "purchased": "Acheté",
            "rated_by": "Noté %{rating}/5 par nos apprenants",
            "recognised_by": "Nous sommes reconnus par",
            "related_courses": "Cours connexes",
            "save_with_collection": "Économisez avec une collection",
            "start_learning_cta": "Commencer à apprendre",
            "suitable_for": "Convient à"
          }
        }
      },
      "referrals": {
        "email_address": "Adresse email de la personne que vous souhaitez parrainer",
        "error_message": "Une erreur s'est produite lors de la création de votre code de parrainage. Veuillez réessayer plus tard."
      },
      "save": "Enregistrer",
      "settings": {
        "form_input_one": "Prénom",
        "form_input_three": "Email",
        "form_input_two": "Nom",
        "page_header": "Paramètres",
        "security_reason_section": "Pour des raisons de sécurité vous ne pouvez pas modifier votre nom ou votre email sur cette page. Contactez <a href='mailto:bonjour@florenceapp.com'>bonjour@florenceapp.com</a> pour une demande de changement."
      },
      "signup_cta": "S'inscrire",
      "update": "Mettre à jour",
      "verification": {
        "candidate_code": "Code candidat",
        "full_name": "nom et prénom",
        "missing_candidate_number": "Veuillez entrer le code à 6 caractères inscrit sur le certificat",
        "missing_name": "Veuillez vous assurer d'entrer le nom tel qu'il apparaît sur le certificat",
        "private_profile": "Cet utilisateur a un profil privé. Veuillez contacter l'utilisateur pour rendre son profil public afin que ses certificats puissent être vérifiés.",
        "subtitle": "Ce service est utilisé pour les employeurs qui souhaitent vérifier le certificat d'un candidat",
        "title": "Vérification du candidat",
        "unverified": "Les détails ne correspondent pas à un utilisateur dans notre base de données. Veuillez vérifier les détails et réessayer.",
        "verify": "Vérifier"
      }
    },
    "time": {
      "abbr_day_names": [
        "Dim",
        "Lun",
        "Mar",
        "Mer",
        "Jeu",
        "Ven",
        "Sam"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Fév",
        "Mar",
        "Avr",
        "Mai",
        "Juin",
        "Juil",
        "Août",
        "Sep",
        "Oct",
        "Nov",
        "Déc"
      ],
      "day_names": [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi"
      ],
      "formats": {
        "date_and_time": "%d %b %Y · %H:%M",
        "default": "%d %B, %Y",
        "month_and_year": "%B, %Y",
        "time_only": "%H:%M"
      },
      "month_names": [
        null,
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    }
  }
}