import PropTypes from "prop-types";
import React from "react";
import ReactLoading from "react-loading";

const Loading = ({ color, marginTop, height, width }) => {
  return (
    <div className={`flex justify-center ${marginTop}`}>
      <ReactLoading
        type="spin"
        color={color || "#28818F"}
        height={height || 40}
        width={width || 40}
      />
    </div>
  );
};

Loading.propTypes = {
  color: PropTypes.string,
  marginTop: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Loading;
