import React from "react";
import { userDevice } from "helpers/tracking";

interface TrainingFilterButtonProps {
  title: string;
  isSelected: boolean;
  onFilterSelect: () => void;
}

const TrainingFilterButton: React.FC<TrainingFilterButtonProps> = ({
  title,
  isSelected,
  onFilterSelect,
}) => {
  const trackEvent = () => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      "academy_my_learning_tab_clicked",
      {
        tab: title,
        user_device: userDevice(),
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  return (
    <div>
      <button
        className={`
              btn p-2.5 text-center ${
                isSelected
                  ? "m-1 border bg-teal-100 text-white"
                  : "m-1 border border-teal-100 bg-white"
              }
            `}
        onClick={() => {
          onFilterSelect();
          trackEvent();
        }}
      >
        {title}
      </button>
    </div>
  );
};

export default TrainingFilterButton;
