import React from "react";

interface DownloadFormProps {
  closeModal: () => void;
  userName: string;
  userEmail: string;
}

const DownloadForm: React.FC<DownloadFormProps> = ({
  closeModal,
  userName,
  userEmail,
}) => {
  const downloadPortfolio = () => {
    closeModal();
    window.open(process.env.BASE_URL + "/portfolio/download.pdf", "_blank");
  };

  const downloadCertificates = () => {
    closeModal();
    window.open(
      process.env.BASE_URL + "/portfolio/download_certificates.pdf",
      "_blank"
    );
  };

  const trackClick = (event) => {
    if (typeof window.analytics !== "object") return;
    window.analytics.track(
      event,
      {
        name: userName,
        email: userEmail,
      },
      {
        All: false,
        Mixpanel: true,
      }
    );
  };

  return (
    <div className="flex flex-row gap-4">
      <button
        className="btn btn-primary w-6/12 text-sm"
        onClick={() => {
          downloadPortfolio(),
            trackClick("academy_portfolio_download_portfolio");
        }}
      >
        {i18n.t("shared.portfolio.download_portfolio_cta")}
      </button>
      <button
        className="btn btn-primary w-6/12 text-sm"
        onClick={() => {
          downloadCertificates(),
            trackClick("academy_portfolio_download_certificates");
        }}
      >
        {i18n.t("shared.portfolio.download_all_certificates_cta")}
      </button>
    </div>
  );
};

export default DownloadForm;
